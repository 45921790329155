import React from "react";

const singleMoodBoard = (props) => {
  return (
    <React.Fragment>
      <a href={props.to} className="singleMoodBoard">
        <img src={props.image} className="moodBoardImage" alt={props.heading} />
        <div className="moodBoardContent">
          <div className="moodBoardDate">
            <i className="far fa-calendar-alt"></i>&nbsp;&nbsp;
            {props.date}
          </div>
          <h3 className="moodBoardHeading">{props.heading}</h3>
          <h5 className="moodBoardAuthor">by {props.name}</h5>
        </div>
      </a>
    </React.Fragment>
  );
};

export default singleMoodBoard;
