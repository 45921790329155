import React, { useEffect, useState } from "react";
import "./BlogListComponent.css";
import PageCover from "../../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import { db } from "../../../firebase";
import Moment from 'moment';
import { Alert, ButtonToggle } from 'reactstrap';

const blogListComponent = () => {

    const [blogs, setBlogs] = useState([]);
  
    useEffect(() => {
        db.collection("blogs")
            .get()
            .then((querySnapshot) => {
                const data = querySnapshot.docs.map((doc) => doc.data());
            setBlogs(data);
        });
    }, []);

    let counter = 0;

    return(

        <React.Fragment>
            <PageCover title="Blog Submits" />
            <div className="dashboardBlogListCont">
                <div className="blogs">
                    {blogs?.map((data, i) => {
                        counter++;
                        return (
                            <Fade up key={i}>
                                <div  className="singleBlog">
                                    <div className="left">
                                        <div className="title">{data.blogtitle}</div>
                                        <div className="name">{data.fullname}</div>
                                        <div className="date">Submitted on: {Moment(data.createdAt.toDate().toString()).format('lll')}</div>
                                    </div>
                                    <div className="right">
                                        <a href={data.url} target="_blank">
                                            <button type="button">Download File</button>
                                        </a>
                                    </div>
                                </div>
                            </Fade>
                        );
                    })}
                </div>
                {
                    counter === 0 ?
                        <Fade up>
                            <Alert color="danger" style={{ textAlign: "center", padding: "1.5em 0" }}>
                                Oops! Looks like there are no blog submits at the moment!
                                <br /><br />
                                <a href="/dashboard"><ButtonToggle color="danger">Go Back</ButtonToggle></a>
                            </Alert>
                        </Fade>
                                :
                        null
                }
            </div>
        </React.Fragment>
    );
};

export default blogListComponent;