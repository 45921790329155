import React from "react";
import "./DashboardComponent.css";
import PageCover from "../../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import { useAuth } from "../../../contexts/AuthContext";
import { Link } from "react-router-dom";

const dashboardComponent = () => {
  const { currentUser } = useAuth();

  return (
    <React.Fragment>
      {currentUser.email == "blogs@tedxbitd.in" ? (
        <>
          <PageCover title="Blogs Dashboard" />
          <div className="dashboardCont">
            <Fade up>
              <div className="buttons">
                <Link to="/bloglist">
                  <button type="button">View Blog Submits</button>
                </Link>
                <Link to="/addblog">
                  <button type="button">Post a New Blog</button>
                </Link>
              </div>
              <div className="buttons">
                <Link to="/moodboardlist">
                  <button type="button">View Submitted MoodBoards</button>
                </Link>
              </div>
            </Fade>
          </div>
        </>
      ) : (
        <>
          <PageCover title="Admin Dashboard" />
          <div className="dashboardCont">
            <Fade up>
              <div className="buttons">
                <Link to="/bloglist">
                  <button type="button">View Blog Submits</button>
                </Link>
                <Link to="/addblog">
                  <button type="button">Post a New Blog</button>
                </Link>
              </div>
              <div className="buttons">
                <Link to="/messages">
                  <button type="button">View Received Messages</button>
                </Link>
                <Link to="/nominations">
                  <button type="button">View Speaker Nominations</button>
                </Link>
              </div>
              <div className="buttons">
                <Link to="/calist">
                  <button type="button">View CA Registrations</button>
                </Link>
                <Link to="/bookings">
                  <button type="button">View Promocode Bookings</button>
                </Link>
              </div>
              <div className="buttons">
                <Link to="/moodboardlist">
                  <button type="button">View Submitted MoodBoards</button>
                </Link>
                <Link to="/teamregistrations">
                  <button type="button">View Team Registrations</button>
                </Link>
              </div>
              <div className="buttons">
                <Link to="/sponsornominationslist">
                  <button type="button">View Sponsor Nominations 2023</button>
                </Link>
                <Link to="/speakernominationslist">
                  <button type="button">View Speaker Nominations 2023</button>
                </Link>
              </div>
            </Fade>
          </div>
        </>
      )}
      {/* else{
      <PageCover title="Admin Dashboard" />
     
        } */}
    </React.Fragment>
  );
};

export default dashboardComponent;
