import React from 'react'
import "./SingleSpeaker2023.css"
import { FaInstagram, FaTwitter, FaFacebookF,FaYoutube,FaGlobeAmericas,FaLinkedin } from "react-icons/fa";
import devansh from "../../img/devansh.jpg"




function SingleSpeaker2023(props) {

 

  return (
    <div className="speakerCard">
    <div className="first-content">
     <img src={props.imgURL} alt="Speaker"/>
     <div>
     <span>{props.name}</span>
     <span>{props.designation}</span>
     </div>
    </div>
    <div className="second-content">
  <span>
    {props.speakerTwitter && <a target='_blank' href={props.speakerTwitter}><p style={{color:'red'}}><FaTwitter/></p></a>}
    {props.speakerInstagram && <a target='_blank' href={props.speakerInstagram}><p style={{color:'red'}}><FaInstagram/></p></a>}
    {props.speakerFacebook && <a target='_blank' href={props.speakerFacebook}><p style={{color:'red'}}><FaFacebookF/></p></a>}
    {props.speakerYT && <a target='_blank' href={props.speakerYT}><p style={{color:'red'}}><FaYoutube/></p></a>}
    {props.linkedIn && <a target='_blank' href={props.linkedIn}><p style={{color:'red'}}><FaLinkedin/></p></a>}
    {props.website && <a target='_blank' href={props.website}><p style={{color:'red'}}><FaGlobeAmericas/></p></a>}
  </span>
  <span id={props.id} onClick={props.handleOpen}>VIEW PROFILE</span>
    </div>
  
  
  </div>
  )
}

export default SingleSpeaker2023
