import React from "react";
import "./AboutUsComponent.css";
import "./AboutUs2023.css"
import PageCover from "../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";
import UTurn1 from "../../img/u-turn.png"
import UTurn from "../../img/pivot3.png"

const About = () => {
  const handleOnClick = (e) => {
    e.preventDefault()
    setForm
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>TEDxBITD - About Us</title>
        <meta
          name="description"
          content="Living up to the concept of ideas worth spreading, BIT Durg is here to host the first-ever TED event. TEDxBITD aims to bring different groups of people together to share a unique experience."
        />
        <meta
          name="keywords"
          content="ted,tedx,bit,durg,speakers,bitd,event,campus,college,bhilai"
        />
      </Helmet>
      <div>
        <PageCover title="About Us" />
        <Fade up>
          <div className="mainSection">
            <div className="ted">
              <h3 className="title">TED</h3>
              <p>
                TED is a nonprofit devoted to spreading ideas, usually in the
                form of short, powerful talks (18 minutes or less). TED began in
                1984 as a conference where Technology, Entertainment and Design
                converged and today covers almost all topics from Science to
                Business to Global Issues - in more than 100 languages.
                <br />
                <br />
                TED is owned by a nonprofit, nonpartisan foundation. Their
                agenda is to make great ideas accesible and spark conversation.
              </p>
            </div>
            <div className="tedx">
              <h3 className="title">
                TED<sup>x</sup>
              </h3>
              <p>
                TEDx was created in 2009 in the spirit of TED's mission, "Ideas
                worth spreading". It supports independent organizers who want to
                create TED-like event in their own community.
                <br />
                <br />
                In the spirit of ideas worth spreading, TEDx is a program of
                local, self-organized events that bring people together to share
                a TED-like experience. At a TEDx event, TEDTalks video and live
                speakers combine to spark deep discussion and connection in a
                small group. These local, self-organized events are branded
                TEDx, where x = independently organized TED event. The TED
                Conference provides general guidance for the TEDx program, but
                individual TEDx events are self-organized (subject to certain
                rules and regulations).
              </p>
            </div>
          </div>
          <div className="tedxbitd">
            <h2 className="lineTitle">
              <span>
                <strong>TEDxBITD</strong>
              </span>
            </h2>
            <p>
              The idea of a day with insightful stories, compelling talks, and
              inspiring conversations itself sounds intriguing, doesn’t it?{" "}
              <strong>BIT Durg</strong> plans to bring you the greatest
              experience of the year 2023. <strong>TEDxBITD</strong> is a
              platform provided to <strong>BIT Durg</strong> to host a TEDx
              event with amazing speakers, a live audience, and much more.
              <br />
              <br />
              <strong>BIT Durg</strong> aims to spark and generate TED IDEAS
              WORTH SPREADING, amongst the students and audience. We are looking
              forward to creating a unique gathering to unleash hidden ideas and
              make the day memorable. Unpacking some great memories,{" "}
              <strong>TEDxBITD</strong> is inviting speakers from different
              disciplines of life, presenting multiple issues to be addressed.
              After all, there are numerous ideas worth spreading!
              <br />
              <br />
              We believe those who keep on experimenting even after multiple
              failures are the ones who are bound to be successful. They have
              the spark in their eyes to transform mismatched ideas into a
              perfect symphony. <strong>TEDxBITD</strong> looks forward to
              welcoming you all and together we shall find out about our amazing
              speakers' <strong>Pivot-The Turning Point</strong> moment.
            </p>
          
            <h2 className="n-lineTitle lineTitle">
              <span>
                <strong>Theme 2023</strong>
              </span>
            </h2>
            <Fade up>
          <div className="n-themeDiv blackBg">
          <img src={UTurn} alt="Pivot" /> 
            <h2 className="n-title">" Pivot - The Turning Point " </h2>
            <p>
            "Pivot: The Turning Point" encourages us to embrace change, take risks, and make bold moves that lead to positive outcomes. It emphasizes the importance of understanding the windows of opportunity and embracing the shifts that can lead to pivotal moments. It also emphasizes the importance of becoming more accepting of change and adapting to it. The event will explore stories of individuals, organizations, and societies that have reached pivotal moments and made significant changes.

            </p>
          </div>
          </Fade>
          </div>
          
          
          {/* <div className="blackBg">
            <h2>Mission</h2>
            <p>
              The main aim of TEDxBITD is to spark meaningful conversation and
              generate ideas worth spreading among the students, pertaining to
              the theme of TEDxBITD, i.e; "Water on Electricity”. This will be
              achieved by inviting different personalities as speakers, who have
              brought about changes in the society, or have achieved
              extraordinary feats in their lives that are otherwise difficult to
              accomplish. Maximum efforts will be put so that ideas and talks
              from a plethora of fields can be brought for the students by the
              medium of TEDxBITD.
            </p>
          </div> */}
          <h3 style={{ textAlign: "center", marginBottom: "3em" }}>
            For more information,{" "}
            <a
              href="/contact"
              style={{ textDecoration: "none", color: "#e62b1e" }}
            >
              Contact us
            </a>{" "}
            now.
          </h3>
        </Fade>
      </div>
    </React.Fragment>
  );
};

export default About;
