import React from "react";
import "./EventHighlightsComponent.css";
import PageCover from "../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import SingleVideo from "./SingleVideoComponent/SingleVideoComponent";

const EventHighlights = () => {
  return (
    <React.Fragment>
      <PageCover title="Event Highlights" />
      <div className="highlightsCont">
        <Fade up>
          <h2 className="lineTitle">
            <span>
              <strong>Speakers</strong>
            </span>
          </h2>
          <div className="videosList">
            <Fade up>
              <SingleVideo
                video="https://www.youtube.com/embed/4YHe25uX2h8"
                speaker="Dr. Jaspal Singh"
                title="Can Intelligence and Success be Correlated?"
              />
            </Fade>
            <Fade up>
              <SingleVideo
                video="https://www.youtube.com/embed/XLlxUwf6PdQ"
                speaker="Aman Shukla"
                title="How belief system is the invisible force behind your outcomes?"
              />
            </Fade>
            <Fade up>
              <SingleVideo
                video="https://www.youtube.com/embed/U9LbZ4yEz_E"
                speaker="Dr. Deeksha Sharma"
                title="How understanding Emotional Intelligence can improve our lives?"
              />
            </Fade>
            <Fade up>
              <SingleVideo
                video="https://www.youtube.com/embed/HrjAp1rMFKY"
                speaker="Angela Kumar"
                title="The Power of will"
              />
            </Fade>
            <Fade up>
              <SingleVideo
                video="https://www.youtube.com/embed/mmGiNe6mpQU"
                speaker="Suhas Misra"
                title="Things to keep in mind if you have Start-up Dreams"
              />
            </Fade>
            <Fade up>
              <SingleVideo
                video="https://youtube.com/embed/dl4AXA8W0B4"
                speaker="Shrawan Kumar Yadav"
                title="Frustration - The Foundation of Growth and Success"
              />
            </Fade>
            <Fade up>
              <SingleVideo
                video="https://youtube.com/embed/YAZLlJHD5oM"
                speaker="Lt. Col. D D Goel"
                title="Be “shameless” for the pursuit of your dreams"
              />
            </Fade>
          </div>
        </Fade>
        <Fade up>
          <h2 className="lineTitle">
            <span>
              <strong>Performances</strong>
            </span>
          </h2>
          <div className="videosList">
            <Fade up>
              <SingleVideo
                video="https://www.youtube.com/embed/BtbKdcM1QR4"
                speaker="Anwesha Bhatia"
                title="Dance performance"
              />
            </Fade>
            <Fade up>
              <SingleVideo
                video="https://www.youtube.com/embed/-_Q4fK3SNUs"
                speaker="Virago - The Girl Group"
                title="A Rollicking Recital of Rhythm"
              />
            </Fade>
          </div>
        </Fade>
      </div>
    </React.Fragment>
  );
};

export default EventHighlights;
