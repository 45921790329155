import React, { useState, useEffect } from "react";
import "./BookingComponent.css";
import PageCover from "../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import AlertModal from "../AlertModalComponent/AlertModalComponent";

const Referral = () => {
  const [modal, showModal] = useState("");

  const closeModal = () => {
    showModal("");
  };

  const [referral, setReferralCode] = useState("");
  const [razorpayBtn, setRazorpayBtn] = useState("d-none");
  const [referralFormBtn, setReferralFormBtn] = useState("d-block");
  const ApplyReferral = (e) => {
    e.preventDefault();

    const referralCodes = [
      "VIK08",
      "LAVA75",
      "MAN27",
      "ABH21",
      "GAU19",
      "MM04",
      "ABH09",
      "ARIIN06",
      "SHR07",
      "BHUMIKA26",
      "RAJ10",
      "ZUN13",
      "CHA25",
      "HAR10",
      "ANUOSHKA23",
      "ANU15",
      "KAV29",
      "HAR10",
      "TAR12",
      "DG01",
      "SON21",
      "MUSKA26",
      "SAKET01",
      "RIS16",
      "BHR25",
      "SIT17",
      "NIL13",
      "SHU30",
      "SAKET01",
      "ARYA09",
      "ANS26",
      "KHU21",
      "AHM20",
      "RITESH22",
      "PRA04",
      "GADDI23",
      "ADY28",
      "SHREYANSH07",
      "KUN23",
      "VAI06",
      "AIS10",
      "TUS15",
      "MANN03",
      "DS7",
      "HIMANSHU25",
    ];

    if (referralCodes.includes(referral)) {
      {
        window.innerWidth <= 700
          ? setRazorpayBtn("d-flex flex-column justify-content-around")
          : setRazorpayBtn("d-flex justify-content-around");
      }

      setReferralFormBtn("d-none");
    } else {
      setRazorpayBtn("d-none");
      setReferralFormBtn("d-block");
      showModal(
        <AlertModal
          message="Please apply a valid referral code!"
          icon="failed.png"
          close={closeModal}
        />
      );
    }
  };

  // PrimePlus
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://checkout.razorpay.com/v1/payment-button.js";
  //   script.setAttribute("data-payment_button_id", "pl_MOd2ZovXHWgAbW");
  //   script.setAttribute("async", null);
  //   document.querySelector(".razorpayForm").appendChild(script);
  // }, []);

  // Premium
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://checkout.razorpay.com/v1/payment-button.js";
  //   script.setAttribute("data-payment_button_id", "pl_MOeBuKCFcjJYvm");
  //   script.setAttribute("async", null);
  //   document.querySelector(".razorpayForm").appendChild(script);
  // }, []);

  // Prime
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "https://checkout.razorpay.com/v1/payment-button.js";
  //   script.setAttribute("data-payment_button_id", "pl_MOcYKn6T99DVUX");
  //   script.setAttribute("async", null);
  //   document.querySelector(".razorpayForm").appendChild(script);
  // }, []);

  return (
    <React.Fragment>
      {modal}
      <div className="bookingCont">
        <div className="header referralHeader height-100">
          <div className="content">
            <h2 className="title">Apply your Referral Code!</h2>
            <form className={referralFormBtn} onSubmit={ApplyReferral}>
              <div className="formRow">
                <div className="inputGroup">
                  <input
                    type="text"
                    name="referralcode"
                    id="referralcode"
                    placeholder="Referral Code*"
                    onChange={(e) => setReferralCode(e.target.value)}
                    value={referral}
                    required
                  />
                </div>
              </div>
              <div className="inputGroup">
                <button type="submit">APPLY</button>
              </div>
            </form>
            <div style={{ width: "100%", marginTop: "5em" }}>
              <form className={"razorpayForm " + razorpayBtn}></form>
              {/* <form className={"razorpayForm " + razorpayBtn}></form>
            <form className={"razorpayForm " + razorpayBtn}></form> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Referral;
