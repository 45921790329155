import React, { useEffect, useState } from "react";
import "./MoodBoardListComponent.css";
import PageCover from "../../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import { db } from "../../../firebase";
import Moment from "moment";
import { Alert, ButtonToggle } from "reactstrap";

const blogListComponent = () => {
  const [moodboard, setMoodBoard] = useState([]);

  useEffect(() => {
    const fetchdata = async () => {
      db.collection("moodBoard").onSnapshot(function (data) {
        setMoodBoard(
          data.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        );
      });
    };
    fetchdata();
  }, []);

  const verifyMoodBoard = async (id) => {
    db.collection("moodBoard").doc(id).update({
      verify: true,
    });
  };

  const unVerifyMoodBoard = async (id) => {
    db.collection("moodBoard").doc(id).update({
      verify: false,
    });
  };

  let counter = 0;

  return (
    <React.Fragment>
      <PageCover title="Mood-Board Submits" />
      <div className="dashboardMoodBoardListCont">
        <div className="moodBoards">
          {moodboard?.map((data, i) => {
            counter++;
            return (
              <Fade up key={i}>
                <div className="singleMoodBoard">
                  <div className="left">
                    <div className="title">{data.blogtitle}</div>
                    <div className="name">{data.fullname}</div>
                    <div className="date">
                      Submitted on:{" "}
                      {Moment(data.createdAt.toDate().toString()).format("lll")}
                    </div>
                  </div>
                  <div className="right">
                    <a href={data.url} className="mr-2" target="_blank">
                      <button type="button">
                        <i className="fas fa-download"></i>
                      </button>
                    </a>
                    {data.verify == false ? (
                      <button
                        type="button"
                        className="success"
                        onClick={() => verifyMoodBoard(data.id)}
                      >
                        <i className="fas fa-check"></i>
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => unVerifyMoodBoard(data.id)}
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    )}
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
        {counter === 0 ? (
          <Fade up>
            <Alert
              color="danger"
              style={{ textAlign: "center", padding: "1.5em 0" }}
            >
              Oops! Looks like there are no blog submits at the moment!
              <br />
              <br />
              <a href="/dashboard">
                <ButtonToggle color="danger">Go Back</ButtonToggle>
              </a>
            </Alert>
          </Fade>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default blogListComponent;
