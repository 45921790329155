import React, { useState, useEffect } from "react";
import "./TicketBookingComponent.css";
import PageCover from "../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import { db } from "../../firebase";
import AlertModal from "../AlertModalComponent/AlertModalComponent";
import emailjs from "emailjs-com";
import { Progress } from "reactstrap";

const TicketBooking = () => {
  const [modal, showModal] = useState("");

  const closeModal = () => {
    showModal("");
  };

  const [title, setTitle] = useState("");
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [contactno, setContactNo] = useState("");
  const [occupation, setOccupations] = useState("");
  const [promocode, setPromoCode] = useState("");

  const [loading, setLoading] = useState(null);

  const BookTicket = (e) => {
    e.preventDefault();
    setLoading(
      <div className="inputGroup progressBar">
        <Progress animated color="info" value={100}>
          Confirming...
        </Progress>
      </div>
    );

    if (
      title !== "" &&
      fullname !== "" &&
      email !== "" &&
      contactno != "" &&
      occupation !== "" &&
      promocode !== ""
    ) {
      if (
        promocode === "AMAN" ||
        promocode === "DD" ||
        promocode === "SUHAS" ||
        promocode === "AMANS" ||
        promocode === "ANGELA" ||
        promocode === "DEEPAK" ||
        promocode === "SHRAWAN" ||
        promocode === "JASPAL" ||
        promocode === "RUPESH" ||
        promocode === "IdeaCodify" ||
        promocode === "TechEffin" ||
        promocode === "NerdyAcademy" ||
        promocode === "MyFM" ||
        promocode === "Feevin" ||
        promocode === "SENIORS" ||
        promocode === "PERFORMER" ||
        promocode === "TEDXBITD2021" ||
        promocode === "VIP" ||
        promocode === "ORGANIZER"
      ) {
        db.collection("Speaker Tickets")
          .add({
            title: title,
            fullname: fullname,
            email: email,
            contactno: contactno,
            occupation: occupation,
            promocode: promocode,
            date: new Date(),
          })
          .then(() => {
            setLoading(null);
            showModal(
              <AlertModal
                message="Your ticket has been booked. It will be sent to you via email before the event."
                icon="successful.png"
                reload="true"
                close={closeModal}
              />
            );
          })
          .catch((error) => {
            showModal(
              <AlertModal
                message={error}
                icon="failed.png"
                reload="true"
                close={closeModal}
              />
            );
            setLoading(null);
          });

        emailjs
          .sendForm(
            "service_n6fo218", // Service Id
            "template_nbroge3", // Template Id
            e.target,
            "user_j147cM4xSAMaZzHRF7Kqm" // User Id
          )
          .then(console.log("Successful"));
        e.target.reset();
      } else {
        setLoading(null);
        showModal(
          <AlertModal
            message="Promocode is invalid! Please try again."
            icon="failed.png"
            close={closeModal}
          />
        );
      }
    } else
      showModal(
        <AlertModal
          message="Please fill in all the details!"
          icon="failed.png"
          close={closeModal}
        />
      );
  };

  return (
    <React.Fragment>
      {modal}
      <PageCover title="Book your Ticket" />
      <div className="submitCont">
        <Fade up>
          <div className="instructions">
            <Fade up>
              <h3>Read the Instructions before booking:</h3>
              <ol>
                <li>Fill in all the details correctly.</li>
                <li>
                  Please enter a valid promocode that has been provided to you.
                </li>
                <li>
                  Wait for a few seconds after submitting the form, you will see
                  a confirmation message once your ticket has been confirmed.
                </li>
                <li>
                  After submission, a mail will be sent to your entered email
                  id, kindly refer to that for further details.
                </li>
              </ol>
            </Fade>
          </div>
          <h2 className="lineTitle">
            <span>
              <strong>Personal</strong> Information
            </span>
          </h2>
          <form onSubmit={BookTicket}>
            <div className="formRow">
              <div className="inputGroup">
                <label htmlFor="title">Title:*</label>
                <select
                  name="title"
                  id="title"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                >
                  <option value="">--Select title--</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Mrs.">Mrs.</option>
                </select>
              </div>
              <div className="inputGroup">
                <label htmlFor="fullname">Full Name:*</label>
                <input
                  type="text"
                  name="fullname"
                  id="fullname"
                  placeholder="Full Name"
                  onChange={(e) => setFullName(e.target.value)}
                  value={fullname}
                  required
                />
              </div>
            </div>
            <div className="formRow">
              <div className="inputGroup">
                <label htmlFor="emailid">Email-ID:*</label>
                <input
                  type="email"
                  name="emailid"
                  id="emailid"
                  placeholder="Email ID"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="contactno">Contact No.:*</label>
                <input
                  type="text"
                  name="contactno"
                  id="contactno"
                  placeholder="Contact No."
                  onChange={(e) => setContactNo(e.target.value)}
                  value={contactno}
                  required
                />
              </div>
            </div>
            <div className="formRow">
              <div className="inputGroup">
                <label htmlFor="occupation">Occupation:*</label>
                <input
                  type="text"
                  name="occupation"
                  id="occupation"
                  placeholder="Occupation"
                  onChange={(e) => setOccupations(e.target.value)}
                  value={occupation}
                  required
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="promocode">Promocode:*</label>
                <input
                  type="text"
                  name="promocode"
                  id="promocode"
                  onChange={(e) => setPromoCode(e.target.value)}
                  value={promocode}
                  placeholder="Promocode"
                  required
                />
              </div>
            </div>
            {loading}
            <div className="inputGroup">
              <button type="submit">BOOK TICKET</button>
            </div>
          </form>
        </Fade>
      </div>
    </React.Fragment>
  );
};

export default TicketBooking;
