import React, { useEffect, useState } from "react";
import PageCover from "../../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import { db } from "../../../firebase";
import Moment from "moment";
import { Alert, ButtonToggle } from "reactstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const sponsorNominationsListComponent = () => {
  const [application, setApplication] = useState([]);

  useEffect(() => {
    db.collection("sponsornominations23")
      .orderBy("createdAt", "desc")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setApplication(data);
      });
  }, []);

  let counter = 0;

  return (
    <React.Fragment>
      <PageCover title="Sponsor Nominations" />
      <div className="registrationsListCont">
        <table id="bookingsExcelTable" className="table d-none">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Company Name</th>
              <th>Company Industry</th>
              <th>Company Email</th>
              <th>Company Website</th>
              <th>PoC Name</th>
              <th>PoC Position</th>
              <th>PoC Mobile No.</th>
              <th>PoC Email</th>
              {/* <th>Deliverables</th> */}
              <th>Queries</th>
              <th>Nominated At</th>
            </tr>
          </thead>
          <tbody>
            {application?.map((data, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td>{data.companyName}</td>
                  <td>{data.companyIndustry}</td>
                  <td>{data.companyEmail}</td>
                  <td>{data.companyWebsite}</td>
                  <td>{data.pocName}</td>
                  <td>{data.pocPosition}</td>
                  <td>{data.pocMobile}</td>
                  <td>{data.pocEmail}</td>
                  {/* <td>{data.checkbox[true]}</td> */}
                  <td>{data.queries}</td>
                  <td>
                    {Moment(data.createdAt?.toDate().toString()).format("lll")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={{ margin: "0 0 3em 15px" }}>
          <ReactHTMLTableToExcel
            className="btn btn-info"
            table="bookingsExcelTable"
            filename="TEDxBITD 2022 Sponsor Nominations"
            sheet="Sheet"
            buttonText="Export List as Spreadsheet"
          />
        </div>
        <div className="blogs">
          {application?.map((data, i) => {
            counter++;
            return (
              <Fade up key={i}>
                <div className="singleBlog">
                  <div className="left">
                    <div className="title">
                      {i + 1 + ". " + data.companyName}
                    </div>
                    <div className="date">
                      <b>Company Industry:&nbsp;&nbsp;</b>
                      {data.companyIndustry}
                    </div>
                    <div className="date">
                      <b>Company Email:&nbsp;&nbsp;</b>
                      {data.companyEmail}
                    </div>
                    <div className="date">
                      <b>Company Website:&nbsp;&nbsp;</b>
                      {data.companyWebsite}
                    </div>
                    <div className="date">
                      <b>PoC Name:&nbsp;&nbsp;</b>
                      {data.pocName}
                    </div>
                    <div className="date">
                      <b>PoC Position:&nbsp;&nbsp;</b>
                      {data.pocPosition}
                    </div>
                    <div className="date">
                      <b>PoC Mobile No.:&nbsp;&nbsp;</b>
                      {data.pocMobile}
                    </div>
                    <div className="date">
                      <b>PoC Email:&nbsp;&nbsp;</b>
                      {data.pocEmail}
                    </div>
                    <div className="date">
                      <b>Partnership Category(ies):&nbsp;&nbsp;</b>
                      {/* {data.checkbox} */}
                    </div>
                    <div className="date">
                      <b>Queries:&nbsp;&nbsp;</b>
                      {data.queries}
                    </div>
                    <div className="date">
                      <b>Nominated at:&nbsp;&nbsp;</b>
                      {Moment(data.createdAt?.toDate().toString()).format(
                        "lll"
                      )}
                    </div>
                  </div>
                  <div className="right">
                    <a
                      href={data.companyWebsite}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button type="button">Visit Website</button>
                    </a>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
        {counter === 0 ? (
          <Fade up>
            <Alert
              color="danger"
              style={{ textAlign: "center", padding: "1.5em 0" }}
            >
              Oops! Looks like there are no registrations at the moment!
              <br />
              <br />
              <a href="/dashboard">
                <ButtonToggle color="danger">Go Back</ButtonToggle>
              </a>
            </Alert>
          </Fade>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default sponsorNominationsListComponent;
