import React from "react";
import "./FooterComponent.css";
import logoWhite from "../../img/logos/logo-white.png";

const Footer = () => {
  return (
    <React.Fragment>
      <footer>
        <div className="footerTop">
          <img src={ logoWhite } alt="TEDxBITD White Brand Logo" className="logo" />
          <a href="mailto:tedxbitd@gmail.com" className="email">
            <input type="text" name="email" id="email" placeholder="Email" className="email" />
            <i className="fas fa-paper-plane"></i>
          </a>
          <div className="social">
            <a href="https://instagram.com/tedxbitd" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
            <a href="https://twitter.com/TEDxBITD" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
            <a href="https://facebook.com/TEDxBITD" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
            <a href="https://linkedin.com/company/tedxbitd" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
          </div>
        </div>
        <hr />
        <div className="footerBottom">
          <span>Copyright &copy; TEDxBITD 2023</span>
          <span>Site Developed By- <a>TEDxBITD Technical Team</a></span>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
