import React from 'react'
import { useAuth } from '../../../contexts/AuthContext';
import { Link } from "react-router-dom";

function LogoutButton() {

    const {currentUser, logout} = useAuth();
    
    return (
        <React.Fragment>
            { currentUser ? <Link onClick={logout}>LOG OUT</Link> : null}
        </React.Fragment>
    );
}

export default LogoutButton;
