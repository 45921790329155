import React, { useEffect, useState } from "react";
import "./PromocodeBookingsComponent.css";
import PageCover from "../../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import { db } from "../../../firebase";
import Moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const openMicListComponent = () => {
  const [application, setApplication] = useState([]);

  useEffect(() => {
    db.collection("Speaker Tickets")
      .orderBy("date", "asc")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setApplication(data);
      });
  }, []);

  const returnBookings = (promocode) => {
    let counter = 0;

    let bookingsList = null;

    bookingsList = application?.map((data, i) => {
      if (data.promocode === promocode) {
        counter++;
        return (
          <Fade up key={i}>
            <div className="singleBlog">
              <div className="left">
                <div className="title">{data.fullname}</div>
                <div className="date">
                  <b>Email:&nbsp;&nbsp;</b>
                  {data.email}
                </div>
                <div className="date">
                  <b>Contact No.:&nbsp;&nbsp;</b>
                  {data.contactno}
                </div>
                <div className="date">
                  <b>Booked on:&nbsp;&nbsp;</b>
                  {Moment(data.date?.toDate().toString()).format("lll")}
                </div>
              </div>
            </div>
          </Fade>
        );
      }
    });

    if (counter === 0)
      bookingsList = (
        <Fade up>
          <div className="singleBlog">
            <div className="left">
              <div className="title">No Bookings yet</div>
            </div>
          </div>
        </Fade>
      );

    return bookingsList;
  };

  return (
    <React.Fragment>
      <PageCover title="Bookings via Promocode" />
      <div className="promocodeBookingsCont">
        <table id="bookingsExcelTable" className="table d-none">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Name</th>
              <th>Contact No.</th>
              <th>E-mail</th>
              <th>TImestamp</th>
            </tr>
          </thead>
          <tbody>
            {application?.map((data, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td>{data.fullname}</td>
                  <td>{data.contactno}</td>
                  <td>{data.email}</td>
                  <td>
                    {Moment(data.date?.toDate().toString()).format("lll")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={{ margin: "0 0 3em 50px" }}>
          <ReactHTMLTableToExcel
            className="btn btn-info"
            table="bookingsExcelTable"
            filename="Bookings via Promocode"
            sheet="Sheet"
            buttonText="Export List as Spreadsheet"
          />
        </div>
        <h2 className="lineTitle">
          <span>
            <strong>Aman</strong> Dhattarwal
          </span>
        </h2>
        <div className="blogs">{returnBookings("AMAN")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>D D</strong> Goel
          </span>
        </h2>
        <div className="blogs">{returnBookings("DD")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>Suhas</strong> Misra
          </span>
        </h2>
        <div className="blogs">{returnBookings("SUHAS")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>Angela</strong> Kumar
          </span>
        </h2>
        <div className="blogs">{returnBookings("ANGELA")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>Deeksha</strong> Sharma
          </span>
        </h2>
        <div className="blogs">{returnBookings("DEEKSHA")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>Jaspal</strong> Singh
          </span>
        </h2>
        <div className="blogs">{returnBookings("JASPAL")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>Deepak</strong> Paramanand
          </span>
        </h2>
        <div className="blogs">{returnBookings("DEEPAK")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>Shrawan</strong> Kumar Yadav
          </span>
        </h2>
        <div className="blogs">{returnBookings("SHRAWAN")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>Aman</strong> Shukla
          </span>
        </h2>
        <div className="blogs">{returnBookings("AMANS")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>Rupesh</strong> Mahore
          </span>
        </h2>
        <div className="blogs">{returnBookings("RUPESH")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>Idea</strong> Codify
          </span>
        </h2>
        <div className="blogs">{returnBookings("IdeaCodify")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>TechEffin</strong>
          </span>
        </h2>
        <div className="blogs">{returnBookings("TechEffin")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>Nerdy</strong> Academy
          </span>
        </h2>
        <div className="blogs">{returnBookings("NerdyAcademy")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>94.3 My FM</strong>
          </span>
        </h2>
        <div className="blogs">{returnBookings("MyFM")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>Feevin</strong>
          </span>
        </h2>
        <div className="blogs">{returnBookings("Feevin")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>Seniors</strong>
          </span>
        </h2>
        <div className="blogs">{returnBookings("SENIORS")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>Performers</strong>
          </span>
        </h2>
        <div className="blogs">{returnBookings("PERFORMER")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>Teachers</strong>
          </span>
        </h2>
        <div className="blogs">{returnBookings("TEDXBITD2021")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>VIP</strong> Tickets
          </span>
        </h2>
        <div className="blogs">{returnBookings("VIP")}</div>

        <h2 className="lineTitle">
          <span>
            <strong>Shubharangshu</strong> Special
          </span>
        </h2>
        <div className="blogs">{returnBookings("ORGANIZER")}</div>
      </div>
    </React.Fragment>
  );
};

export default openMicListComponent;
