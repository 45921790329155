import React, { useEffect } from "react";

//Common Components
import Navbar from "./HeaderComponents/NavbarComponents/NavbarComponent";
import Footer from "./FooterComponents/FooterComponent";
import ScrollToTop from "./ScrollToTop";
import { BrowserRouter as Router, Route } from "react-router-dom";
//End Common Components

//Pages
import Home from "./HomeComponents/HomeComponent";
import About from "./AboutUsComponent/AboutUsComponent";
import About2023 from "./AboutUsComponent/AboutUsComponent2023";
import Speakers from "./SpeakersComponent/SpeakersComponent";
import Speakers2023 from "./SpeakersComponent/SpeakersComponent2023";
import Team from "./TeamComponent/TeamComponent";
import Team2023 from "./TeamComponent/TeamComponent2023"; //New Added
import Merchandise from "./MerchandiseComponent/Merchandise";
import Sponsors from "./SponsorsComponent/SponsorsComponent";
import Sponsors2023 from "./SponsorsComponent/SponsorsComponent2023";
import SponsorsFinal from "./SponsorsComponent/SponsorsComponentFinal";
import Contact from "./ContactUsComponent/ContactUsComponent";
import Schedule from "./ScheduleComponent/ScheduleComponent";
import Book2022 from "./BookingComponent/BookingComponent";
import Book from "./BookingComponent/BookingComponent2023";
import TicketStatus from "./BookingComponent/TicketStatusComponent";
import OpenMicGuidelines from "./OpenMicComponent/OpenMicGuidelinesComponent/OpenMicGuidelinesComponent";
import OpenMicApply from "./OpenMicComponent/OpenMicApplyComponent/OpenMicApplyComponent";
import CampusAmbassador from "./CampusAmbassadorComponent/CampusAmbassadorComponent";
import TicketBooking from "./TicketBookingComponent/TicketBookingComponent";
import EventHighlights from "./EventHighlightsComponent/EventHighlightsComponent";
import SponsorNominations from "./SponsorsComponent/SponsorNominations/SponsorNominations";
import SpeakerNominations from "./SpeakersComponent/SpeakerNominations/SpeakerNominations";
//End Pages

//Blog Components
import Blog from "./BlogComponent/BlogListComponent/BlogListComponent";
import SingleBlog from "./BlogComponent/SingleBlogComponent/SIngleBlogComponent";
import BlogGuidelines from "./BlogComponent/BlogGuidelinesComponent/BlogGuidelinesComponent";
import SubmitBlog from "./BlogComponent/SubmitBlogComponent/SubmitBlogComponent";
import SubmitMoodBoard from "./BlogComponent/SubmitMoodBoardComponent/SubmitMoodBoardComponent";
//End Blog Components

//MoodBoard
import MoodBoard from "./MoodBoardComponent/MoodBoardListComponent/MoodBoardListComponent";
import SingleMoodBoard from "./MoodBoardComponent/SingleMoodBoardComponent/SingleMoodBoardCompnent";
import AddMoodBoard from "./AdminComponent/AddMoodBoardComponent/AddMoodBoardComponent";

//Register Components
import RegisterGuidelines from "./RegisterComponent/RegisterGuidelinesComponent/RegisterGuidelinesComponent";
import RegisterForm from "./RegisterComponent/RegisterFormComponent/RegisterFormComponent";
//End Register Components

//Admin Components
import AdminLogin from "./AdminComponent/LoginComponent/LoginComponent";
import AdminDashboard from "./AdminComponent/DashboardComponent/DashboardComponent";
import BlogsList from "./AdminComponent/BlogListComponent/BlogListComponent";
import MoodBoardList from "./AdminComponent/MoodBoardListComponent/MoodBoardListComponent";
import MessagesList from "./AdminComponent/MessagesListComponent/MessagesListComponent";
import AddBlog from "./AdminComponent/AddBlogComponent/AddBlogComponent";
import OpenMicList from "./AdminComponent/OpenMicListComponent/OpenMicListComponent";
import SpeakersList from "./AdminComponent/SpeakersListComponent/SpeakersListComponent";
import CampusAmbassadorsList from "./AdminComponent/CampusAmbassadorComponent/CampusAmbassadorComponent";
import PromocodeBookings from "./AdminComponent/PromocodeBookingsComponent/PromocodeBookingsComponent";
import RegistrationsList from "./AdminComponent/RegistrationsComponent/RegistrationsComponent";
import TshirtPoll from "./TshirtPollComponent/TshirtComponent";
import SponsorNominationsList from "./AdminComponent/SponsorNominationsListComponent/SponsorNominationsListComponent";
import SpeakerNominationsList from "./AdminComponent/SpeakerNominationsListComponent/SpeakerNominationsListComponent";
import Referral from "./BookingComponent/Referral";
//End Admin Components

//Private Route
import PrivateRoute from "../PrivateRoute";
import { AuthProvider } from "../contexts/AuthContext";
import speakersListComponent from "./AdminComponent/SpeakersListComponent/SpeakersListComponent";

const mainComponent = (props) => {
  return (
    <React.Fragment>
      <Router>
        <AuthProvider>
          <ScrollToTop />
          {window.location.pathname !== "/book" &&
          window.location.pathname !== "/ticketstatus" &&
          window.location.pathname !== "/referral" ? (
            <Navbar />
          ) : null}
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/aboutus2022" component={About} />
          <Route exact path="/aboutus" component={About2023} />
          <Route exact path="/speakers2022" component={Speakers} />
          <Route exact path="/speakers" component={Speakers2023} />
          <Route exact path="/speakerslist" component={speakersListComponent} />
          <Route exact path="/team2022" component={Team} />
          <Route exact path="/team" component={Team2023} />
          {/* <Route exact path="/sponsors2022" component={Sponsors} /> */}
          {/* <Route exact path="/sponsors" component={SponsorsFinal} /> */}
          {/* <Route exact path="/sponsors2023" component={Sponsors2023} /> */}
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/addmoodboard" component={AddMoodBoard} />
          <Route exact path="/campusambassador" component={CampusAmbassador} />
          <Route exact path="/tshirtpoll" component={TshirtPoll} />
          <Route
            exact
            path="/sponsornominations"
            component={SponsorNominations}
          />
          <Route
            exact
            path="/speakernominations"
            component={SpeakerNominations}
          />
          {/* <Route exact path="/schedule" component={Schedule} /> */}
          {/* 
          <Route exact path="/openmicguidelines" component={OpenMicGuidelines} />
          <Route exact path="/apply" component={OpenMicApply} /> */}
          <Route exact path="/book" component={Book} />
          <Route exact path="/ticketstatus" component={TicketStatus} />
          <Route exact path="/highlights" component={EventHighlights} />
          {/* Blog Components */}
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/singleblog/:id" component={SingleBlog} />
          <Route exact path="/guidelines" component={BlogGuidelines} />
          <Route exact path="/submitblog" component={SubmitBlog} />
          <Route exact path="/submitmoodboard" component={SubmitMoodBoard} />
          {/* End Blog Components */}
          {/* MoodBoardComponents */}
          <Route exact path="/moodboard" component={MoodBoard} />
          <Route
            exact
            path="/singlemoodboard/:id"
            component={SingleMoodBoard}
          />
          {/* End MoodBoardComponents */}
          {/* Register Components */}
          {/* <Route exact path="/guidelines" component={RegisterGuidelines} />
          <Route exact path="/register" component={RegisterForm} /> */}
          {/* End Register Components */}
          {/* Private Route */}
          <Route exact path="/login" component={AdminLogin} />
          <PrivateRoute exact path="/addblog" component={AddBlog} />
          <PrivateRoute exact path="/dashboard" component={AdminDashboard} />
          <PrivateRoute exact path="/bloglist" component={BlogsList} />
          <PrivateRoute exact path="/moodboardlist" component={MoodBoardList} />
          <PrivateRoute exact path="/messages" component={MessagesList} />
          {/* <PrivateRoute exact path="/openmiclist" component={OpenMicList} /> */}
          <PrivateRoute exact path="/nominations" component={SpeakersList} />
          <PrivateRoute
            exact
            path="/calist"
            component={CampusAmbassadorsList}
          />
          <PrivateRoute exact path="/bookings" component={PromocodeBookings} />
          <PrivateRoute
            exact
            path="/teamregistrations"
            component={RegistrationsList}
          />
          <PrivateRoute
            exact
            path="/sponsornominationslist"
            component={SponsorNominationsList}
          />
          <PrivateRoute
            exact
            path="/speakernominationslist"
            component={SpeakerNominationsList}
          />
          {/* End Private Route */}
          <Route exact path="/referral" component={Referral} />
          <Route exact path="/merchandise" component={Merchandise} />
          <Route exact path="/viptickets" component={TicketBooking} />
          <Route exact path="/speakertickets" component={TicketBooking} />
          {window.location.pathname !== "/book" &&
          window.location.pathname !== "/ticketstatus" &&
          window.location.pathname !== "/referral" ? (
            <Footer />
          ) : null}
        </AuthProvider>
      </Router>
    </React.Fragment>
  );
};

export default mainComponent;
