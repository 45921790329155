import React, { useState, useEffect } from "react";
import "./MoodBoardList.css";
import { Fade } from "react-reveal";
import PageCover from "../../HeaderComponents/PageCover";
import { Helmet } from "react-helmet";
import { db } from "../../../firebase";
import Moment from "moment";
import SingleMoodBoard from "./SingleMoodBoard/SingleMoodBoard";

const MoodBoardList = () => {
  const [moodboards, setMoodBoards] = useState([]);

  useEffect(() => {
    const fetchdata = async () => {
      db.collection("moodBoard").onSnapshot(function (data) {
        setMoodBoards(
          data.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        );
      });
    };
    fetchdata();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>TEDxBITD - Mood-Boards</title>
        <meta
          name="description"
          content="Bring out your words your way. Whether you want your knowledge to be shared or your experience, TEDxBITD provides you a platform to publish your work, the way you like it."
        />
        <meta
          name="keywords"
          content="ted,tedx,bit,durg,speakers,bitd,event,campus,college,bhilai"
        />
      </Helmet>
      <PageCover title="Mood-Boards" />
      <div>
        <div className="submitBtn">
          <a href="/guidelines">
            <button type="button">Submit</button>
          </a>
        </div>

        <div className="moodBoardListCont">
          {moodboards.map((MoodBoard) => {
            return (
              <>
                {MoodBoard.verify == true ? (
                  <Fade up>
                    <SingleMoodBoard
                      to={`/singlemoodboard/${MoodBoard.id}`}
                      image={MoodBoard.url}
                      date={Moment(MoodBoard.createdAt.toDate()).format("LL")}
                      heading={MoodBoard.moodBoardTitle}
                      name={MoodBoard.fullname}
                    />
                  </Fade>
                ) : null}
              </>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default MoodBoardList;
