import React, { useState } from "react";
import ShirtHeader from "../../img/merchandise2023/tShirt.jpeg";
import "./Merchandise.css";
import SizeChartBox from "./SizeChartBox";

import { Fade } from "react-reveal";

import tshirt1 from "../../img/merchandise2023/tshirt1.png";
import tshirt2 from "../../img/merchandise2023/tshirt2.png";
import tshirt3 from "../../img/merchandise2023/tshirt3.png";
import cap1 from "../../img/merchandise2023/cap1.png";
import cap2 from "../../img/merchandise2023/cap2.png";

const Merchandise = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="merchContent">
      <div className={`${open ? "open popup" : "popup"}`}>
        <SizeChartBox handleCloseClick={handleClose} />
      </div>

      <div className="mainContent">
        <Fade up>
          <div className="mainContent-top">
            <img src={ShirtHeader} alt="" />
            <div>
              <p>
                THE OFFICIAL MERCHANDISE OF <br /> TEDxBITD 2023 CHAPTER IS HERE
              </p>
            </div>
          </div>
          <div className="sizeBtn">
            <button onClick={handleOpen}>SIZE CHART</button>
          </div>
        </Fade>
      </div>
      <div className="shirtDesc">
        <Fade up>
          <span>MERCHANDISE AVAILABLE</span>
          <div className="shirtBoxContainer">
            <div className="shirtBox">
              <img src={tshirt1} alt="" />

              {/* <div className="sizeBtn">
                <span>T-Shirt</span>
                <span style={{ color: "black" }}>
                  <p style={{ textDecoration: "line-through" }}>Rs. 999</p>
                  <p>Rs. 300</p>
                </span>
                <button className="buyBtn">Buy Now</button>
              </div> */}
              <div className="boxDesc">
                <p>SUBLIME</p>
                <p>Rs. 300/-</p>
                <a target="_blank" href="https://rzp.io/l/sublime-merchandise">
                  <button>Buy Now</button>
                </a>
              </div>
            </div>

            <div className="shirtBox">
              <img src={tshirt2} alt="" />

              {/* <div className="sizeBtn">
              <span>T-Shirt</span>
              <span style={{ color: "black" }}>
                <p style={{ textDecoration: "line-through" }}>Rs. 999</p>
                <p>Rs. 300</p>
              </span>

              <button className="buyBtn">Buy Now</button>
            </div> */}
              <div className="boxDesc">
                <p>X-FACTOR</p>
                <p>Rs. 300/-</p>
                <a target="_blank" href="https://rzp.io/l/xfactor-merchandise">
                  <button>Buy Now</button>
                </a>
              </div>
            </div>

            <div className="shirtBox">
              <img src={tshirt3} alt="" />

              {/* <div className="sizeBtn">
              <span>T-Shirt</span>
              <span style={{ color: "black" }}>
                <p style={{ textDecoration: "line-through" }}>Rs. 999</p>
                <p>Rs. 300</p>
              </span>
              <button className="buyBtn">Buy Now</button>
            </div> */}
              <div className="boxDesc">
                <p>PIVOT</p>
                <p>Rs. 300/-</p>
                <a target="_blank" href="https://rzp.io/l/pivot-merchandise">
                  <button>Buy Now</button>
                </a>
              </div>
            </div>

            <div className="shirtBox">
              <img src={cap1} alt="" />

              {/* <div className="sizeBtn">
              <span>T-Shirt</span>
              <span style={{ color: "black" }}>
                <p style={{ textDecoration: "line-through" }}>Rs. 999</p>
                <p>Rs. 300</p>
              </span>
              <button className="buyBtn">Buy Now</button>
            </div> */}
              <div className="boxDesc">
                <p>SHIFT-X</p>
                <p>Rs. 80/-</p>
                <a target="_blank" href="https://rzp.io/l/pivot-cap">
                  <button>Buy Now</button>
                </a>
              </div>
            </div>

            <div className="shirtBox">
              <img src={cap2} alt="" />
              <div className="boxDesc">
                <p>COMPASS</p>
                <p>Rs. 80/-</p>
                <a target="_blank" href="https://rzp.io/l/compass-cap">
                  <button>Buy Now</button>
                </a>
              </div>
            </div>

            {/* <div className="shirtBox">
          <img src={Shirt} alt="" />
          <div className="sizeBtn">
            <button className="">Buy Now</button>
          </div>
        </div> */}
          </div>
        </Fade>
      </div>

      <div></div>
    </div>
  );
};

export default Merchandise;
