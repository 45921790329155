import React, { Component } from "react";

class Timer extends Component {
  constructor(props) {
    super(props);
    this.countDownId = null;
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      expired: false,
    };
  }

  componentDidMount() {
    this.countDownId = setInterval(this.timerInit, 1000);
  }

  componentWillUnmount() {
    if (this.countDownId) {
      clearInterval(this.countDownId);
    }
  }

  timerInit = () => {
    const { startDate } = this.props;
    const now = new Date().getTime();
    if (!startDate) {
      this.setState({ expired: true });
      return;
    }
    const countDownStartDate = new Date(startDate).getTime();
    const distance = countDownStartDate - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // For countdown is finished
    if (distance < 0) {
      clearInterval(this.countDownId);
      this.setState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        expired: true,
      });
      return;
    }
    this.setState({ days, hours, minutes, seconds, expired: false });
  };

  render() {
    const { days, hours, minutes, expired } = this.state;
    if (expired) {
      return (
        <div className="expired" style={{ textAlign: "center" }}>
          The event has successfully finished!
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="timerSection" style={{ marginTop: "5em" }}>
          <div className="singleTimer">
            <div className="timerTitle">DAYS</div>
            <div className="timer">{days <= 9 ? "0" + days : days}</div>
          </div>
          <div className="singleTimer">
            <div className="timerTitle">HOURS</div>
            <div className="timer">{hours <= 9 ? "0" + hours : hours}</div>
          </div>
          <div className="singleTimer">
            <div className="timerTitle">MINUTES</div>
            <div className="timer">
              {minutes <= 9 ? "0" + minutes : minutes}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Timer;
