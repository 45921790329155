import React from "react";
import "./Card2.css";
// import instagram from "../img/instafinal.pg
import instagram from "../../../img/instafinal.png";
import linkedin2 from "../../../img/linkin.png";
import twitter2 from "../../../img/twitter.png";

function Card2(props) {
  return (
    <div className="cardContainer">
    <div className="person-card">
      <div className="card-img">
        {/* <img src="https://drive.google.com/uc?export=download&id=1QRA75iqctzUANr2neDTOY7JfHQdCWvco" /> */}
        <img src={props.path}/>

        <div className="card-desc">
          <p className="person-name">{props.name}</p>
          <p className="position">{props.post}</p>
        </div>
      </div>
      <div className="socialsDiv">
        <a
          href={props.instagram}
          target="__blank"
        >
          <img className="socials" src={instagram} alt="" />
        </a>
        <a href={props.twitter}
        target="__blank">
          <img className="socials" src={twitter2} alt="" />
        </a>
        <a href={props.linkedin}
        target="__blank">
          <img className="socials" src={linkedin2} alt="" />
        </a>
      </div>
    </div>
    </div>
  );
}

export default Card2;
