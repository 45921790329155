import React from "react";
import "./BlogGuidelinesComponent.css";
import PageCover from "../../HeaderComponents/PageCover";
import { Fade } from "react-reveal";

const blogGuidelines = () => {
  return (
    <React.Fragment>
      <PageCover title="Guidelines" />
      <div className="guidelinesCont">
        <h2 className="heading">
          An invitation to write for TEDxBITD
          <br />
          GUEST POST
        </h2>

        <section>
          <p>
            We're excited to announce that we are welcoming blogs & mood boards
            from our readers. Please make sure to follow up with these
            guidelines below, before submitting your blogs or mood boards.
          </p>
        </section>

        <section>
          <h4 className="subheading">Content:</h4>
          <p>
            We intend to provide our audience the most authentic blogs and
            delightful mood boards. It will help them take away something
            informative once they wade through them. We are aiming to make this
            a multi-niche platform where you can share your ideas regarding
            technology and entertainment designs or tell stories that are
            capable of bringing change. Aligning with the concept of{" "}
            <strong>ideas worth sharing</strong>, we are also looking forward to
            our authors to share their <strong>“Pivot: the turning point”</strong>{" "}
            moments with us.
          </p>
        </section>

        <section>
          <h4 className="subheading">Content Guidelines:</h4>
          <p>
            <ol>
              <li>
                We hope that the writer agrees not to publish the post elsewhere
                (including their blog or web site).
              </li>
              <li>
                As we reserve the right to edit the submission, we will review
                them & suggest any changes if needed and schedule it to be
                published.
              </li>
              <li>
                The writer should ensure proper attribution of data, quotations,
                and outside content referenced in the article.
              </li>
              <li>
                The writers are allowed to use one link that leads to their
                website or blog.
              </li>
              <li>
                The uploaded file for blogs should be of either one of these
                formats- '.doc' or '.docx'.
              </li>
            </ol>
          </p>
        </section>

        <section>
          <h4 className="subheading">Mood-Board Guidelines:</h4>
          <p>
            <ol>
              <li>For mood boards, utmost creativity will be appreciated.</li>
              <li>
                The mood boards may contain digital collages that arrange
                images, texts and other design elements into a format that
                represents the idea or message of the creator.
              </li>
              <li>
                Be sure to include your contact information so we know how best
                to get a hold of you.
              </li>
              <li>
                If the images attached are not their own, the creator should
                give a direct link crediting those images.
              </li>
              <li>
                For uploading mood boards the file format used should be either
                one of these- '.jpeg', '.jpg' or '.png'
              </li>
            </ol>
          </p>
        </section>

        <section>
          <p>
            <strong>
              NOTE: After submitting the blog/ mood board, wait until you see
              the confirmation message.
            </strong>
          </p>
        </section>

        <section>
          <p>
            We thank you for your interest in sharing your ideas, experiences
            and stories that you felt were worth spreading. We're looking
            forward to receiving your submission. We will work hard to get a
            great outcome for our readers along with our bloggers' success as
            well.
          </p>
        </section>

        <section>
          <a href="/submitblog">
            <button type="button">Submit Blog</button>
          </a>
          <a href="/submitmoodboard" className="ml-3">
            <button type="button">Submit Mood-Board</button>
          </a>
        </section>
      </div>
    </React.Fragment>
  );
};

export default blogGuidelines;
