import React from "react";

const SingleVideo = (props) => {
  return (
    <React.Fragment>
      <div className="singleVideo">
        <iframe
          src={props.video}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <h5 class="title">{props.title}</h5>
        <h6 className="speaker">{props.speaker}</h6>
      </div>
    </React.Fragment>
  );
};

export default SingleVideo;
