import React, { Component } from "react";
import "./TeamComponent.css";
import PageCover from "../HeaderComponents/PageCover";
import Image from "./ImageComponent/ImageComponent";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";

class Team extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>TEDxBITD - Team Members</title>
          <meta
            name="description"
            content="The critical feature to organizing any event and making it a success is to gather a team you could depend upon. TEDxBITD has a team of volunteers from BIT Durg working hard to make the event memorable."
          />
          <meta
            name="keywords"
            content="ted,tedx,bit,durg,speakers,bitd,event,campus,college,bhilai"
          />
        </Helmet>
        <PageCover title="Team 2022" />
        <div className="teamCont">
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Core</strong> Team
              </span>
            </h2>
            <div className="teamList">
              <Fade up>
                <Image
                  path="Kritika-Upadhyay.png"
                  name="Kritika Upadhyay"
                  post="Organizer"
                  contact="9109079518"
                  quote="I speak my mind and I never mind what I speak!"
                  instagram="kritikkkkaaaaaaaa"
                  linkedin="https://www.linkedin.com/in/kritika-upadhyay-2001"
                />
                <Image
                  path="Vinayak-Rawat.png"
                  name="Vinayak Rawat"
                  post="Co-Organizer"
                  contact="9571089472"
                  quote="That E-sports freak Everyone talks about 🎮"
                  instagram="ig_rawx"
                  linkedin="https://www.linkedin.com/in/vrawat2710"
                />
                <Image
                  path="Nandini-Pandey.png"
                  name="Nandini Pandey"
                  post="Curator"
                  contact="9827993660"
                  quote="Talk to me carefully; I might correct your grammar."
                  instagram="nandinip_"
                  linkedin="https://www.linkedin.com/in/nandini-pandey-2a7ba9190"
                />
                <Image
                  path="Shalini-Shrivas.png"
                  name="Shalini Shrivas"
                  post="Curator"
                  contact="7067303170"
                  quote="Being loud about the things that matter"
                  instagram="shalini_shrivas13"
                  linkedin="https://www.linkedin.com/in/shalini-shrivas-8775501b1"
                />
                <Image
                  path="Piyush-Gupta.png"
                  name="Piyush Gupta"
                  post="Event Management Head"
                  contact="7999528048"
                  quote="I don't take any responsibility for my jokes, just kidding!"
                  instagram="pi_u_shhh"
                  linkedin="https://www.linkedin.com/in/piyush-gupta-41494021a"
                />
                <Image
                  path="Suryansh-Bisen.png"
                  name="Suryansh Bisen"
                  post="Public Relations Head"
                  contact="9179322789"
                  quote="Surely, not an introvert."
                  instagram="suryansh.1191"
                  linkedin="https://www.linkedin.com/in/suryansh-bisen-83b7911b6"
                />
                <Image
                  path="Preeti-Gabel.png"
                  name="Preeti Gabel"
                  post="Marketing Head"
                  contact="9179243009"
                  quote="Find me enjoying sunset with pizza! 🌻🍕"
                  instagram="preeti.gabel"
                  linkedin="https://www.linkedin.com/in/preeti-gabel-3592151ab"
                />
                <Image
                  path="Aaryan-Khandelwal.png"
                  name="Aaryan Khandelwal"
                  post="Co-Technical Head"
                  contact="8103445828"
                  quote="I am sunset! ⛅"
                  instagram="aaryan610"
                  linkedin="https://www.linkedin.com/in/aaryan-khandelwal-89ba501a6"
                />
                <Image
                  path="Bavisetti-Narayan.png"
                  name="Bavisetti Narayan"
                  post="Co-Technical Head"
                  contact="9406108766"
                  quote="You might have read ample of lines till now. Then take a break have some water and GO GET SOME WORK TO DO."
                  instagram="bavisettinarayan"
                  linkedin="https://www.linkedin.com/in/bavisetti-narayan-a94b5918b"
                />
                {/* <Image
                  path="Rahul-Sharma.png"
                  name="Rahul Sharma"
                  post="Content Head"
                  contact="9399936844"
                  quote="Kesha tells me my love is her drug"
                  instagram="rahul.sharma0.0"
                  linkedin="https://www.linkedin.com/in/rahul-sharma-363865187"
                /> */}
                <Image
                  path="Harshjot-Singh.png"
                  name="Harshjot Singh"
                  post="Logistics & Hospitality Head"
                  contact="7646956519"
                  quote="not great at one-liners"
                  instagram="_.harshjot._"
                  linkedin="https://www.linkedin.com/in/harshjot-singh"
                />
                <Image
                  path="Anubhav-Bhatt.png"
                  name="Anubhav Bhatt"
                  post="Sponsorship Head"
                  contact="8234993336"
                  quote="HULT Prize is Back!!!"
                  instagram="anubhav.bhatt.505"
                  linkedin="https://www.linkedin.com/in/anubhavbhatt552"
                />
                <Image
                  path="Amit-Kumar.png"
                  name="Amit Kumar"
                  post="Finance Head"
                  contact=""
                  quote=""
                  instagram="amit_kumar_sah14"
                  linkedin="https://www.linkedin.com/in/amit-kumar-ba8214191"
                />
                <Image
                  path="Vedant-Mishra.png"
                  name="Vedant Mishra"
                  post="Video Production Head"
                  contact="7024974968"
                  quote="I'm not great at the advice, can I interest you in a sarcastic comment?"
                  instagram="beingvedant"
                  linkedin="https://www.linkedin.com/in/vedant-mishra-aaa1641b9"
                />
                <Image
                  path="Palak-Dewangan.png"
                  name="Palak Dewangan"
                  post="Graphic Design Head"
                  contact="7617236125"
                  quote="Roads? Where we're going, we don't need roads."
                  instagram="palak_.dewangan"
                  linkedin="https://www.linkedin.com/in/palak-dewangan-352ba31a0"
                />
              </Fade>
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Event Management</strong> Team
              </span>
            </h2>
            <div className="teamList">
              <Fade up>
                <Image
                  path="Ojaswi-Soni.png"
                  name="Ojaswi Soni"
                  contact="7389372555"
                  quote="Thinking I should trademark 'I know'!"
                  instagram="soni.ojaswi"
                  linkedin="https://www.linkedin.com/in/ojaswi-soni-b32149199"
                />
                <Image
                  path="Surbhi-Dewangan.png"
                  name="Surbhi Dewangan"
                  contact=""
                  quote="Love is well stocked craft room. 💕"
                  instagram="surbhi_0110"
                  linkedin="https://www.linkedin.com/in/surbhi-dewangan-7235631aa"
                />
                <Image
                  path="Anurag-Rai.png"
                  name="Anurag Rai"
                  contact="7999528048"
                  quote="Literal meaning of my name is love so that's why i can easily say that everyone anurag anurag"
                  instagram="anurag_rai15"
                  linkedin=""
                />
                <Image
                  path="Gamak.png"
                  name="Gamak"
                  contact="6267791826"
                  quote="'Sugar coated Mirror', when it comes to behaviour.....😉"
                  instagram="ringingtorpedo"
                  linkedin="https://www.linkedin.com/in/gamak-sahu-9043a2225"
                />
                <Image
                  path="Smriti-Pandey.png"
                  name="Smriti Pandey"
                  contact="7224970876"
                  quote="Being a Monica since 2001."
                  instagram="_.smriti_pandey_._"
                  linkedin="https://www.linkedin.com/in/smriti-pandey-b06930223"
                />
              </Fade>
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Public Relations</strong> Team
              </span>
            </h2>
            <div className="teamList">
              <Fade up>
                <Image
                  path="Nimrat-Kaur-Mudhar.png"
                  name="Nimrat Kaur Mudhar"
                  contact="9109192229"
                  quote="It looks like Romedy Now but it is Comedy Central in real life"
                  instagram="nimmmstagram"
                  linkedin="https://www.linkedin.com/in/nimrat-kaur-mudhar-8167b3173"
                />
                <Image
                  path="Anushka-Dhar.png"
                  name="Anushka Dhar"
                  contact=""
                  quote="An amalgamation of all things wacky! ¯\_(ツ)_/¯"
                  instagram="ayynushka"
                  linkedin="https://www.linkedin.com/in/anushka-dhar-19432a227"
                />
              </Fade>
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Marketing</strong> Team
              </span>
            </h2>
            <div className="teamList">
              <Fade up>
                <Image
                  path="Aman-K.A.png"
                  name="Aman K.A."
                  contact="7647079411"
                  quote="Go self-made to become legendary!"
                  instagram="aman_thomas_"
                  linkedin="https://www.linkedin.com/in/aman-k-a-303561183"
                />
                <Image
                  path="Ariin-Singh.png"
                  name="Ariin Singh"
                  contact="7354797570"
                  quote="Love to interact distinct minds and spreading kindness like confetti😊"
                  instagram="ariinsingh"
                  linkedin="https://www.linkedin.com/in/ariin-singh-88841b209"
                />
                {/* <Image
                  path="Shruti-Gupta.png"
                  name="Shruti Gupta"
                  contact="8103497760"
                  quote="If someone calls me imperfect , i m!! , coz this whole universe is made out of imperfection of the matters involved , so as i"
                  instagram="_shrutiiii_2806"
                  linkedin="https://www.linkedin.com/in/shruti-gupta-130baa212"
                /> */}
              </Fade>
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Technical</strong> Team
              </span>
            </h2>
            <div className="teamList">
              <Fade up>
                <Image
                  path="Ritesh-Thakur.png"
                  name="Ritesh Kumar Thakur"
                  contact="8349158303"
                  quote="Leave it better than you found it ✨"
                  instagram="ritesh._.thakur_"
                  linkedin="https://www.linkedin.com/in/ritesh-kumar-thakur-000209203"
                />
              </Fade>
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Content</strong> Team
              </span>
            </h2>
            <div className="teamList">
              <Fade up>
                <Image
                  path="Manu-Pandey.png"
                  name="Manu Pandey"
                  contact="9907981182"
                  quote="Stay humble, Work hard."
                  instagram="manu.pandey25"
                  linkedin="https://www.linkedin.com/in/manu-pandey-99434022a"
                />
                <Image
                  path="Ananya-Chakraborty.png"
                  name="Ananya Chakraborty"
                  contact="7771065135"
                  quote="Smiling is my favorite exercise😄"
                  instagram="ananya_chaks"
                  linkedin="https://www.linkedin.com/in/ananya-chakraborty-607734229"
                />
                <Image
                  path="Mansi-Bhat.png"
                  name="Mansi Bhat"
                  contact=""
                  quote="I believe in making the impossible possible because there's no fun in giving up......"
                  instagram="mansibhat08"
                  linkedin="https://www.linkedin.com/in/mansi-bhat-076736229"
                />
              </Fade>
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Logistics & Hospitality</strong> Team
              </span>
            </h2>
            <div className="teamList">
              <Fade up>
                <Image
                  path="Himanshu-Deshmukh.png"
                  name="Himanshu Deshmukh"
                  contact="9399504126"
                  quote="You can't lose hope on your goal, whenever you don't have any option to achieve."
                  instagram="heyyymanshu"
                  linkedin="https://www.linkedin.com/in/himanshu-deshmukh-1ab2a922a"
                />
                <Image
                  path="Nihal-Singh.png"
                  name="Nihal Singh"
                  contact="8817366902"
                  quote="Don't underestimate me, that's my mother's job"
                  instagram="mr.canzova"
                  linkedin="https://www.linkedin.com/in/nihal-singh-6b2116200"
                />
                <Image
                  path="Sonal-Choudhary.png"
                  name="Sonal Choudhary"
                  contact="6263330551"
                  quote="Are you sweet or do I need to show you my baking talent?"
                  instagram="sonal_choudhary32"
                  linkedin="https://www.linkedin.com/in/sonal-choudhary-024785217"
                />
              </Fade>
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Sponsorship</strong> Team
              </span>
            </h2>
            <div className="teamList">
              <Fade up>
                <Image
                  path="Manas-Diwan.png"
                  name="Manas Diwan"
                  contact="7773059502"
                  quote="Introvert on the outside, extrovert on the inside!"
                  instagram="manas.diwan"
                  linkedin="https://www.linkedin.com/in/manas-diwan-40860311a"
                />
                <Image
                  path="Yukti-Jhawar.png"
                  name="Yukti Jhawar"
                  contact="9179197697"
                  quote="I will be sweet as sugar until you start annoying me"
                  instagram="yukti_3004"
                  linkedin="http://www.linkedin.com/in/yukti-jhawar-2002"
                />
              </Fade>
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Video Production</strong> Team
              </span>
            </h2>
            <div className="teamList">
              <Fade up>
                <Image
                  path="Anmol-Singh.png"
                  name="Anmol Singh"
                  contact="8698682498"
                  quote="I like to record THINGS"
                  instagram="anmol.ogg"
                  linkedin="https://www.linkedin.com/in/anmol-singh-baghel-a608431ba"
                />
                <Image
                  path="Saket-Kumar-Gautam.png"
                  name="Saket Kumar Gautam"
                  contact="7903531361"
                  quote="If you like basketball then I like you 💓💓"
                  instagram="gautam.saket_ts_18"
                  linkedin="https://www.linkedin.com/in/saket-kumar-gautam-175a8a203"
                />
              </Fade>
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Graphic Design</strong> Team
              </span>
            </h2>
            <div className="teamList">
              <Fade up>
                <Image
                  path="Neeraj-Prasad.png"
                  name="Neeraj Prasad"
                  contact="8319401611"
                  quote="Music is my language."
                  instagram="neeraj._.20"
                  linkedin="https://www.linkedin.com/in/neeraj-prasad-86a89b202"
                />
                <Image
                  path="Parmeet-Singh.png"
                  name="Parmeet Singh"
                  contact="9755859848"
                  quote="Bringing the art to the cart."
                  instagram="mr_sunny2311"
                  linkedin="https://www.linkedin.com/in/parmeet-singh-3877501b8"
                />
                <Image
                  path="Shivam-Kashyap.png"
                  name="Shivam Kashyap"
                  contact="7587114412"
                  quote="No, I Don't Tweet, I Quack! "
                  instagram="ishivam._.7"
                  linkedin=""
                />
              </Fade>
            </div>
          </Fade>
        </div>
      </React.Fragment>
    );
  }
}

export default Team;
