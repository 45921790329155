import React, { useState, useEffect } from "react";
import "./BlogListComponent.css";
import PageCover from "../../HeaderComponents/PageCover";
import SingleBlog from "./SingleBlogComponent/SIngleBlogComponent";
import { Fade } from "react-reveal";
import ComingSoon from "../../ComingSoonComponent/ComingSoonComponent";
import { Helmet } from "react-helmet";
import { db } from "../../../firebase";
import Moment from "moment";

const blogList = () => {
  const [blogs, setblogs] = useState([]);

  useEffect(() => {
    const fetchdata = async () => {
      db.collection("Blogs").onSnapshot(function (data) {
        setblogs(
          data.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        );
      });
    };
    fetchdata();
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>TEDxBITD - Submit Blog</title>
        <meta
          name="description"
          content="Bring out your words your way. Whether you want your knowledge to be shared or your experience, TEDxBITD provides you a platform to publish your work, the way you like it."
        />
        <meta
          name="keywords"
          content="ted,tedx,bit,durg,speakers,bitd,event,campus,college,bhilai"
        />
      </Helmet>
      {/* <ComingSoon title="Let’s Do Some<br />Mind-<strong>blog</strong>-ling!" image="blogs.png" button="Submit your own Blog" link="guidelines">
            The essence of any <strong>TEDx event</strong> lies in its mission: <strong>'IDEAS WORTH SPREADING'</strong>. We are surrounded by intriguing, compelling, fascinating, and engaging ideas everywhere. The human brain itself is the source of hundreds of ideas! So why keep them to ourselves? Let's share it, and spread it! Bring a change, upskill others!
            <br /><br />
            The blogs section here will be the home o ideas from all walks of life, every worthwhile experience, advice, and learnings can be shared here.
            <br /><br />
            We invite you to write blogs of your choice, for the first-ever <strong>TEDx event in BIT Durg</strong>, aligning with the ultimate goal of promoting worthwhile ideas! Making this youth culture event as creative, imaginative, and informative as possible.
        </ComingSoon> */}
      <PageCover title="Blogs" />
      <div>
        <div className="submitBtn">
          <a href="/guidelines">
            <button type="button">Submit</button>
          </a>
        </div>

        <div className="blogListCont">
          {blogs.map((Blog) => {
            return (
              <Fade up>
                <SingleBlog
                  to={`/singleblog/${Blog.id}`}
                  image={
                    "https://drive.google.com/uc?export=view&id=" +
                    Blog.blogimageurl
                  }
                  date={Moment(Blog.blogdate).format("ll")}
                  heading={Blog.blogtitle}
                  author={Blog.blogauthor}
                />
              </Fade>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default blogList;
