import React, { Component } from "react";
import "./SpeakerModal.css";

class SpeakerModalComponent extends Component {
  state = {
    showModal: true,
    modalClass: "modalCont show",
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      modalClass: "modalCont",
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className={this.state.modalClass}>
          <div className="speakerModalContent">
            <div
              className="speakerModalBody"
              style={{ backgroundImage: "url('" + this.props.image + "')" }}
            >
              <div className="header">
                <img src={this.props.image} alt={this.props.image} />
                <div className="details">
                  <h4 className="name">{this.props.name}</h4>
                  <h6 className="post">{this.props.post}</h6>
                </div>
              </div>
              <p>{this.props.about}</p>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    this.closeModal();
                    this.props.close();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SpeakerModalComponent;
