import React, { useState } from "react";
import "./SubmitBlogCOmponent.css";
import PageCover from "../../HeaderComponents/PageCover";
import { db, storage } from "../../../firebase";
import AlertModal from "../../AlertModalComponent/AlertModalComponent";
import { Progress } from "reactstrap";
import { Fade } from "react-reveal";

const submitBlog = () => {
  const [fullname, setFullname] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [blogtitle, setBlogtitle] = useState("");
  const [social, setSocial] = useState("");
  const [about, setAbout] = useState("");

  const [file, setFile] = useState(null);
  const [url, setURL] = useState("");
  const [loading, setLoading] = useState(null);
  const [modal, showModal] = useState("");

  const types = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/doc",
    "application/msword",
  ];

  function handleChange(e) {
    const fileUpload = e.target.files[0];
    if (fileUpload && types.includes(fileUpload.type)) {
      setFile(e.target.files[0]);
    } else {
      setFile(null);
      showModal(
        <AlertModal
          message="Error in uploading file! Make sure you upload a file with '.doc', '.docx' or '.pdf' format only."
          icon="failed.png"
          close={closeModal}
        />
      );
    }
  }

  const closeModal = () => {
    showModal("");
  };

  // Add a new document in collection "blogs" with Random ID
  const submitBlog = async (e) => {
    if ((fullname && mobileNo && email) != "") {
      e.preventDefault();
      setLoading(
        <div className="inputGroup progressBar">
          <Progress animated color="info" value={100}>
            Uploading...
          </Progress>
        </div>
      );
      await storage.ref(`/blogs/${file.name}`).put(file);

      storage
        .ref("blogs")
        .child(file.name)
        .getDownloadURL()
        .then((url) => {
          db.collection("blogs")
            .add({
              url: url,
              fullname: fullname,
              mobileNo: mobileNo,
              email: email,
              blogtitle: blogtitle,
              social: social,
              about: about,
              filename: file.name,
              createdAt: new Date(),
            })
            .then(() => {
              setURL("");
              setLoading(null);
              showModal(
                <AlertModal
                  message="Your blog has been submitted successfully!"
                  icon="successful.png"
                  reload="true"
                  close={closeModal}
                />
              );
            });
        });
    } else {
      showModal(
        <AlertModal
          message="Failed to submit blog! Please try again."
          icon="failed.png"
          reload="true"
          close={closeModal}
        />
      );
    }
  };

  return (
    <React.Fragment>
      <PageCover title="Submit Blog" />
      {modal}
      <div className="submitCont">
        <Fade up>
          <div className="instructions">
                        <Fade up>
                            <h3>Read the Instructions before submitting:</h3>
                            <ol>
                                <li>Fill in all the details correctly.</li>
                                <li>Wait till you see the confirmation message.</li>
                                <li>Accepted file formats- '.doc', '.docx', '.pdf'.</li>
                                <li>Read the complete guidelines <a href="/guidelines">here</a>.</li>
                            </ol>
                        </Fade>
                    </div>
          <h2 className="lineTitle">
            <span>
              <strong>Personal</strong> Information
            </span>
          </h2>
          <form>
            <div className="formRow twoInputs">
              <div className="inputGroup">
                <label htmlFor="name">Full Name:*</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Full Name*"
                  onChange={(event) => setFullname(event.target.value)}
                  required
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="mobileno">Mobile No.:*</label>
                <input
                  type="text"
                  name="mobileno"
                  id="mobileno"
                  placeholder="Mobile No.*"
                  onChange={(event) => setMobileNo(event.target.value)}
                  required
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="emailid">Email-ID:*</label>
                <input
                  type="email"
                  name="emailid"
                  id="emailid"
                  placeholder="Email-ID*"
                  onChange={(event) => setEmail(event.target.value)}
                  required
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="title">Blog Title:*</label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Blog Title*"
                  onChange={(event) => setBlogtitle(event.target.value)}
                  required
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="social">Instagram/Linkedin Profile:</label>
                <input
                  type="text"
                  name="social"
                  id="social"
                  placeholder="Social Media Link"
                  onChange={(event) => setSocial(event.target.value)}
                  required
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="upload">
                  Upload your Blog(Word File only):*
                </label>
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  placeholder="Upload your Blog"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            {loading}
            <div className="formRow singleInput">
              <div className="inputGroup">
                <label htmlFor="about">About the author:</label>
                <textarea
                  name="about"
                  id="about"
                  rows="5"
                  placeholder="About the author"
                  onChange={(event) => setAbout(event.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="formRow singleInput">
              <div className="inputGroup">
                <button onClick={submitBlog} disabled={!file}>
                  SUBMIT BLOG
                </button>
              </div>
            </div>
          </form>
        </Fade>
      </div>
    </React.Fragment>
  );
};

export default submitBlog;
