import React, { useState, useEffect } from "react";
import FAQ from "./FAQ";
import { useWindowSize } from "react-use";
import "./HomeComponent.css";
import Timer from "./TimerComponent";
import { Fade } from "react-reveal";
import Tilt from "react-tilt";
import { Helmet } from "react-helmet";
import SingleVideo from "../EventHighlightsComponent/SingleVideoComponent/SingleVideoComponent";
import ReactConfetti from "react-confetti";
import Fraz from "../../img/speaker2023/fraz.png";
import Ayushi from "../../img/speaker2023/ayushi1.png";
import Tapesh from "../../img/speaker2023/tapesh.png";

const Home = () => {
  const [faqs, setfaqs] = useState([
    {
      question: "Who are the speakers?",
      answer:
        "TEDxBITD is working towards having numerous inspiring and stimulating ideas on the main stage. To have a brief idea about the speakers, <a href='https://www.tedxbitd.com/speakers' target='_blank' rel='noopener noreferrer'>visit here</a> and you can also stay connected with us on our social media handle.,",
      open: true,
    },
    {
      question: "Who can attend TEDxBITD?",
      answer:
        "<strong>TEDxBITD</strong> is an independently organized event under the TEDx banner. Anyone who wishes to be a part of the first event can register and attend the event. <a href='/contact'>Contact us</a> for more information.",
      open: false,
    },
    {
      question: "Where is the event venue?",
      answer:
        "After the successful completion of the first <strong>TEDxBITD</strong> event via online mode this year it's going to be a more fun and sublime experience as the events are going to be held in <strong>OFFLINE</strong> mode. For more details <a href='/contact'>contact us</a>.",
      open: false,
    },
    {
      question: "How long will the event be?",
      answer:
        "The timings and schedule will be announced soon. Follow us <a href='https://www.instagram.com/tedxbitd/' target='_blank' rel='noopener noreferrer'>here</a> to get notified with our every update.",
      open: false,
    },
    {
      question:
        "When will the registrations start/end? Can tickets be bought at the door?",
      answer:
        "The registrations are started. <a href='/book' target='_blank' rel='noopener noreferrer'>Visit Here</a> and book your tickets.",
      open: false,
    },
    // {
    //   question:
    //     "When will the registrations start/end? Can tickets be bought at the door?",
    //   answer:
    //     "The registrations will start soon. Stay connected with us on our social media for recent updates. <a href='/contact'>Contact us</a> for more information.",
    //   open: false,
    // },
  ]);

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  const startDate = new Date("June 06, 2021 13:00:00").getTime();

  const ctaBtn = () => {
    const heading = document.querySelector(".mainHeading");
    const btn = document.querySelector(".knowMoreBtn");
    const button = btn.querySelector("button");

    window.addEventListener("scroll", () => {
      if (window.scrollY >= heading.offsetTop) {
        btn.classList.add("small");
        button.innerHTML = "<i class='far fa-question-circle'></i>";
      } else {
        btn.classList.remove("small");
        button.innerHTML = "Know More";
      }
      if (window.scrollY >= heading.offsetTop) {
        btn.classList.add("small");
        button.innerHTML = "<i class='fas fa-ticket-alt'></i>";
      } else {
        btn.classList.remove("small");
        button.innerHTML = "Know More";
      }
    });
  };

  useEffect(() => {
    ctaBtn();
  }, []);

  const [windowDimension, setDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [showConfetti, setShowConfetti] = useState(true);
  const [confettiCount, setconfettiCount] = useState(400);
  const { width, height } = useWindowSize();

  useEffect(() => {
    setTimeout(() => {
      setconfettiCount(10);
    }, 2500);
    setTimeout(() => {
      setShowConfetti(false);
    }, 4000); //4000ms => 4s
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>TEDxBITD - Home</title>
        <meta
          name="description"
          content="TEDx is here at BIT Durg, for the very first time! TEDxBITD in affiliation with the spirit of TED ideas worth spreading - is delighted to share a part of the ethos of TEDx with our community."
        />
      </Helmet>
      <div></div>
      {showConfetti && (
        <ReactConfetti
          numberOfPieces={confettiCount}
          tweenDuration={1000}
          width={width > 768 ? width - 50 : width}
        />
      )}
      <div className="homeCover">
        <Fade up>
          <img
            src="./assets/images/logos/logo-white-small.png"
            alt="Home Page TEDxBITD Logo"
          />
          <h1 className="mainHeading">Pivot - The Turning Point</h1>
          <a href="/aboutus" className="knowMoreBtn">
            <button type="button">Know More</button>
          </a>
          <a href="/aboutus" className="knowMoreBtn">
            <button type="button">Know More</button>
          </a>
          <a href="/aboutus" className="knowMoreBtn">
            <button type="button">Know More</button>
          </a>
        </Fade>
      </div>

      {/* <a href="https://online.flippingbook.com/view/605837323/" class="fbo-embed" data-fbo-id="605837323" data-fbo-lightbox="yes" data-fbo-width="250px" data-fbo-height="188px" data-fbo-version="1" style={{maxWidth: "100%"}}>SEO</a> */}

      <Fade up>
        <div className="infoSection">
          <div className="singleInfo">
            <div className="infoQues">WHEN</div>
            {/* <div className="infoAnswer">19 AUGUST 2023</div> */}
            <div className="infoAnswer">TBA</div>
          </div>
          <div className="singleInfo">
            <div className="infoQues">WHERE</div>
            {/* <div className="infoAnswer">BITD AUDITORIUM</div> */}
            <div className="infoAnswer">TBA</div>
          </div>
          <div className="singleInfo">
            <div className="infoQues">TIME</div>
            {/* <div className="infoAnswer">10:00am - 5:00pm</div> */}
            <div className="infoAnswer">TBA</div>
          </div>
        </div>
        <div className="parallaxBg">
          <div className="content">
            <h2 className="heading">
              “Things don’t work out according to plan. It’s okay to not have
              one, I didn’t have one, but I had a passion and I decided to
              pursue that.”
            </h2>
          </div>
          <div className="authorImage">
            <img
              src="./assets/images/parallax/mithila-palkar-bg1.png"
              alt="img"
            />
          </div>
        </div>

        <div>
          <div className="heading">
            <h2>Speakers</h2>
          </div>

          <div className="speakersSection">
            <Tilt className="Tilt" options={{ max: 25 }}>
              <img src={Fraz} alt="Speaker 1" />
            </Tilt>
            <Tilt className="Tilt" options={{ max: 25 }}>
              <img src={Ayushi} alt="Speaker 2" />
            </Tilt>
            <Tilt className="Tilt" options={{ max: 25 }}>
              <img src={Tapesh} alt="Speaker 3" />
            </Tilt>
          </div>

          <div className="ctaBtn">
            <a href="/speakers">
              <button type="button">SEE ALL SPEAKERS</button>
            </a>
          </div>
        </div>

        {/* <div className="heading">
          <hr />
          <h2>Blog - Posts, Entries, or Articles</h2>
        </div>

        <div className="homeContent">
          <p>
            <strong>TEDxBITD</strong> invites you to guest post on our website
            to share your experience with our audience. We don’t believe in
            narrowing down our readers’ interests to certain niches. We invite
            writers and bloggers to guest post with us on whatever topic they
            feel comfortable in.
            <br />
            <br />
            We are here to give you a stage to tell your own story and connect
            with our readers in your style. In return, we intend to provide you
            a platform to establish your very own authority and relations with
            others in your field of expertise.
            <br />
            <br />
            We expect you to provide genuine, helpful, and relevant content to
            bring about some <strong>ideas worth spreading</strong>. We hope to
            see you soon! Till then, keep writing and keep inspiring!
          </p>
        </div>

        <div className="ctaBtn">
          <a href="/guidelines">
            <button type="button">SUBMIT BLOG</button>
          </a>
        </div> */}
        {/* <Timer startDate={startDate} />

        <div className="ctaBtn">
          <a href="/schedule">
            <button type="button">SCHEDULE</button>
          </a>
        </div> */}
        <div className="heading">
          <hr />
          <h2>2022 Event Highlights</h2>
        </div>
        <div className="videosList">
          <Fade up>
            <SingleVideo
              video="https://www.youtube.com/embed/rPwtZMyzktM"
              speaker="Sanjay Jain"
              title="Can Intelligence and Success be Correlated?"
            />
          </Fade>
          <Fade up>
            <SingleVideo
              video="https://www.youtube.com/embed/D61cz3FO9i8"
              speaker="Dr. Diksha Tiwari"
              title="How belief system is the invisible force behind your outcomes?"
            />
          </Fade>
          <Fade up>
            <SingleVideo
              video="https://www.youtube.com/embed/tckRiOixyW0"
              speaker="Anuj Kumar Sharma"
              title="How understanding Emotional Intelligence can improve our lives?"
            />
          </Fade>
        </div>
        <div className="parallaxBg">
          <div className="content">
            <h2 className="heading">
              "You may use your power to build walls… or you may use it to break
              barriers."
            </h2>
          </div>
          <div className="authorImage">
            <img
              src="./assets/images/parallax/shah-rukh-khan-bg1.png"
              alt="img"
            />
          </div>
        </div>
        <div className="heading">
          <h2>Frequently Asked Questions</h2>
        </div>
        <section className="faq_part">
          <div className="container-fluid">
            <div className="faqs">
              {faqs.map((faq, i) => (
                <FAQ faq={faq} key={i} index={i} toggleFAQ={toggleFAQ} />
              ))}
            </div>
          </div>
        </section>
      </Fade>
    </React.Fragment>
  );
};

export default Home;
