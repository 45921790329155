import React from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { Link } from "react-router-dom";

function DashBoardButton() {
  const { currentUser } = useAuth();

  return (
    <React.Fragment>
      {currentUser ? (
        <Link to="/dashboard">
          DASHBOARD
        </Link>
      ) : null}
    </React.Fragment>
  );
}

export default DashBoardButton;
