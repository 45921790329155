import React, { useState } from "react";
import "./SingleSpeakerComponent.css";
import SpeakerModal from "../SpeakerModal/SpeakerModal";

const SingleSpeaker = (props) => {
  const [modal, showModal] = useState("");

  const closeModal = () => {
    showModal("");
  };

  return (
    <React.Fragment>
      {modal}
      <div
        className="singleSpeaker"
        onClick={() =>
          showModal(
            <SpeakerModal
              name={props.name}
              post={props.post}
              about={props.children}
              image={props.path}
              close={closeModal}
            />
          )
        }
      >
        <img src={props.path} alt={props.name} />
        <div className="content">
          <h4 className="name">{props.name}</h4>
          <h6 className="post">{props.post}</h6>
        </div>
        <div className="speakerHover">
          <div style={{ backgroundImage: "url('" + props.path + "')" }}></div>
          <h4 className="name">{props.name}</h4>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SingleSpeaker;
