import React, { useEffect, useState } from "react";
import "./ContactUsComponent.css";
import PageCover from "../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import emailjs from "emailjs-com";
import AlertModal from "../AlertModalComponent/AlertModalComponent";
import { db } from "../../firebase";
import { Helmet } from "react-helmet";
import { Link, Element } from "react-scroll";

const contentHeight = () => {
  const heights = document.querySelectorAll(".cardContent");
  let max = heights[0].offsetHeight;

  for (let i = 0; i < heights.length; i++) {
    if (heights[i].offsetHeight >= max) max = heights[i].offsetHeight;
  }

  heights.forEach((height) => {
    height.style.height = max + "px";
  });
};

const contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);

  const [modal, showModal] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    db.collection("contactus")
      .add({
        name: name,
        email: email,
        message: message,
        createdAt: new Date(),
      })
      .then(() => {
        setLoader(false);
        showModal(
          <AlertModal
            message="Your Message has been submitted. Thank you for your feedback!"
            icon="successful.png"
            reload="true"
            close={closeModal}
          />
        );
      })
      .catch((error) => {
        showModal(
          <AlertModal
            message={error.message}
            icon="failed.png"
            reload="true"
            close={closeModal}
          />
        );
        setLoader(false);
      });

    emailjs
      .sendForm(
        "service_n6fo218", // Service Id
        "template_u7lxykn", // Template Id
        e.target,
        "user_j147cM4xSAMaZzHRF7Kqm" // User Id
      )
      .then(
        (result) => {
          // console.log(result.text);
          setLoader(false);
        },
        (error) => {
          // console.log(error.text);
          setLoader(false);
        }
      );
    e.target.reset();
  };

  const closeModal = () => {
    showModal("");
  };

  useEffect(() => {
    contentHeight();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>TEDxBITD - Contact Us</title>
        <meta
          name="description"
          content="TEDxBITD has a great support team to answer all your questions related to the event. Feel free to drop by and we will get back to you at the earliest."
        />
        <meta
          name="keywords"
          content="ted,tedx,bit,durg,speakers,bitd,event,campus,college,bhilai"
        />
      </Helmet>
      <PageCover title="Contact Us" />
      {modal}
      <div className="contactCont">
        <div className="cardsCont">
          <div className="cards">
            <Fade up>
              <a href="/speakernominations" className="singleCard">
                <div className="cardIcon">
                  <img
                    src="./assets/images/icons/speakers.png"
                    alt="Speaker Icon"
                  />
                </div>
                <div className="cardHeading">Speaker Nomination </div>
                <div className="cardContent">
                  If your actions inspire others to dream more & learn more, you
                  belong to <b>TEDxBITD stage</b>! Speaker Nominations are now
                  open
                </div>
                <div className="cardCta">Apply Here <i className="fas fa-arrow-right"></i></div>
              </a>
              <a href="/sponsornominations" className="singleCard">
                <div className="cardIcon">
                  <img
                    src="./assets/images/icons/sponsors.png"
                    alt="Speaker Icon"
                  />
                </div>
                <div className="cardHeading">Sponsor Nomination</div>
                  <div className="cardContent">
                   <b>TEDxBITD</b>  is looking for collaborators. Join us in the making
                    of new ideas. Sponsorship nominations are now open!
                  </div>
                <div className="cardCta">Apply Here <i className="fas fa-arrow-right"></i></div>
              </a>
              {/* <Link
                to="/sponsornominations"
                spy={true}
                smooth={true}
                duration={500}
              >
                <div className="singleCard">
                  <div className="cardIcon">
                    <img
                      src="./assets/images/icons/sponsors.png"
                      alt="Speaker Icon"
                    />
                  </div>
                  <div className="cardHeading">Sponsors</div>
                  <div className="cardContent">
                    TEDxBITD is looking for collaborators. Join us in the making
                    of new ideas. Sponsorship nominations are now open!
                  </div>
                  <div className="cardCta">Contact Now</div>
                </div>
              </Link> */}
              {/* <Link to="contactus" spy={true} smooth={true} duration={500}>
                <div className="singleCard">
                  <div className="cardIcon">
                    <img
                      src="./assets/images/icons/enquiry.png"
                      alt="Speaker Icon"
                    />
                  </div>
                  <div className="cardHeading">Enquiry</div>
                  <div className="cardContent">
                    Get in touch and our support team will answer all your
                    questions.
                  </div>
                  <div className="cardCta">Contact Now</div>
                </div>
              </Link> */}
            </Fade>
          </div>
        </div>
        <div className="contactForm">
          <Fade up>
            <div className="leftSide">
              <img
                src="./assets/images/contact-us/banner.png"
                alt="Contact Us Banner"
              />
            </div>
            <Element name="contactus">
              <form action="#" onSubmit={handleSubmit}>
                <Fade up>
                  <div className="heading">We'd love to hear from you</div>
                  <div className="inputGroup">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name*"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="inputGroup">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email*"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="inputGroup">
                    <textarea
                      name="message"
                      id="message"
                      placeholder="Message*"
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <div className="inputGroup">
                    <button type="submit">
                      Submit&nbsp;&nbsp;<i className="fas fa-arrow-right"></i>
                    </button>
                  </div>
                  <div className="socialMedia">
                    <a
                      href="https://instagram.com/tedxbitd"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a
                      href="https://twitter.com/TEDxBITD"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a
                      href="https://facebook.com/TEDxBITD"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook"></i>
                    </a>
                    <a
                      href="https://linkedin.com/company/tedxbitd"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </div>
                </Fade>
              </form>
            </Element>
          </Fade>
        </div>
      </div>
    </React.Fragment>
  );
};

export default contact;
