import React from "react";

const singleBlog = (props) => {
  return (
    <React.Fragment>
      <a href={props.to} className="singleBlog">
        <img src={props.image} className="blogImage" alt={props.heading} />
        <div className="blogContent">
          <div className="blogDate">
            <i className="far fa-calendar-alt"></i>&nbsp;&nbsp;
            {props.date}
          </div>
          <h3 className="blogHeading">{props.heading}</h3>
          <h5 className="blogAuthor">by {props.author}</h5>
          <p className="blogDesc">{props.children}</p>
        </div>
      </a>
    </React.Fragment>
  );
};

export default singleBlog;
