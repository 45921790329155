import React, { Component } from "react";
import "./HeaderComponent.css";
import "./NavbarComponent.css";
import { Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";
import logoBlack from "../../../img/logos/logo-black.png";
import DashBoardButton from "./DashboardButton";

class Header extends Component {
  state = {
    navItemClasses: "navItems",
    lineWidth: "normal",
    open: false,
  };

  toggleNav = () => {
    const status = !this.state.open;

    if (status)
      this.setState({
        navItemClasses: "navItems show",
        lineWidth: "reverse",
        open: true,
      });
    else
      this.setState({
        navItemClasses: "navItems",
        lineWidth: "normal",
        open: false,
      });
  };

  render() {
    return (
      <React.Fragment>
        <nav className="tedxNavbar">
          <div className="navbarTop">
            <div className="hamburger" onClick={(e) => this.toggleNav(e)}>
              <div className={"line line1 " + this.state.lineWidth}></div>
              <div className={"line line2 " + this.state.lineWidth}></div>
              <div className={"line line3 " + this.state.lineWidth}></div>
            </div>
            <Link to="/">
              <img
                src={logoBlack}
                className="mobileNavLogo"
                alt="TEDxBITD Logo"
              />
            </Link>
            <div className={this.state.navItemClasses}>
              <Link to="/">
                <img src={logoBlack} alt="TEDxBITD Logo" />
              </Link>
              <div className="navLinks">
                {/* <Link to="/register" onClick={(e) => this.toggleNav(e)}>
                  REGISTER
                </Link> */}
                {/* <Link to="/guidelines" onClick={(e) => this.toggleNav(e)}>
                  REGISTER
                </Link> */}
                <DashBoardButton onClick={(e) => this.toggleNav(e)} />
                {/* <div className="hasDropdown">
                  PAGES
                  <div className="dropdownMenu"></div>
                </div> */}
                <Link to="/" onClick={(e) => this.toggleNav(e)}>
                  HOME
                </Link>
                <Link to="/aboutus" onClick={(e) => this.toggleNav(e)}>
                  ABOUT US
                </Link>
                <Link to="/team" onClick={(e) => this.toggleNav(e)}>
                  TEAM
                </Link>
                {/* <Link to="/book" onClick={(e) => this.toggleNav(e)}>
                  BOOK TICKETS
                </Link> */}
                <Link to="/moodboard" onClick={(e) => this.toggleNav(e)}>
                  MOOD-BOARDS
                </Link>
                <Link to="/speakers" onClick={(e) => this.toggleNav(e)}>
                  SPEAKERS
                </Link>
                {/* <Link to="/highlights" onClick={(e) => this.toggleNav(e)}>
                  HIGHLIGHTS
                </Link> */}
                {/* <Link to="/blog" onClick={(e) => this.toggleNav(e)}>
                    BLOGS
                  </Link> */}

                {/* <Link to="/schedule" onClick={(e) => this.toggleNav(e)}>
                  SCHEDULE
                </Link> */}

                {/* <Link to="/merchandise" onClick={(e) => this.toggleNav(e)}>
                  MERCHANDISE
                </Link> */}
                {/* <Link to="/speakernominations" onClick={(e) => this.toggleNav(e)}>
                  SPEAKER NOMINATIONS
                </Link> */}
                {/* <Link to="/sponsors" onClick={(e) => this.toggleNav(e)}>
                  SPONSORS
                </Link> */}
                {/* <Link to="/interact" onClick={(e) => this.toggleNav(e)}>
                  INTERACT
                </Link> */}
                <Link to="/contact" onClick={(e) => this.toggleNav(e)}>
                  CONTACT US
                </Link>

                <LogoutButton />
              </div>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default Header;
