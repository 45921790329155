import React from "react";
import "./SpeakersComponent.css";
import PageCover from "../HeaderComponents/PageCover";
import SingleSpeaker from "./SingleSpeakerComponent/SingleSpeakerComponent";
import { Fade } from "react-reveal";
import ComingSoon from "../ComingSoonComponent/ComingSoonComponent";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { db, storage } from "../../firebase";
import { Progress } from "reactstrap";
import AlertModal from "../AlertModalComponent/AlertModalComponent";

const speakers = () => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [occupation, setOccupation] = useState("");
  const [about, setAbout] = useState("");

  const [loading, setLoading] = useState(null);
  const [file, setFile] = useState(null);
  const [url, setURL] = useState("");

  const [modal, showModal] = useState("");

  const types = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/doc",
    "application/msword",
  ];

  function handleChange(e) {
    const fileUpload = e.target.files[0];
    if (fileUpload && types.includes(fileUpload.type)) {
      setFile(e.target.files[0]);
    } else {
      setFile(null);
    }
  }

  const onSubmit = async (event) => {
    if ((fullname && contactNo && email && occupation) !== "") {
      event.preventDefault();

      const application = {
        // url: url,
        // filename : file.name,
        fullname: fullname,
        contactNo: contactNo,
        email: email,
        contactNo: contactNo,
        occupation: occupation,
        about: about,
        date: new Date(),
      };

      // If submittion successful without file
      if (!file) {
        db.collection("Speakers")
          .add(application)
          .then(() => {
            setURL(""),
              setFile(null),
              setFullname(""),
              setContactNo(""),
              setEmail(""),
              setOccupation(""),
              setAbout(""),
              setLoading(null);
            showModal(
              <AlertModal
                message="Speaker has been nominated successfully!"
                icon="successful.png"
                reload="true"
                close={closeModal}
              />,
            );
          });
      }
      // If submittion successful with file
      else {
        setLoading(
          <div className="inputGroup progressBar">
            <Progress animated color="info" value={100}>
              Uploading...
            </Progress>
          </div>,
        );
        await storage.ref(`/speakers/${file.name}`).put(file);

        storage
          .ref("speakers")
          .child(file.name)
          .getDownloadURL()
          .then((url) => {
            db.collection("Speakers")
              .add(
                application,
                (application.url = url),
                (application.filename = file.name),
              )
              .then(() => {
                setURL(""),
                  setFile(null),
                  setFullname(""),
                  setContactNo(""),
                  setEmail(""),
                  setOccupation(""),
                  setAbout(""),
                  setLoading(null);
                showModal(
                  <AlertModal
                    message="Speaker has been nominated successfully!"
                    icon="successful.png"
                    reload="true"
                    close={closeModal}
                  />,
                );
              });
          });
      }
    } else {
      // If form submission failled
      showModal(
        <AlertModal
          message="Speaker could not be nominated! Please try again."
          icon="failed.png"
          close={closeModal}
        />,
      );
    }
  };

  const closeModal = () => {
    showModal("");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>TEDxBITD - Speakers 2022</title>
        <meta
          name="description"
          content="Our speakers are the inspiration centers of TEDxBITD. We aim to bring speakers with inspirational stories and life-changing ideas worth spreading, to encourage great and meaningful conversations."
        />
        <meta
          name="keywords"
          content="ted,tedx,bit,durg,speakers,bitd,event,campus,college,bhilai"
        />
      </Helmet>
      {/* <ComingSoon
        title="Speakers? Be A Little More Patient!"
        image="speakers.png"
        button="Go Back"
        link=""
      >
        <strong>TEDxBITD</strong> guarantees to manifest amazing minds, gripping
        ideas, grueling struggles, awe-inspiring victories from a very wide
        variety of TED speakers! This is the soul of TED - to acknowledge
        accomplishments and the journeys behind them - soak experiences and
        learn from them!
        <br />
        <br />
        We are pleased to invite passionate speakers from all walks of life to
        come and present to our audience - the experiences that will stay with
        them as a positive drive for their entire life and help them unleash
        their greatest potentials. The first of its kind in{" "}
        <strong>BIT Durg - TEDxBITD</strong> ensures the making of the greatest
        memories with TED speakers, new and experienced, both.
      </ComingSoon> */}
      <div>
        <PageCover title="Speakers 2022" />

        <div className="speakersList">
          <SingleSpeaker
            path="./assets/images/speakers-22/tilak-tanwar.jpg"
            name="Tilak Tanwar"
            linkedin=""
            post="Author, Guinness World Record Holder"
          >
            Tilak is a Bestselling Author, having authored the book called "Feed
            your brain, not your stomach!". He also holds a Guinness World
            Record for delivering an astonishing 28 speeches continuously in 7
            different languages in 7 different cities! With his wisdom and
            expertise, Tilak also has been a valuable coach to aspiring authors
            and a guiding light to many of his mentees.
          </SingleSpeaker>
          <SingleSpeaker
            path="./assets/images/speakers-22/sanjay-jain.jpg"
            name="Sanjay Jain"
            linkedin=""
            post="Life Coach and Motivational Speaker"
          >
            Sanjay is a Life Coach and Author, having authored multiple books
            and conducted various workshops. He has a vast amount of rich
            experience in helping people realise their dreams. He has been
            interviewed in the NewsX India A-List Series by the Assistant Editor
            and also been awarded at The Most Admired Global Indian 2019 Awards.
          </SingleSpeaker>
          <SingleSpeaker
            path="./assets/images/speakers-22/pranjal-kamra.jpg"
            name="Pranjal Kamra"
            linkedin=""
            post="Co-Founder @ Finology Ventures, YouTuber"
          >
            Pranjal is the Co-founder of Finology Ventures - the first and only
            SEBI registered investment consultancy firm in Chhattisgarh.
            Popularly known as an "Investment Guru", he has garnered a
            subscriber count of 4M+ on YouTube and posts investment and stock
            market related content.
          </SingleSpeaker>
          <SingleSpeaker
            path="./assets/images/speakers-22/akshay-bachhawat.jpeg"
            name="Akshay Bachhawat"
            linkedin=""
            post="CA, Founder @ Vichiti"
          >
            A Chartered Accountant by profession, Akshay has founded Vichiti -
            an initiative which aids working professionals from diverse domains
            to meet up and enhance their connections. He is also the Vice
            President of Play and Shine Foundation, an Expert Member of the
            World Innovation Alliance and is associated with LinkedIn's Creator
            Accelerator Program India.
          </SingleSpeaker>
          <SingleSpeaker
            path="./assets/images/speakers-22/anuj-kumar-sharma.jpg"
            name="Anuj Kumar Sharma"
            linkedin=""
            post="YouTuber, Software Engineer (Ex-Amazon)"
          >
            Anuj is a Software Engineer and an Ed-Tech YouTuber with 295K+
            subscribers. Popularly known as 'Anuj Bhaiya' among his students, he
            is also an ex-student of the illustrious Super-30 Batch. He has
            worked for Amazon and Urban Company in the past and continues to
            educate and guide countless number of students towards a successful
            career.
          </SingleSpeaker>
          <SingleSpeaker
            path="./assets/images/speakers-22/diksha-tiwari.jpg"
            name="Diksha Tiwari"
            linkedin="https://linkedin.com/in/ies-jaspal-singh/"
            post="Doctor, Founder @ Arpan"
          >
            A Doctor by profession, Diksha is a huge fan of history and an avid
            social worker. She runs an NGO named 'Arpan' that works towards
            connecting women across the rural areas of Chhattisgarh for personal
            and professional growth. Plus, she runs a page dedicated to the
            history of chhattisgarh which serves as a virtual museum.
          </SingleSpeaker>
          <SingleSpeaker
            path="./assets/images/speakers-22/c-s-robinson.jpg"
            name="C.S. Robinson"
            linkedin=""
            post="Associate Professor, Dept. of Applied Physics"
          >
            An Associate Professor in the Department of Applied Physics, Mr.
            Robinson has accomplished the feat of being the first ever faculty
            member from BIT Durg to be delivering a TED talk. He is passionate
            about astrophysics, has been teaching for 25+ years and has a
            reservoir of knowledge to share.
          </SingleSpeaker>
        </div>
        {/* <div className="speakersList">
          <SingleSpeaker
            path="./assets/images/speakers/aman-dhattarwal.jpg"
            name="Aman Dhattarwal"
            linkedin=""
            post="Founder of Apni Kaksha | Educator | YouTuber"
          >
            The man behind the slogan "TU PHODHEGA", Aman Dhattarwal is an
            inspirational Youth Leader and Educator. Besides academic
            excellence, he is also a National level Lawn Tennis player.
            <br />
            <br />
            He is the founder of Hustlers Bay and 6 YouTube channels with 4.5
            million hustlers.
            <br />
            <br />
            One of the youngest millionaires in India, Aman Dhattarwal completed
            his BE from NSIT, New Delhi, and is now working as an online JEE-
            Physics tutor at Unacademy.
            <br />
            <br />
            His motivation towards encouraging young minds to find their true
            potential is inimitable.
          </SingleSpeaker>
          <SingleSpeaker
            path="./assets/images/speakers/d-d-goel.jpeg"
            name="Lt. Col. D D Goel"
            linkedin=""
            post="Indian Army"
          >
            Lt. Col. D. D. Goel is a major in Psychology, a PG in Social
            Psychology, a Masters in Personnel Mgmt, and a B. Ed. with the
            experience of working as a lecturer, before joining the Indian Army.
            <br />
            <br />
            Served in the Indian Army for more than a decade - a casualty of an
            IED blast - he molded himself to enjoy swimming, scuba diving, river
            rafting, and going on his bullet expeditions today.
            <br />
            <br />
            The only paraglider (solo) in the continent on an artificial limb.
            He featured in the Limca Book of Records 2020 as the first Indian to
            paraglide on an artificial leg.
            <br />
            <br />
            Authored the motivational book titled “When People Doubted My
            Ability to Walk, I Decided to Fly" which was released by the CDS
            General Bipin Rawat.
          </SingleSpeaker>
          <SingleSpeaker
            path="./assets/images/speakers/suhas-misra.jpg"
            name="Suhas Misra"
            linkedin=""
            post="CEO Misters, Co-Founder Hector Beverages(Paper Boat)"
          >
            The CEO of Misters - Suhas Misra began his journey towards academic
            excellence from BIT Durg, later he completed his MBA degree from IIM
            Calcutta.
            <br />
            <br />
            He started his career by serving as Regional Sales Training Manager
            at Nokia and ASM at Coca-Cola.
            <br />
            <br />
            His exceptional leadership skills and creativity led him to the
            position of the founder and director of Hector Beverages(Paper Boat)
            and co-founder of Tardigrade Health Tech, and ChannelPlay.
            <br />
            <br />
            At Misters, he aims to create an avenue for men to address -
            products, therapy, exercise, or just a conversation about their most
            existential insecurities.
          </SingleSpeaker>
          <SingleSpeaker
            path="./assets/images/speakers/angela-kumar.jpg"
            name="Angela Kumar"
            linkedin=""
            post="Miss India Chhattisgarh 2020"
          >
            Crowned VLCC Femina Miss India Chhattisgarh 2020 and VLCC Holistic
            Health Winner - who began her journey at a Talent and Personality
            Platform - Everyuth Times Fresh Face Season 12 Gujarat Winner.
            <br />
            <br />
            As a BBA student at the Maharaja Sayajirao University of Baroda,
            Angela found her passion in dancing and sports, nationally
            representing her state in the latter, while being awarded to excel
            in academics simultaneously.
            <br />
            <br />
            An animal welfare and environmental activist, she extended a helping
            hand to several NGOs from her school days.
            <br />
            <br />
            She is a professional model and a Grooming and Personality
            Development mentor at Auraa
          </SingleSpeaker>
          <SingleSpeaker
            path="./assets/images/speakers/deeksha-sharma.jpg"
            name="Dr. Deeksha Sharma"
            linkedin=""
            post="Postdoc Cornell University Emotional Intelligence and Mental Wellbeing Researcher"
          >
            A postdoctoral fellow at Cornell University USA. With a Ph.D.
            research based on emotional intelligence and its association with
            self-efficacy in real-time.
            <br />
            <br />
            She has been actively promoting emotional well-being through her
            podcasts and a dedicated YouTube channel, aimed at promoting student
            wellbeing. Has also held seminars for World Bank employees.
            <br />
            <br />A passionate recruiter, with expertise in handling top-level
            management positions with known IT product developers.
            <br />
            <br />
            She has authored an upcoming book called "Recycle Emotions". She has
            also been guiding Ph.D. students at Cornell. Also a member of the
            Women's entrepreneurial club at Cornell University.
          </SingleSpeaker>
          <SingleSpeaker
            path="./assets/images/speakers/jaspal-singh.jpg"
            name="Jaspal Singh"
            linkedin="https://linkedin.com/in/ies-jaspal-singh/"
            post="Coach to GATE & ESE Aspirants"
          >
            An outstanding professional for a solid 8 years, currently working
            as an Educator for Unacademy.
            <br />
            <br />A civil engineer who went on to obtain a Ph.D. in this field.
            He qualified for some of India's esteemed examinations in different
            fields with flying colours.
            <br />
            <br />
            Envisioned and made quality education accessible to everyone through
            free online sessions for GATE & ESE aspirants across the country
            since May 2020.
            <br />
            <br />
            Recognized by several National Awards - Indian Achievers Award,
            Faculty of the Year 2021 Award by the Centre of Education, etc.
          </SingleSpeaker>
          <SingleSpeaker
            path="./assets/images/speakers/deepak-paramanand.jpg"
            name="Deepak Paramanand"
            linkedin=""
            post="Product Lead- Hitachi Europe"
          >
            An established Al Product Lead with 20+ years of experience in
            designing, developing, and deploying artificial intelligence
            alongside various technical and business roles.
            <br />
            <br />
            Led a global team of AI Engineers, Android engineers & Designers to
            ship the 'puppets' Al product while working for Microsoft, UK.
            <br />
            <br />
            He has been honored as a panelist at a Product Conference on Al at
            the London School of Business, UK.
            <br />
            <br />
            He is currently serving as a mentor and an inspiration to
            next-generation Product managers at esteemed places, such as Plato,
            We and Al, and Everwise.
          </SingleSpeaker>
          <SingleSpeaker
            path="./assets/images/speakers/shrawan-kumar-yadav.jpg"
            name="Shrawan Kumar Yadav"
            linkedin=""
            post="NLP and Emotional Wellbeing Coach"
          >
            Founder of the "Connecting Youth on Wheels" campaign, which aims to
            educate Indian youth about emotional well-being.
            <br />
            <br />
            Co-founder of 'Cohyem', where a team of young professionals from
            diverse backgrounds works for people development.
            <br />
            <br />
            He went on a solo motorcycle ride, covering 25000km, and held
            seminars on the issues of mental well-being in around 40 cities in
            India and Bhutan.
            <br />
            <br />A MBA in Sales and Marketing and is an accredited NLP
            (Neuro-linguistic programming) mentor, Shrawan has been encouraging
            emotional well-being among the Indian youth for a long time.
          </SingleSpeaker>
          <SingleSpeaker
            path="./assets/images/speakers/aman-shukla.jpg"
            name="Aman Shukla"
            linkedin=""
            post="Public Speaker & Life Coach"
          >
            A Public Speaking Coach who delivers highly relatable and energetic
            keynote webinars on overcoming public speaking reluctance.
            <br />
            <br />
            The fact that he did all of this at the age of 21 astounds David JP
            Phillips, a speaker who recommends Aman's work. David's Ted Talk on
            Public Speaking has received the most views.
            <br />
            <br />
            Aman was chosen to give a presentation at two International Student
            Leadership Conferences in California. He has hosted more than 75
            webinars on public speaking in the lockdown period.
            <br />
            <br />
            He hosted a convention at Talkatora Stadium - the largest indoor
            stadium in Delhi, with an audience of 3500. He has also spoken at
            several prestigious institutes around the country.
          </SingleSpeaker>
          <SingleSpeaker
            path="./assets/images/speakers/rupesh-mahore.jpg"
            name="Rupesh Mahore"
            linkedin=""
            post="Physics Researcher | Science Communicator Performing Artist"
          >
            A physics researcher currently working as an intern at Harvard
            University. He has worked under the guidance of Padma Shri Prof.
            H.C. Verma at IIT Kanpur.
            <br />
            <br />
            Worked with the Indian Space Research Organization (ISRO)
            contributing to the Chandrayaan-2 Project. He recently interviewed
            the Co-founder of SpaceX - Jim Cantrell and astronaut Rakesh Sharma.
            <br />
            <br />
            He is the Co-founder of Spacegrade - a space-tech startup - working
            with ML & Robotics projects on Human Spaceflight Research (pre, post
            & during flight training) concerning the upcoming Gaganyaan Mission.
            <br />
            <br />
            He has also built a Hexafunctional Autonomous Robotic Cart (HARC)
            for COVID Ward delivered to more than 20 hospitals. Currently
            working on a pulse width modulated oxygen conservation device which
            could potentially shed 50% load on the system to address the surging
            oxygen crisis.
          </SingleSpeaker>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default speakers;
