import React, { useState } from "react";
import PageCover from "../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import AlertModal from "../AlertModalComponent/AlertModalComponent";
import { db } from "../../firebase";

const tShirtComponent = () => {
  const [fullname, setFullname] = useState("");
  const [poll, setPoll] = useState("Polo");
  const [modal, showModal] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (poll === "Polo") {
      db.collection("tshirtpoll")
        .add({
          fullname: fullname,
          poll: poll,
          votedAt: new Date(),
        })
        .then(() => {
          showModal(
            <AlertModal
              message="Your Poll has been submitted. Thank you for your feedback!"
              icon="successful.png"
              reload="true"
              close={closeModal}
            />
          );
        })
        .catch((error) => {
          showModal(
            <AlertModal
              message={error.message}
              icon="failed.png"
              reload="true"
              close={closeModal}
            />
          );
        });
      e.target.reset();
    } else
      showModal(
        <AlertModal
          message="We have detected an unusual response from you! Kindly vote again!"
          icon="failed.png"
          reload="true"
          close={closeModal}
        />
      );
  };

  const closeModal = () => {
    showModal("");
  };

  return (
    <React.Fragment>
      <PageCover title="T-Shirt Poll" />
      {modal}
      <div className="loginCont">
        <form onSubmit={handleSubmit}>
          <Fade up>
            <div className="heading">Submit your vote!</div>
            <div className="inputGroup">
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Full Name*"
                onChange={(e) => setFullname(e.target.value)}
                required
              />
            </div>
            <div className="inputGroup">
              <select
                name="poll"
                id="poll"
                onChange={(e) => setPoll(e.target.value)}
              >
                <option value="Polo">Polo</option>
                <option value="Round Neck">Round Neck</option>
              </select>
            </div>
            <div className="inputGroup">
              <button type="submit">SUBMIT</button>
            </div>
          </Fade>
        </form>
      </div>
    </React.Fragment>
  );
};

export default tShirtComponent;
