import React, { useState } from "react";
import "./BookingComponent.css";
import "./BookingComponent2023.css";
import { Helmet } from "react-helmet";
import "./BookingCard.css";
import { BsInfoSquare } from "react-icons/bs";
import { FaFeatherAlt } from "react-icons/fa";
import IButtonComp from "./IButtonComp";

const book = () => {
  const [open, setOpen] = useState(false);
  const [ticketImg, setTicketImg] = useState("");
  const [ticketTitle, setTicketTitle] = useState("");
  const [ticketPrice, setTicketPrice] = useState("");

  const handleiButton = (e) => {
    const id = e.target.id;
    if (id === "premium") {
      setTicketTitle("PREMIUM");
      setTicketImg("premium");
      setTicketPrice("₹ 190/-");
      setOpen(!open);
    }

    if (id === "primeplus") {
      setTicketTitle("PRIME PLUS");
      setTicketPrice("₹ 140/-");
      setTicketImg("primep");
      setOpen(!open);
    }

    if (id === "prime") {
      setTicketTitle("PRIME");
      setTicketImg("prime");
      setTicketPrice("₹ 90/-");
      setOpen(!open);
    }
  };

  const handleCloseClick = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>TEDxBITD - Book your Ticket</title>
        <meta
          name="description"
          content="Crafting TEDxBITD is the greatest task of all. To capture the real essence of a TED-like experience, BIT Durg is aiming to follow TED’s own program structure, with some mix of our creativity."
        />
      </Helmet>

      <div className="bookingCont">
        <div className="header">
          <div className="content">
            <h2 className="title">Book the event tickets now!</h2>
            <div className="details">
              <div className="single-detail">
                <i className="far fa-calendar-alt"></i>19th of August, 2023
              </div>
              <div className="single-detail">
                <i className="far fa-clock"></i>10:00 AM - 5:00 PM
              </div>
              <div className="single-detail">
                <i className="fas fa-ticket-alt"></i>Get your tickets now!
              </div>
            </div>

            {/* <div className="single-detail">Have a Referral Code?</div>
            <a href="/referral">Referral Page</a> */}
            <a href="/contact">Contact Us</a>
          </div>
        </div>
        <div className="body">
          <div className={open ? "open popup" : "popup"}>
            <IButtonComp
              ticketTitle={ticketTitle}
              ticketPrice={ticketPrice}
              ticketImg={ticketImg}
              handleCloseClick={handleCloseClick}
            />
          </div>
          {/* <div className="referral-slab">
            <div className="slab-title">Have a Referral Code?</div>
            <a href="/referral">Use Now</a>
          </div> */}
          {/* <h2 className="title text-center">Available Tiers</h2> */}
          <h2 className="title text-center">
            EVENT TICKETS <FaFeatherAlt /> <br />
            {/* <span style={{ fontSize: "1rem" }}>
              6th August - 7th August
            </span>{" "} */}
          </h2>

          <div className="slabs-list">
            <div className="booking-card">
              <div className=" content">
                <div className="back">
                  <div className="back-content ">
                    <div className="single-slab">
                      <div className="iButton">
                        <BsInfoSquare id="primeplus" onClick={handleiButton} />
                      </div>
                      <div className="slab-header">
                        {/* <h4 className="price">
                          ₹<span>140</span>
                        </h4> */}
                        <h4 className="price">
                          {/* <strike>₹170</strike> */}₹{" "}
                          <strike
                            style={{ fontSize: "2.5rem", fontWeight: "bold" }}
                          >
                            170
                          </strike>
                        </h4>
                        <h5 className="slab-title">Prime Plus</h5>
                      </div>
                      <div className="slab-body">
                        <div className="details">
                          {/* <div className="single-detail">
                            <div className="icon">
                              <i className="far fa-calendar-alt"></i>
                            </div>
                            <div className="desc">6th August - 7th August</div>
                          </div> */}
                          {/* <div className="single-detail">
                            <div className="icon">
                              <i className="fas fa-ticket-alt"></i>
                            </div>
                            <div className="desc">50% OFF</div>
                          </div> */}
                          {/* <div className="single-detail">
                            <div className="icon">
                              <i className="fas fa-ticket-alt"></i>
                            </div>
                            <div className="desc">Available</div>
                          </div> */}
                          {/* <div className="single-detail">
                            <div className="icon">
                              <i className="fas fa-ticket-alt"></i>
                            </div>
                            <div className="desc">Housefull</div>
                          </div> */}
                          <div className="single-detail">
                            <div className="icon">
                              <i className="fas fa-fire"></i>
                            </div>
                            <div className="desc">Sold Out</div>
                          </div>
                        </div>
                      </div>
                      <div className="slab-footer">
                        <a
                          // href="https://rzp.io/l/tedxbit23-prime-plus"
                          href="#"
                          // target="_blank"
                          // rel="noopener noreferrer"
                        >
                          Tickets Not Available
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="booking-card">
              <div className=" content">
                <div className="back">
                  <div className="back-content ">
                    <div className="single-slab">
                      <div className="iButton">
                        <BsInfoSquare id="premium" onClick={handleiButton} />
                      </div>
                      <div className="slab-header">
                        <h4 className="price">
                          {/* <strike>₹220</strike> */}₹&nbsp;
                          <strike
                            style={{ fontSize: "2.5rem", fontWeight: "bold" }}
                          >
                            220
                          </strike>
                        </h4>
                        {/* <h4 className="price">
                          ₹<span>190</span>
                        </h4> */}
                        <h5 className="slab-title">Premium</h5>
                      </div>
                      <div className="slab-body">
                        <div className="details">
                          {/* <div className="single-detail">
                            <div className="icon">
                              <i className="far fa-calendar-alt"></i>
                            </div>
                            <div className="desc">6th August - 7th August</div>
                          </div> */}
                          {/* <div className="single-detail">
                            <div className="icon">
                              <i className="fas fa-ticket-alt"></i>
                            </div>
                            <div className="desc">50% OFF</div>
                          </div> */}
                          {/* <div className="single-detail">
                            <div className="icon">
                              <i className="fas fa-ticket-alt"></i>
                            </div>
                            <div className="desc">Housefull</div>
                          </div> */}
                          <div className="single-detail">
                            <div className="icon">
                              <i className="fas fa-fire"></i>
                            </div>
                            <div className="desc">Sold Out</div>
                          </div>
                        </div>
                      </div>
                      <div className="slab-footer">
                        <a
                          // href="https://rzp.io/l/tedxbit23-premium"
                          href="#"
                          // target="_blank"
                          // rel="noopener noreferrer"
                        >
                          Tickets Not Available
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="booking-card">
              <div className=" content">
                <div className="back">
                  <div className="back-content ">
                    <div className="single-slab">
                      <div className="iButton">
                        <BsInfoSquare id="prime" onClick={handleiButton} />
                      </div>
                      <div className="slab-header">
                        <h4 className="price">
                          {/* <strike>₹120</strike>  */}₹{" "}
                          <strike
                            style={{ fontSize: "2.5rem", fontWeight: "bold" }}
                          >
                            120
                          </strike>
                        </h4>
                        {/* <h4 className="price">
                          ₹<span>90</span>
                        </h4> */}
                        <h5 className="slab-title">Prime</h5>
                      </div>
                      <div className="slab-body">
                        <div className="details">
                          {/* <div className="single-detail">
                            <div className="icon">
                              <i className="far fa-calendar-alt"></i>
                            </div>
                            <div className="desc">6th August - 7th August</div>
                          </div> */}
                          {/* <div className="single-detail">
                            <div className="icon">
                              <i className="fas fa-ticket-alt"></i>
                            </div>
                            <div className="desc">50% OFF</div>
                          </div> */}
                          {/* <div className="single-detail">
                            <div className="icon">
                              <i className="fas fa-ticket-alt"></i>
                            </div>
                            <div className="desc">Available</div>
                            <div className="desc">Housefull</div>
                          </div> */}
                          <div className="single-detail">
                            <div className="icon">
                              <i className="fas fa-fire"></i>
                            </div>
                            {/* <div className="desc">Fast selling</div> */}
                            <div className="desc">Sold Out</div>
                          </div>
                        </div>
                      </div>
                      <div className="slab-footer">
                        <a
                          // href="https://rzp.io/l/tedxbitd22-prime"
                          href="#"
                          // target="_blank"
                          // rel="noopener noreferrer"
                        >
                          {/* Book Now */}
                          Tickets Not Available
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="referralBox">
            <p>Have a referral code ??</p>
              <a className="referralButton" href="/referral">Referral page</a>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default book;
