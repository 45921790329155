import React from "react";
import "./ImageComponent.css";

const image = (props) => {
  const path = props.path;
  let img = null;
  let tooltipImg = null;
  if (path !== null) {
    img = <img className="personImage" src={"./assets/images/team-2022/" + props.path} alt={props.name} />;
    tooltipImg = <img src={"./assets/images/team-2022/" + props.path} alt={props.name} />;
  }

  const post = props.post;
  let text = null;
  if (post !== null)
    text = <div className="post">{props.post}</div>;

  let linkedin = props.linkedin;
  if(linkedin !== null)
    linkedin = linkedin.substr(linkedin.lastIndexOf("/") + 1);

  return (
    <div className="memberCard">
      <div className="memberImage">
        {img}
        <div className="memberQuote">
          "{props.quote}"
        </div>
        <div className="memberSocial">
          <div className="singleSocial">
            <i className="fab fa-instagram"></i>
            <div className="socialTooltip">
              <div className="details">
                <div className="image">
                  {tooltipImg}
                </div>
                <div className="text">
                  <h4>{props.name}</h4>
                  <h5>@{props.instagram}</h5>
                  <a href={"https://instagram.com/" + props.instagram} className="cta" target="_blank" rel="noopener noreferrer">Follow</a>
                </div>
              </div>
              <div className="whiteSpace"></div>
            </div>
          </div>
          <div className="singleSocial">
            <i className="fab fa-linkedin"></i>
            <div className="socialTooltip">
              <div className="details">
                <div className="image">
                  {tooltipImg}
                </div>
                <div className="text">
                  <h4>{props.name}</h4>
                  <h5>@{linkedin}</h5>
                  <a href={props.linkedin} className="cta" target="_blank" rel="noopener noreferrer">Follow</a>
                </div>
              </div>
              <div className="whiteSpace"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="memberDetails">
        <h4 className="name">{props.name}</h4>
        {text}
      </div>
    </div>
  );
};

export default image;
