import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./SingleMoodBoard.css";
import { db } from "../../../firebase";
import Moment from "moment";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const SingleMoodBoard = (props) => {
  const [moodboard, setMoodBoard] = useState([]);

  const history = useHistory();
  const ref = db.collection("moodBoard").doc(props.match.params.id);
  ref.get().then((doc) => {
    if (doc.exists) {
      const MoodBoard = doc.data();
      setMoodBoard({
        id: doc.id,
        moodboardtitle: MoodBoard.moodBoardTitle,
        moodboardauthor: MoodBoard.fullname,
        moodboardimage: MoodBoard.url,
        moodboarddate: MoodBoard.createdAt.toDate(),
      });
    } else {
      history.push("/moodboard");
    }
  });

  const [OtherMoodboards, setOtherMoodBoards] = useState([]);
  useEffect(() => {
    const fetchdata = async () => {
      db.collection("moodBoard").onSnapshot(function (data) {
        setOtherMoodBoards(
          data.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        );
      });
    };
    fetchdata();
  }, []);

  const shareUrl =
    "https://tedxbitd.com/singlemoodboard/" + props.match.params.id;
  const shareText =
    "TEDxBITD Moodboard by " +
    moodboard.moodboardauthor +
    ' on "' +
    moodboard.moodboardtitle +
    '"';
  return (
    <React.Fragment>
      <div className="singleMoodBoardCont">
        <div className="moodBoardHeader">
          <div className="moodBoardDetails">
            <h1 className="moodBoardTitle">{moodboard.moodboardtitle}</h1>
            <h5 className="moodBoardAuthor">{moodboard.moodboardauthor}</h5>
            <h6 className="moodBoardDate">
              Posted on {Moment(moodboard.moodboarddate).format("LL")}
            </h6>
          </div>

          <div className="moodBoardImage">
            <img src={moodboard.moodboardimage} alt="" />
          </div>
        </div>

        <div className="moodBoardMain">
          <div className="moodBoardLeft">
            <div className="moodBoardShare">
              <div className="shareButtons">
                <h6>Share on:</h6>
                <WhatsappShareButton url={shareUrl} title={shareText}>
                  <WhatsappIcon size="32" round={true} />
                </WhatsappShareButton>
                <FacebookShareButton url={shareUrl} quote={shareText}>
                  <FacebookIcon size="32" round={true} />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={shareText}>
                  <TwitterIcon size="32" round={true} />
                </TwitterShareButton>
                <LinkedinShareButton url={shareUrl} title={shareText}>
                  <LinkedinIcon size="32" round={true} />
                </LinkedinShareButton>
                <TelegramShareButton url={shareUrl} title={shareText}>
                  <TelegramIcon size="32" round={true} />
                </TelegramShareButton>
              </div>
            </div>
          </div>
          <div className="moodBoardRight">
            <div className="moodBoardList">
              <h3>Other MoodBoards </h3>
              {OtherMoodboards.map((MoodBoards) => {
                return MoodBoards.id !== props.match.params.id ? (
                  <div>
                  {MoodBoards.verify == true ?<Link
                    to={`/singlemoodboard/${MoodBoards.id}`}
                    className="singleMoodBoardList"
                  >
                    <div className="singleMoodBoardListImage">
                      <img src={MoodBoards.url} alt="Moodboargs Image" />
                    </div>
                    <div className="singleMoodBoardContent">
                      <div className="moodBoardTitle">
                        {MoodBoards.fullname}
                      </div>
                      <div className="moodBoardDate">
                        Posted on{" "}
                        {Moment(MoodBoards.createdAt.toDate()).format("LL")}
                      </div>
                    </div>
                  </Link> :null}
                  
                  </div>
                ) : null;
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SingleMoodBoard;
