import React from 'react'

import "./IButtonComp.css"
import Premium from "../../img/premium.png"
import Prime from "../../img/prime.png"
import PrimeP from "../../img/primep.png"
import { GrClose } from "react-icons/gr";
import { filter } from 'minimatch'


function IButtonComp(props) {
  const showImg = props.ticketImg
  
  return (
    <div className='iButtonComp'>
        <p>{props.ticketTitle}</p>
      <div className={`iButtonComp-Details`}>
        <div className={`ticket-details ${ window.innerWidth <= 700 && showImg == "primep" ? "displayCont" : ""} ` }>
          <img src={PrimeP} alt=""  className={` ${showImg == "primep" ? "removeFilter" : null } `} />
          <p className={` ${showImg == "primep" ? "changeColor" : null } `}  >PRIME PLUS</p>
        </div>
        <div className={`ticket-details ${ window.innerWidth <= 700 && showImg == "premium" ? "displayCont" : ""} ` }>
          <img src={Premium} alt=""  className={` ${showImg == "premium" ? "removeFilter" : null } `} />
          <p className={` ${showImg == "premium" ? "changeColor" : null } `}>PREMIUM</p>
        </div>
        <div className={`ticket-details ${ window.innerWidth <= 700 && showImg == "prime" ? "displayCont" : ""} ` }>
          <img src={Prime} alt="" className={` ${showImg == "prime" ? "removeFilter" : null } `} />
          <p className={` ${showImg == "prime" ? "changeColor" : null } `}>PRIME</p>
        </div>
      </div>
        <div onClick={props.handleCloseClick} className='iButtonComp-Close'>x</div>
    </div>
  )
}

export default IButtonComp
