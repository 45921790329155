import React, { useEffect, useState } from "react";
import PageCover from "../../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import emailjs from "emailjs-com";
import AlertModal from "../../AlertModalComponent/AlertModalComponent";
import { db } from "../../../firebase";
import { Helmet } from "react-helmet";

const sponsorNominations = () => {
  const [companyName, setCompanyName] = useState("");
  const [companyIndustry, setCompanyIndustry] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [pocName, setPocName] = useState("");
  const [pocPosition, setPocPosition] = useState("");
  const [pocMobile, setPocMobile] = useState("");
  const [pocEmail, setPocEmail] = useState("");
  const [queries, setQueries] = useState("");
  const [checkbox, setCheckbox] = useState({
    isTitle: false,
    isCoSponsor: false,
    isPlatnium: false,
    isGold: false,
    isAssociate: false,
    isFood: false,
  });

  const onChangeTitle = () => {
    setCheckbox((initialState) => ({
      ...checkbox,
      isTitle: !initialState.isTitle,
    }));
  };

  // const onChangePlatinum = () => {
  //   setCheckbox((initialState) => ({
  //     ...checkbox,
  //     isPlatnium: !initialState.isPlatnium,
  //   }));
  // };
  const onChangeCoSponsor = () => {
    setCheckbox((initialState) => ({
      ...checkbox,
      isCoSponsor: !initialState.isCoSponsor,
    }));
  };

  // const onChangeGold = () => {
  //   setCheckbox((initialState) => ({
  //     ...checkbox,
  //     isGold: !initialState.isGold,
  //   }));
  // };

  const onChangeAssociate = () => {
    setCheckbox((initialState) => ({
      ...checkbox,
      isAssociate: !initialState.isAssociate,
    }));
  };

  const onChangeFood = () => {
    setCheckbox((initialState) => ({
      ...checkbox,
      isFood: !initialState.isFood,
    }));
  };

  const [loader, setLoader] = useState(false);
  const [modal, showModal] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    if (
      checkbox.isTitle == false &&
      checkbox.isCoSponsor == false &&
      checkbox.isPlatnium == false &&
      checkbox.isGold == false &&
      checkbox.isAssociate == false &&
      checkbox.isFood == false
    ) {
      return alert("Please tick one of the check boxes");
    }
    db.collection("sponsornominations23")
      .add({
        companyName: companyName,
        companyIndustry: companyIndustry,
        companyEmail: companyEmail,
        companyWebsite: companyWebsite,
        pocName: pocName,
        pocPosition: pocPosition,
        pocMobile: pocMobile,
        pocEmail: pocEmail,
        queries: queries,
        checkbox: checkbox,
        createdAt: new Date(),
      })
      .then(() => {
        setLoader(false);
        showModal(
          <AlertModal
            message="Your Nomination has been submitted. Thank you for your interest, we'll get in touch with you soon."
            icon="successful.png"
            reload="true"
            close={closeModal}
          />
        );
      })
      .catch((error) => {
        showModal(
          <AlertModal
            message={error.message}
            icon="failed.png"
            reload="true"
            close={closeModal}
          />
        );
        setLoader(false);
      });

    emailjs
      .sendForm(
        "service_n6fo218", // Service Id
        "template_3386ijh", // Template Id
        e.target,
        "user_j147cM4xSAMaZzHRF7Kqm" // User Id
      )
      .then(
        (result) => {
          // console.log(result.text);
          setLoader(false);
        },
        (error) => {
          // console.log(error.text);
          setLoader(false);
        }
      );
    e.target.reset();
  };

  const closeModal = () => {
    showModal("");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>TEDxBITD - Sponsor Nominations</title>
        <meta
          name="description"
          content="TEDxBITD has a great support team to answer all your questions related to the event. Feel free to drop by and we will get back to you at the earliest."
        />
        <meta
          name="keywords"
          content="ted,tedx,bit,durg,speakers,bitd,event,campus,college,bhilai"
        />
      </Helmet>
      <PageCover title="Sponsor Nominations" />
      {modal}
      <div className="submitCont">
        <Fade up>
          <div className="instructions">
            <Fade up>
              <h3>Read the Instructions before submitting:</h3>
              <ol>
                <li>
                  You can download the sponsorship pitch deck from here-{" "}
                  <a
                    href="./assets/docs/TEDxBITD - Sponsorship Proposal.pdf"
                    download
                  >
                    Download Pitch Deck
                  </a>
                  .
                </li>
                <li>
                  All the fields marked with an <strong>*</strong> are mandatory
                  to fill.
                </li>
                <li>You can choose multiple partnership categories.</li>
              </ol>
            </Fade>
          </div>
          <form onSubmit={handleSubmit}>
            <h2 className="lineTitle">
              <span>
                <strong>Company</strong> Information
              </span>
            </h2>
            <div className="formRow twoInputs">
              <div className="inputGroup">
                <label htmlFor="companyName">Company Name:*</label>
                <input
                  type="text"
                  name="companyName"
                  id="companyName"
                  placeholder="Company Name*"
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="companyIndustry">Company Industry:*</label>
                <select
                  name="companyIndustry"
                  id="companyIndustry"
                  onChange={(e) => setCompanyIndustry(e.target.value)}
                  required
                >
                  <option value="">--Select Industry--</option>
                  <option value="Education">Education</option>
                  <option value="Electronics">Electronics</option>
                  <option value="Entertainment">Entertainment</option>
                  <option value="Food">Food</option>
                  <option value="Health care">Health care</option>
                  <option value="Hospitality">Hospitality</option>
                  <option value="News/Media">News/Media</option>
                  <option value="Software/IT">Software/IT</option>
                  <option value="Transport">Transport</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="inputGroup">
                <label htmlFor="companyEmail">Company Email:*</label>
                <input
                  type="email"
                  name="companyEmail"
                  id="companyEmail"
                  placeholder="Company Email*"
                  onChange={(e) => setCompanyEmail(e.target.value)}
                  required
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="companyWebsite">Company Website:</label>
                <input
                  type="text"
                  name="companyWebsite"
                  id="companyWebsite"
                  placeholder="Company Website"
                  onChange={(e) => setCompanyWebsite(e.target.value)}
                />
              </div>
            </div>
            <h2 className="lineTitle">
              <span>
                <strong>Person of Contact(PoC)</strong> Information
              </span>
            </h2>
            <div className="formRow twoInputs">
              <div className="inputGroup">
                <label htmlFor="pocName">PoC Name:*</label>
                <input
                  type="text"
                  name="pocName"
                  id="pocName"
                  placeholder="PoC Name*"
                  onChange={(e) => setPocName(e.target.value)}
                  required
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="pocPosition">PoC Position:*</label>
                <input
                  type="text"
                  name="pocPosition"
                  id="pocPosition"
                  placeholder="PoC Position*"
                  onChange={(e) => setPocPosition(e.target.value)}
                  required
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="pocMobile">PoC Mobile No.:*</label>
                <input
                  type="text"
                  name="pocMobile"
                  id="pocMobile"
                  placeholder="PoC Mobile No.*"
                  onChange={(e) => setPocMobile(e.target.value)}
                  required
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="pocEmail">PoC Email:*</label>
                <input
                  type="email"
                  name="pocEmail"
                  id="pocEmail"
                  placeholder="PoC Email*"
                  onChange={(e) => setPocEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="formRow d-block">
              <div className="inputGroup">
                <label htmlFor="pocLevel">Partnership Category(ies):</label>
                <div className="row pl-4 mt-2">
                  <div className="form-check col-lg-2">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        checked={checkbox.isTitle}
                        onChange={onChangeTitle}
                        className="form-check-input"
                      />
                      Title
                    </label>
                  </div>
                  <div className="form-check col-lg-2">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        checked={checkbox.isCoSponsor}
                        onChange={onChangeCoSponsor}
                        className="form-check-input"
                      />
                      Co-Sponsor
                    </label>
                  </div>
                  {/* <div className="form-check col-lg-2">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        checked={checkbox.isPlatinum}
                        onChange={onChangePlatinum}
                        className="form-check-input"
                      />
                      Platinum
                    </label>
                  </div> */}
                  {/* <div className="form-check col-lg-2">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        checked={checkbox.isGold}
                        onChange={onChangeGold}
                        className="form-check-input"
                      />
                      Gold
                    </label>
                  </div> */}
                  <div className="form-check col-lg-2">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        checked={checkbox.isAssociate}
                        onChange={onChangeAssociate}
                        className="form-check-input"
                      />
                      Associate
                    </label>
                  </div>
                  <div className="form-check col-lg-2">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        checked={checkbox.isFood}
                        onChange={onChangeFood}
                        className="form-check-input"
                      />
                      Food & Beverage & Gifting
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="formRow">
              <div className="inputGroup">
                <label htmlFor="queries">Queries(if any):</label>
                <textarea
                  name="queries"
                  id="queries"
                  rows="5"
                  placeholder="Type your query..."
                  onChange={(e) => setQueries(e.target.value)}
                ></textarea>
              </div>
            </div>
            {loader}
            <div className="formRow singleInput">
              <div className="inputGroup">
                <button type="submit">SUBMIT NOMINATION</button>
              </div>
            </div>
          </form>
        </Fade>
      </div>
    </React.Fragment>
  );
};

export default sponsorNominations;
