import React, { useEffect, useState } from "react";
import "./RegistrationsComponent.css";
import PageCover from "../../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import { db } from "../../../firebase";
import Moment from "moment";
import { Alert, ButtonToggle } from "reactstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const registrationsListComponent = () => {
  const [application, setApplication] = useState([]);

  useEffect(() => {
    db.collection("2023Registrations")
      .orderBy("date", "desc")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setApplication(data);
      });
  }, []);

  let counter = 0;

  return (
    <React.Fragment>
      <PageCover title="2023 Applications" />
      <div className="registrationsListCont">
        <table id="bookingsExcelTable" className="table d-none">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Name</th>
              <th>Contact No.</th>
              <th>E-mail</th>
              <th>Semester</th>
              <th>Teams</th>
              <th>Position</th>
              <th>Instagram</th>
              <th>Linkedin</th>
              <th>Tasks</th>
              <th>Answer 1</th>
              <th>Answer 2</th>
              <th>Answer 3</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {application?.map((data, index) => {
                let teams = [];
                data.teams.forEach(team => {
                    teams.push(team.value);
                });
                teams = teams.toString().replace(/,/g, ", ");
              return (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td>{data.fullname}</td>
                  <td>{data.contactNo}</td>
                  <td>{data.email}</td>
                  <td>{data.semester}</td>
                  <td>{teams}</td>
                  <td>{data.position}</td>
                  <td>{data.instagramLink}</td>
                  <td>{data.linkedinLink}</td>
                  <td>{data.link}</td>
                  <td>{data.answer1}</td>
                  <td>{data.answer2}</td>
                  <td>{data.answer3}</td>
                  <td>
                    {Moment(data.date?.toDate().toString()).format("lll")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={{ margin: "0 0 3em 15px" }}>
          <ReactHTMLTableToExcel
            className="btn btn-info"
            table="bookingsExcelTable"
            filename="TEDxBITD 2022 Registrations"
            sheet="Sheet"
            buttonText="Export List as Spreadsheet"
          />
        </div>
        <div className="blogs">
          {application?.map((data, i) => {
            counter++;
            let teams = [];
            data.teams.forEach(team => {
                teams.push(team.value);
            });
            teams = teams.toString().replace(/,/g, ", ");
            return (
              <Fade up key={i}>
                <div className="singleBlog">
                  <div className="left">
                    <div className="title">{( i + 1 ) + ". " + data.fullname}</div>
                    <div className="date">
                      <b>Email:&nbsp;&nbsp;</b>
                      {data.email}
                    </div>
                    <div className="date">
                      <b>Contact No.:&nbsp;&nbsp;</b>
                      {data.contactNo}
                    </div>
                    <div className="date">
                      <b>Semester:&nbsp;&nbsp;</b>
                      {data.semester}
                    </div>
                    <div className="date">
                      <b>Teams:&nbsp;&nbsp;</b>
                      {teams}
                    </div>
                    <div className="date">
                      <b>Position:&nbsp;&nbsp;</b>
                      {data.position}
                    </div>
                    <div className="date">
                      <b>Instagram:&nbsp;&nbsp;</b>
                      {data.instagramLink}
                    </div>
                    <div className="date">
                      <b>Linkedin:&nbsp;&nbsp;</b>
                      {data.linkedinLink}
                    </div>
                    <div className="date">
                      <b>Tasks Link:&nbsp;&nbsp;</b>
                      <a href={data.link} target="_blank" rel="noopener noreferrer">Click here</a>
                    </div>
                    <div className="date">
                      <b>Answer 1:&nbsp;&nbsp;</b>
                      {data.answer1}
                    </div>
                    <div className="date">
                      <b>Answer 2:&nbsp;&nbsp;</b>
                      {data.answer2}
                    </div>
                    <div className="date">
                      <b>Answer 3:&nbsp;&nbsp;</b>
                      {data.answer3}
                    </div>
                    <div className="date">
                      <b>Registered at:&nbsp;&nbsp;</b>
                      {Moment(data.date?.toDate().toString()).format(
                        "lll"
                      )}
                    </div>
                  </div>
                  <div className="right">
                    <a href={data.url} target="_blank" rel="noopener noreferrer">
                      <button type="button">Download Resume</button>
                    </a>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
        {counter === 0 ? (
          <Fade up>
            <Alert
              color="danger"
              style={{ textAlign: "center", padding: "1.5em 0" }}
            >
              Oops! Looks like there are no registrations at the moment!
              <br />
              <br />
              <a href="/dashboard">
                <ButtonToggle color="danger">Go Back</ButtonToggle>
              </a>
            </Alert>
          </Fade>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default registrationsListComponent;