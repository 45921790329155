import React from "react";
import "./PageCover.css";
import { Fade } from "react-reveal";

const pageCover = (props) => {
  return (
    <React.Fragment>
      <div className="coverCont">
        <Fade up>
          <h1>{props.title}</h1>
        </Fade>
      </div>
    </React.Fragment>
  );
};

export default pageCover;
