import React, { useEffect, useState } from "react";
import "./SpeakersListComponent.css";
import PageCover from "../../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import { db } from "../../../firebase";
import Moment from 'moment';
import { Alert, ButtonToggle } from 'reactstrap';

const speakersListComponent = () => {

    const [application, setApplication] = useState([]);
  
    useEffect(() => {
        db.collection("Speakers").orderBy("date", "desc")
            .get()
            .then((querySnapshot) => {
                const data = querySnapshot.docs.map((doc) => doc.data());
            setApplication(data);
        });
    }, []);

    let counter = 0;

    return(
        <React.Fragment>
            <PageCover title="Speakers Nominations" />
            <div className="speakersListCont">
                <div className="blogs">
                    {application?.map((data, i) => {
                        counter++;
                        return (
                            <Fade up key={i}>
                                <div className="singleBlog">
                                    <div className="left">
                                        <div className="title">{data.fullname}</div>
                                        <div className="date"><b>Email:&nbsp;&nbsp;</b>{data.email}</div>
                                        <div className="date"><b>Contact No.:&nbsp;&nbsp;</b>{data.contactNo}</div>
                                        <div className="date"><b>Occupation:&nbsp;&nbsp;</b>{data.occupation}</div>
                                        <div className="date"><b>About:&nbsp;&nbsp;</b>{data.about}</div>
                                        <div className="date"><b>Nominated on:&nbsp;&nbsp;</b>{Moment(data.date?.toDate().toString()).format('lll')}</div>
                                    </div>
                                    <div className="right">
                                        {
                                            data.filename ?
                                                <a href={data?.url} target="_blank" rel="noopener noreferrer">
                                                    <button type="button" >PDF Link</button>
                                                </a>
                                                        :
                                                <a href="javascript:void(0)">
                                                    <button type="button" >N/A</button>
                                                </a>
                                        }
                                        
                                    </div>
                                </div>
                            </Fade>
                        );
                    })}
                </div>
                {
                    counter === 0 ?
                        <Fade up>
                            <Alert color="danger" style={{ textAlign: "center", padding: "1.5em 0" }}>
                                Oops! Looks like there are no Speaker nominations at the moment!
                                <br /><br />
                                <a href="/dashboard"><ButtonToggle color="danger">Go Back</ButtonToggle></a>
                            </Alert>
                        </Fade>
                                :
                        null
                }
            </div>
        </React.Fragment>
    );
};

export default speakersListComponent;