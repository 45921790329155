import React, { Component } from "react";
import "./TeamComponent.css";
import { useWindowSize } from "react-use"
import PageCover from "../HeaderComponents/PageCover";
import Image from "./ImageComponent/ImageComponent";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";
import Card from "./ImageComponent23/Card2";
import "./NewTeamComponent.css";
import Pivot from "../../img/pivot3.png";
import ReactConfetti from "react-confetti";
import { useState } from "react";
import { useEffect } from "react";


import yukti from "../../team-2023/yukti.png"
import shivam from "../../team-2023/shivam.png"
import ananya from "../../team-2023/ananya.png"
import nihal from "../../team-2023/nihal.png"

//team leads
import anurag from "../../team-2023/anurag.png"
import ariin from "../../team-2023/ariin.png"
import ankit from "../../team-2023/ankit.png"
import ritesh from "../../team-2023/ritesh.png"
import harshit from "../../team-2023/harshit.png"
import aryan from "../../team-2023/aryan.png"
import anshul from "../../team-2023/anshul.png"
import gaurav from "../../team-2023/gaurav.png"
import manas from "../../team-2023/manas.png"
import rajnish from "../../team-2023/rajnish.png"


// event management member
import himanshu from "../../team-2023/himanshu.png"
import adyali from "../../team-2023/adyali.png"
import veeyashu from "../../team-2023/veeyashu.png"
import shreya from "../../team-2023/shreya.png"

// public relations member
import bhumika from "../../team-2023/bhumika.png"
import sitanshu from "../../team-2023/sitanshu.png"

// marketing member
import anuoshka from "../../team-2023/anuoshka.png"
import aryans from "../../team-2023/aaryan.png"
import anuj from "../../team-2023/anuj.png"

// technical member
import devansh from "../../team-2023/devansh.png"
import rishabh from "../../team-2023/rishabh.png"

//content member
import lavanya from "../../team-2023/lavanya.png"
import zunaira from "../../team-2023/zunaira.png"

//logitics member
import vaibhav from "../../team-2023/vaibhav.png"
import shourya from "../../team-2023/shourya.png"
import mmoulika from "../../team-2023/mmoulika.png"

//sponsor memeber
import shubhra from "../../team-2023/shubhra.png"
import vikramaditya from "../../team-2023/vikramaditya.png"
import pratham from "../../team-2023/pratham.png"

// video member
import shreyansh from "../../team-2023/shreyansh.png"
import bhrigesh from "../../team-2023/bhrigesh.png"

//graphic member
import abhay from "../../team-2023/abhay.png"
import atul from "../../team-2023/atul.png"
import aaditya from "../../team-2023/aaditya.png"

//camera member
import kunal from "../../team-2023/kunal.png"
import abhishek from "../../team-2023/abhishek.png"



function Team() {
  const [confettiCount, setconfettiCount] = useState(300);
  const [showConfetti, setShowConfetti] = useState(true);
  const { width, height } = useWindowSize(); 

  useEffect(() => {
    setTimeout(() => {
      setconfettiCount(10);
    }, 2500);
    setTimeout(() => { 
      setShowConfetti(false);
    }, 4000); //4000ms => 4s
  }, []);

    return (
      <React.Fragment>
        <Helmet>
          <title>TEDxBITD - Team Members</title>
          <meta
            name="description"
            content="The critical feature to organizing any event and making it a success is to gather a team you could depend upon. TEDxBITD has a team of volunteers from BIT Durg working hard to make the event memorable."
          />
          <meta
            name="keywords"
            content="ted,tedx,bit,durg,speakers,bitd,event,campus,college,bhilai"
          />
        </Helmet>
        <PageCover title="Team 2023" />
        { showConfetti && <ReactConfetti numberOfPieces={confettiCount} tweenDuration={1000} width={width > 768 ?  width -50 : width   } /> }
        <div className="teamCont">
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Core</strong> Team <img src={Pivot} alt=""/>
              </span>
            </h2>
            <div className="teamList">
              {/* <Fade left> */}
                <Card
                  path={yukti}
                  name="Yukti Jhawar"
                  post="Organizer"
                  contact="9109079518"
                  quote="I speak my mind and I never mind what I speak!"
                  instagram="https://www.instagram.com/yukti_3004/"
                  linkedin="https://www.linkedin.com/in/yukti-jhawar-2002"
                  twitter ="https://twitter.com/YuktiJhawar?s=20"
                />
              {/* </Fade> */}
              <Card
                path={shivam}
                name="Shivam Kashyap"
                post="Co-Organizer"
                contact="9571089472"
                quote="That E-sports freak Everyone talks about 🎮"
                instagram="https://instagram.com/ishivam._.7?igshid=YmMyMTA2M2Y="
                linkedin="https://www.linkedin.com/in/shivamkashyap585-gmail-com"
                twitter="https://twitter.com/iKashyap7?s=20"
              />
              {/* <Fade right> */}
                <Card
                  path={ananya}
                  name="Ananya Chakraborty"
                  post="Curator"
                  contact="9827993660"
                  quote="Talk to me carefully; I might correct your grammar."
                  instagram="https://instagram.com/ananya_chaks?igshid=YmMyMTA2M2Y="
                  linkedin="https://www.linkedin.com/in/ananya-chakraborty-607734229"
                  twitter="#"
                />
              {/* </Fade> */}
              {/* <Fade left> */}
                <Card
                  path={nihal}
                  name="Nihal Singh"
                  post="Curator"
                  contact="7067303170"
                  quote="Being loud about the things that matter"
                  instagram="https://instagram.com/canzova.pvt?igshid=YmMyMTA2M2Y="
                  linkedin="https://www.linkedin.com/in/nihal-singh-6b2116200"
                  twitter="#"
                />
              {/* </Fade> */}
              <Card
                path={anurag}
                name="Anurag Rai"
                post="Event Management Head"
                contact="7999528048"
                quote="I don't take any responsibility for my jokes, just kidding!"
                instagram="https://www.instagram.com/anurag_rai15/"
                linkedin="https://www.linkedin.com/in/anurag-rai-0b7a1022a"
                twitter="https://twitter.com/AnuragRai1015"
              />
              {/* <Fade right> */}
                <Card
                  path={himanshu}
                  name="Himanshu Deshmukh"
                  post="Event Management Head"
                  contact="9179322789"
                  quote="Surely, not an introvert."
                  instagram="https://www.instagram.com/heyyymanshu/"
                  linkedin="https://www.linkedin.com/in/himanshu-deshmukh-1ab2a922a/"
                  twitter="https://twitter.com/heyyymanshu"
                />
              {/* </Fade> */}
              {/* <Fade left> */}
                <Card
                  path={ankit}
                  name="Ankit Kumar"
                  post="Public Relations Head"
                  contact="9179243009"
                  quote="Find me enjoying sunset with pizza! 🌻🍕"
                  instagram="https://www.instagram.com/____ankitkumar__/"
                  linkedin="https://www.linkedin.com/in/ankit-kumar-564435118/"
                  twitter="https://twitter.com/ankitkumar_128"
                />
                <Card
                  path={ariin}
                  name="Ariin Singh"
                  post="Marketing Head"
                  contact="9179243009"
                  quote="Find me enjoying sunset with pizza! 🌻🍕"
                  instagram="https://www.instagram.com/ariinsingh/"
                  linkedin="https://www.linkedin.com/in/ariin-singh-88841b209"
                  twitter="https://twitter.com/ariinsingh06"
                />
              {/* </Fade> */}
              <Card
                path={ritesh}
                name="Ritesh Thakur"
                post="Technical Head"
                contact="8103445828"
                quote="I am sunset! ⛅"
                instagram="https://www.instagram.com/ritesh._.thakur_/"
                linkedin="https://www.linkedin.com/in/ritesh-kumar-thakur-000209203/"
                twitter="https://twitter.com/RITESHK22115"
              />
              {/* <Fade right> */}
                <Card
                  path={harshit}
                  name="Harshit Soni"
                  post="Content Head"
                  contact="9406108766"
                  quote="You might have read ample of lines till now. Then take a break have some water and GO GET SOME WORK TO DO."
                  instagram="https://www.instagram.com/soniharshit.exe/"
                  linkedin="https://www.linkedin.com/in/harshit-soni-422aa720b/"
                  twitter="#"
                />
              {/* </Fade> */}
              {/* <Card
                  path="Rahul-Sharma.png"
                  name="Rahul Sharma"
                  post="Content Head"
                  contact="9399936844"
                  quote="Kesha tells me my love is her drug"
                  instagram="rahul.sharma0.0"
                  linkedin="https://www.linkedin.com/in/rahul-sharma-363865187"
                /> */}
              {/* <Fade left> */}
                <Card
                  path={aryan}
                  name="Aryan Soni"
                  post="Logistics & Hospitality Head"
                  contact="7646956519"
                  quote="not great at one-liners"
                  instagram="https://www.instagram.com/aryan.__.soni_/"
                  linkedin="https://www.linkedin.com/in/aryan-soni-4129ab20b"
                  twitter="#"
                />
              {/* </Fade> */}
              <Card
                path={anshul}
                name="Anshul Gadpalliwar"
                post="Sponsorship Head"
                contact="8234993336"
                quote="HULT Prize is Back!!!"
                instagram="https://www.instagram.com/gaddi_6263/"
                linkedin="#"
                twitter="#"
              />
              {/* <Fade right> */}
                <Card
                  path={gaurav}
                  name="Gaurav Rathore"
                  post="Camera & Equipment Head"
                  contact=""
                  quote=""
                  instagram="https://www.instagram.com/_gaurav__0/"
                  linkedin="https://www.linkedin.com/in/gaurav-rathore-587b3b238"
                  twitter="#"
                />
              {/* </Fade> */}
              {/* <Fade left> */}
                <Card
                  path={manas}
                  name="Manas Banjare"
                  post="Video Designing Head"
                  contact="7024974968"
                  quote="I'm not great at the advice, can I interest you in a sarcastic comment?"
                  instagram="https://www.instagram.com/m_a_n_a_s_21_1_2/"
                  linkedin="https://www.linkedin.com/in/manas-banjare-a02884245/"
                  twitter="https://twitter.com/banjare_manas"
                />
              {/* </Fade> */}
              {/* <Fade right> */}
                <Card
                  path={rajnish}
                  name="Rajnish Goswami"
                  post="Graphic Designing &nbsp; Head"
                  contact="7617236125"
                  quote="Roads? Where we're going, we don't need roads."
                  instagram="https://www.instagram.com/rxjnish/"
                  linkedin="https://www.linkedin.com/in/rajnish-goswami-881193224"
                  twitter="https://twitter.com/Rajnish_013"
                />
              {/* </Fade> */}
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
          <h2 className="lineTitle">
            <span>
              <strong>Event Management</strong> Team
            </span>
            <img src={Pivot} alt=""/>
          </h2>
          <div className="teamList">
            {/* <Fade left> */}
              <Card
                path={adyali}
                name="Adyali Agrawal"
                contact="7389372555"
                quote="Thinking I should trademark 'I know'!"
                instagram="https://instagram.com/adyali_agrawal"
                linkedin="https://www.linkedin.com/in/adyali-agrawal-811465232"
                twitter="https://twitter.com/AdyaliAgrawal"
              />
            {/* </Fade> */}
            <Card
              path={veeyashu}
              name="Veeyashu Sahu"
              contact=""
              quote="Love is well stocked craft room. 💕"
              instagram="instagram.com/veeyashusahu27"
              linkedin="linkedin.com/in/veeyashu-sahu-347705189"
              twitter="#"
            />
            {/* <Fade right> */}
              <Card
                path={shreya}
                name="Shreya Jirwankar"
                contact="7999528048"
                quote="Literal meaning of my name is love so that's why i can easily say that everyone anurag anurag"
                instagram="https://instagram.com/shreyajirwankar"
                linkedin="https://www.linkedin.com/in/shreya-jirwankar-99bbaa23b"
                twitter="https://twitter.com/Jirwankar07"
              />
            {/* </Fade> */}
            {/* <Card className="card-l-cen"
              path="Gamak.png"
              name="Gamak"
              contact="6267791826"
              quote="'Sugar coated Mirror', when it comes to behaviour.....😉"
              instagram="ringingtorpedo"
              linkedin="https://www.linkedin.com/in/gamak-sahu-9043a2225"
            />
            <Card className="card-r-cen"
              path="Smriti-Pandey.png"
              name="Smriti Pandey"
              contact="7224970876"
              quote="Being a Monica since 2001."
              instagram="_.smriti_pandey_._"
              linkedin="https://www.linkedin.com/in/smriti-pandey-b06930223"
            /> */}
            {/* </Fade> */}
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Public Relations</strong> Team
              </span>
              <img src={Pivot} alt=""/>
            </h2>
            <div className="teamList">
              {/* <Fade left> */}
                <Card
                  path={bhumika}
                  name="Bhumika Sahu"
                  contact="9109192229"
                  quote="It looks like Romedy Now but it is Comedy Central in real life"
                  instagram="https://www.instagram.com/_._bhumi_/"
                  linkedin="https://www.linkedin.com/in/bhumika-sahu-14092b25b/"
                  twitter="https://twitter.com/_bhumi06_"
                />
                {/* </Fade> */}
                {/* <Fade right> */}
                <Card
                  path={sitanshu}
                  name="Sitanshu Lall"
                  contact=""
                  quote="An amalgamation of all things wacky! ¯\_(ツ)_/¯"
                  instagram="https://instagram.com/sitanshu.lall"
                  linkedin="https://www.linkedin.com/in/sitanshu-lall-b289a5228"
                  twitter="https://www.twitter.com/SitanshuLall"
                />
              {/* </Fade> */}
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Marketing</strong> Team
              </span>
              <img src={Pivot} alt=""/>
            </h2>
            <div className="teamList">
              {/* <Fade left> */}
                <Card
                  path={anuoshka}
                  name="Anuoshka Singh"
                  contact="7647079411"
                  quote="Go self-made to become legendary!"
                  instagram="https://www.instagram.com/anuoshkasingh/"
                  linkedin="https://www.linkedin.com/in/anuoshkasingh/"
                  twitter="https://twitter.com/anuoshkasingh"
                />
                {/* </Fade> */}
                <Card
                  path={aryans}
                  name="Aryan Singh"
                  contact="7354797570"
                  quote="Love to interact distinct minds and spreading kindness like confetti😊"
                  instagram="https://www.instagram.com/_arhaan_singh_/"
                  linkedin="https://www.linkedin.com/in/aryan-singh-5a1679248"
                  twitter="#"
                />
                {/* <Fade right> */}
                <Card
                  path={anuj}
                  name="Anuj Gupta"
                  contact="8103497760"
                  quote="If someone calls me imperfect , i m!! , coz this whole universe is made out of imperfection of the matters involved , so as i"
                  instagram="https://instagram.com/anuj18__"
                  linkedin="https://www.linkedin.com/in/anuj-gupta-017576252"
                  twitter="#"
                />
              {/* </Fade> */}
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Technical</strong> Team
              </span>
              <img src={Pivot} alt=""/>
            </h2>
            <div className="teamList">
              {/* <Fade left> */}
                <Card
                  path={devansh}
                  name="Devansh Shrivastava"
                  contact="8349158303"
                  quote="Leave it better than you found it ✨"
                  instagram="https://www.instagram.com/devansh19_official/"
                  linkedin="https://www.linkedin.com/in/devansh-shrivastava-94228a230/"
                  twitter="https://twitter.com/SomewhatDevansh"
                />
              {/* </Fade> */}
              {/* <Fade right> */}
                <Card
                  path={rishabh}
                  name="Rishabh Singh"
                  contact="8349158303"
                  quote="Leave it better than you found it ✨"
                  instagram="https://www.instagram.com/singhrishabh1670/"
                  linkedin="https://www.linkedin.com/singhrishabh1670"
                  twitter="https://www.twitter.com/singhrishabh93"
                />
              {/* </Fade> */}
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Content</strong> Team
              </span>
              <img src={Pivot} alt=""/>
            </h2>
            <div className="teamList">
              {/* <Fade left> */}
                <Card
                  path={lavanya}
                  name="Lavanya Soni"
                  contact="9907981182"
                  quote="Stay humble, Work hard."
                  instagram="https://www.instagram.com/lava_soni/"
                  linkedin="https://www.linkedin.com/in/c-h-lavanya-soni-9b361a255/"
                  twitter="https://twitter.com/LavanyaSoni45"
                />
              {/* </Fade> */}
                {/* <Fade right> */}
                  <Card
                    path={zunaira}
                    name="Zunaira Jasmine"
                    contact="7771065135"
                    quote="Smiling is my favorite exercise😄"
                    instagram="https://www.instagram.com/__zunaira13jasmine__/"
                    linkedin="https://www.linkedin.com/in/zunaira-jasmine-b10445254"
                    twitter="https://twitter.com/zunaira_jasmine"
                  />
                {/* <Card
                  path="Mansi-Bhat.png"
                  name="Mansi Bhat"
                  contact=""
                  quote="I believe in making the impossible possible because there's no fun in giving up......"
                  instagram="mansibhat08"
                  linkedin="https://www.linkedin.com/in/mansi-bhat-076736229"
                /> */}
                {/* </Fade> */}
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Logistics & Hospitality</strong> Team
              </span>
              <img src={Pivot} alt=""/>
            </h2>
            <div className="teamList">
              {/* <Fade left> */}
                <Card
                  path={vaibhav}
                  name="Vaibhav Lanjewar"
                  contact="9399504126"
                  quote="You can't lose hope on your goal, whenever you don't have any option to achieve."
                  instagram="https://instagram.com/vaibhavlanjewar06"
                  linkedin="https://www.linkedin.com/in/vaibhav-lanjewar-3b1320203/"
                  twitter="#"
                />
              {/* </Fade> */}
                <Card
                  path={shourya}
                  name="Shourya Shukla"
                  contact="8817366902"
                  quote="Don't underestimate me, that's my mother's job"
                  instagram="https://instagram.com/_.shourya._.shukla._"
                  linkedin="https://www.linkedin.com/in/shourya-shukla-8807b1254"
                  tywitter="https://twitter.com/SHOURYA90956393"
                />
                {/* <Fade right> */}
                  <Card
                    path={mmoulika}
                    name="M. Moulika"
                    contact="6263330551"
                    quote="Are you sweet or do I need to show you my baking talent?"
                    instagram="https://instagram.com/moulika_04"
                    linkedin="https://www.linkedin.com/in/majji-moulika-80560222a"
                    twitter="https://twitter.com/57_moulika"
                  />
              {/* </Fade> */}
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Sponsorship</strong> Team
              </span>
              <img src={Pivot} alt=""/>
            </h2>
            <div className="teamList">
              {/* <Fade left> */}
                <Card
                  path={shubhra}
                  name="Shubhra Dubey"
                  contact="7773059502"
                  quote="Introvert on the outside, extrovert on the inside!"
                  instagram="https://instagram.com/30_uknowwho"
                  linkedin="https://www.linkedin.com/in/shubhra-dubey-112706255"
                  twitter="https://twitter.com/shubhrad30?t=ReIbPuV4BHoDb"
                />
                {/* </Fade> */}
                <Card
                  path={vikramaditya}
                  name="Vikramaditya Singh"
                  contact="9179197697"
                  quote="I will be sweet as sugar until you start annoying me"
                  instagram="https://instagram.com/_vikramaditya_singh_chauhan_"
                  linkedin="https://www.linkedin.com/in/vikramaditya-singh-chauhan-748482250"
                  twitter="https://twitter.com/Vikramaditya081"
                />
                {/* <Fade right> */}
                <Card
                  path={pratham}
                  name="Pratham Shrivastava"
                  contact="9179197697"
                  quote="I will be sweet as sugar until you start annoying me"
                  instagram="https://instagram.com/prathamm_06"
                  linkedin="https://www.linkedin.com/in/pratham-shrivastava-38953b232"
                  twitter="#"
                />
              {/* </Fade> */}
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Video Designing</strong> Team
              </span>
              <img src={Pivot} alt=""/>
            </h2>
            <div className="teamList">
              {/* <Fade left> */}
                <Card
                  path={shreyansh}
                  name="Shreyansh Rajput"
                  contact="8698682498"
                  quote="I like to record THINGS"
                  instagram="https://www.instagram.com/shreyanshrajput.7/"
                  linkedin="https://www.linkedin.com/in/shreyansh-rajput-722705255"
                  twitter="https://twitter.com/72_shreyansh"
                />
                {/* </Fade> */}
                {/* <Fade right> */}
                <Card
                  path={bhrigesh}
                  name="Bhrigesh Deshmukh"
                  contact="7903531361"
                  quote="If you like basketball then I like you 💓💓"
                  instagram="https://www.instagram.com/bhrigeshd/"
                  linkedin="https://www.linkedin.com/in/bhrigesh-deshmukh-739034213/"
                  twitter="#"
                />
              {/* </Fade> */}
            </div>
          </Fade>
          <div className="mt-5"></div>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Graphic Designing</strong> Team
              </span>
              <img src={Pivot} alt=""/>
            </h2>
            <div className="teamList">
              {/* <Fade left> */}
                <Card
                  path={abhay}
                  name="Abhay Patel"
                  contact="8319401611"
                  quote="Music is my language."
                  instagram="https://instagram.com/itzabhayp"
                  linkedin="https://www.linkedin.com/in/abhay-patel-0a5269229"
                  twitter="https://twitter.com/itzabhayp"
                />
                {/* </Fade> */}
                <Card
                  path={atul}
                  name="Atul Pandey"
                  contact="9755859848"
                  quote="Bringing the art to the cart."
                  instagram="https://www.instagram.com/heyimatull/"
                  linkedin="https://www.linkedin.com/in/atul-pandey-54077a214"
                  twitter="https://twitter.com/heyimatul"
                />
                {/* <Fade right> */}
                <Card
                  path={aaditya}
                  name="Aaditya Shukla"
                  contact="7587114412"
                  quote="No, I Don't Tweet, I Quack! "
                  instagram="#"
                  linkedin="#"
                  twitter="#"
                />
              {/* </Fade> */}
            </div>
          </Fade>
          <Fade up>
            <h2 className="lineTitle">
              <span>
                <strong>Camera and Equipment</strong> Team
              </span>
              <img src={Pivot} alt=""/>
            </h2>
            <div className="teamList">
              {/* <Fade left> */}
                <Card
                  path={kunal}
                  name="Kunal Sahu"
                  contact="8319401611"
                  quote="Music is my language."
                  instagram="https://www.instagram.com/kunal_sahu0723/"
                  linkedin="https://www.linkedin.com/in/kunal-kumar-sahu-64ab01233"
                  twitter="#"
                />
                {/* </Fade> */}
                
                {/* <Fade right> */}
                <Card
                  path={abhishek}
                  name="Abhishek Neogi"
                  contact="7587114412"
                  quote="No, I Don't Tweet, I Quack! "
                  instagram="https://www.instagram.com/__.abhishek.__n/"
                  linkedin="https://www.linkedin.com/in/abhishek-neogi-472783228/"
                  twitter="https://twitter.com/AbiskNeogi"
                />
              {/* </Fade> */}
            </div>
          </Fade>
        </div>
      </React.Fragment>
    );
  }

export default Team;
