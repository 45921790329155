import React, { useEffect, useState } from "react";
import PageCover from "../../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import { db } from "../../../firebase";
import Moment from "moment";
import { Alert, ButtonToggle } from "reactstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const sponsorNominationsListComponent = () => {
  const [application, setApplication] = useState([]);

  useEffect(() => {
    db.collection("speakernominations23")
      .orderBy("createdAt", "desc")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setApplication(data);
      });
  }, []);

  let counter = 0;

  return (
    <React.Fragment>
      <PageCover title="Speaker Nominations" />
      <div className="registrationsListCont">
        <table id="bookingsExcelTable" className="table d-none">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Full Name</th>
              <th>Mobile No.</th>
              <th>Email-ID</th>
              <th>Currently working at</th>
              <th>Social media links</th>
              <th>TED Talk inspirations</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {application?.map((data, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td>{data.fullName}</td>
                  <td>{data.mobileNo}</td>
                  <td>{data.email}</td>
                  <td>{data.workingAt}</td>
                  <td>{data.links}</td>
                  <td>{data.inspirations}</td>
                  <td>{data.description}</td>
                  <td>
                    {Moment(data.createdAt?.toDate().toString()).format("lll")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={{ margin: "0 0 3em 15px" }}>
          <ReactHTMLTableToExcel
            className="btn btn-info"
            table="bookingsExcelTable"
            filename="TEDxBITD 2022 Speaker Nominations"
            sheet="Sheet"
            buttonText="Export List as Spreadsheet"
          />
        </div>
        <div className="blogs">
          {application?.map((data, i) => {
            counter++;
            return (
              <Fade up key={i}>
                <div className="singleBlog">
                  <div className="left">
                    <div className="title">{i + 1 + ". " + data.fullName}</div>
                    <div className="date">
                      <b>Mobile No.:&nbsp;&nbsp;</b>
                      {data.mobileNo}
                    </div>
                    <div className="date">
                      <b>Email-ID:&nbsp;&nbsp;</b>
                      {data.email}
                    </div>
                    <div className="date">
                      <b>Currently working at:&nbsp;&nbsp;</b>
                      {data.workingAt}
                    </div>
                    <div className="date">
                      <b>Social media links:&nbsp;&nbsp;</b>
                      {data.links}
                    </div>
                    <div className="date">
                      <b>TED Talk Inspirations:&nbsp;&nbsp;</b>
                      {data.inspirations}
                    </div>
                    <div className="date">
                      <b>Description:&nbsp;&nbsp;</b>
                      {data.description}
                    </div>
                    <div className="date">
                      <b>Nominated at:&nbsp;&nbsp;</b>
                      {Moment(data.createdAt?.toDate().toString()).format(
                        "lll"
                      )}
                    </div>
                  </div>
                  {/* <div className="right">
                    <a
                      href={}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button type="button">Visit Website</button>
                    </a>
                  </div> */}
                </div>
              </Fade>
            );
          })}
        </div>
        {counter === 0 ? (
          <Fade up>
            <Alert
              color="danger"
              style={{ textAlign: "center", padding: "1.5em 0" }}
            >
              Oops! Looks like there are no registrations at the moment!
              <br />
              <br />
              <a href="/dashboard">
                <ButtonToggle color="danger">Go Back</ButtonToggle>
              </a>
            </Alert>
          </Fade>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default sponsorNominationsListComponent;
