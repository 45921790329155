import React, { useEffect, useState } from "react";
import "./CampusAmbassadorComponent.css";
import PageCover from "../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import emailjs from "emailjs-com";
import AlertModal from "../AlertModalComponent/AlertModalComponent";
import { db } from "../../firebase";
import { Helmet } from "react-helmet";
import { Link, Element } from "react-scroll";

const contact = () => {
  const [name, setName] = useState("");
  const [year, setYear] = useState("");
  const [collegename, setCollegename] = useState("");
  const [email, setEmail] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [collegestrength, setCollegestrength] = useState("");

  const [loader, setLoader] = useState(false);

  const [modal, showModal] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    db.collection("campus ambassador")
      .add({
        name: name,
        year: year,
        collegename: collegename,
        email: email,
        phoneno: phoneno,
        collegestrength: collegestrength,
        createdAt: new Date(),
      })
      .then(() => {
        setLoader(false);
        showModal(
          <AlertModal
            message="Your Response has been submitted. Thank you for your registering!"
            icon="successful.png"
            reload="true"
            close={closeModal}
          />
        );
      })
      .catch((error) => {
        showModal(
          <AlertModal
            message={error.message}
            icon="failed.png"
            reload="true"
            close={closeModal}
          />
        );
        setLoader(false);
      });

    emailjs
      .sendForm(
        "service_n6fo218", // Service Id
        "template_u7lxykn", // Template Id
        e.target,
        "user_j147cM4xSAMaZzHRF7Kqm" // User Id
      )
      .then(
        (result) => {
          // console.log(result.text);
          setLoader(false);
        },
        (error) => {
          // console.log(error.text);
          setLoader(false);
        }
      );
    e.target.reset();
  };

  const closeModal = () => {
    showModal("");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>TEDxBITD - Campus Ambassador Registration</title>
        <meta
          name="description"
          content="TEDxBITD has a great support team to answer all your questions related to the event. Feel free to drop by and we will get back to you at the earliest."
        />
        <meta
          name="keywords"
          content="ted,tedx,bit,durg,speakers,bitd,event,campus,college,bhilai"
        />
      </Helmet>
      <PageCover title="Campus Ambassador" />
      {modal}
      <div className="ambassadorCont">
        <div className="contactForm">
          <Fade up>
            <div className="leftSide">
              <img
                src="./assets/images/campus-ambassador/banner.png"
                alt="Campus Ambassador Banner"
              />
            </div>
            <Element name="contactus">
              <form onSubmit={handleSubmit}>
                <Fade up>
                  <div className="heading">Register as Campus Ambassador</div>
                  <div className="inputGroup">
                    <input
                      type="text"
                      name="name"
                      placeholder="Name*"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="inputGroup">
                    <input
                      type="number"
                      name="year"
                      placeholder="Year*"
                      onChange={(e) => setYear(e.target.value)}
                      required
                    />
                  </div>
                  <div className="inputGroup">
                    <input
                      type="text"
                      name="collegenamename"
                      placeholder="College Name*"
                      onChange={(e) => setCollegename(e.target.value)}
                      required
                    />
                  </div>
                  <div className="inputGroup">
                    <input
                      type="email"
                      name="email"
                      placeholder="E-mail*"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="inputGroup">
                    <input
                      type="number"
                      name="phoneno"
                      placeholder="Phone No.*"
                      onChange={(e) => setPhoneno(e.target.value)}
                      required
                    />
                  </div>
                  <div className="inputGroup">
                    <input
                      type="number"
                      name="collegestrength"
                      placeholder="Total students in your college*"
                      onChange={(e) => setCollegestrength(e.target.value)}
                      required
                    />
                  </div>
                  <div className="inputGroup">
                    <button type="submit">
                      Submit&nbsp;&nbsp;<i className="fas fa-arrow-right"></i>
                    </button>
                  </div>
                  <div className="socialMedia">
                    <a
                      href="https://instagram.com/tedxbitd"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a
                      href="https://twitter.com/TEDxBITD"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a
                      href="https://facebook.com/TEDxBITD"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook"></i>
                    </a>
                    <a
                      href="https://linkedin.com/company/tedxbitd"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </div>
                </Fade>
              </form>
            </Element>
          </Fade>
        </div>
      </div>
    </React.Fragment>
  );
};

export default contact;
