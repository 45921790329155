import React from "react";
import "./SpeakersComponent.css";
import "./SpeakerComponent2023.css";
import PageCover from "../HeaderComponents/PageCover";
import SingleSpeaker from "./SingleSpeakerComponent/SingleSpeakerComponent";
import { Fade } from "react-reveal";
import ComingSoon from "../ComingSoonComponent/ComingSoonComponent";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { db, storage } from "../../firebase";
import { Progress } from "reactstrap";
import AlertModal from "../AlertModalComponent/AlertModalComponent";
import SingleSpeaker2023 from "./SingleSpeaker2023";
import SpeakerPopup from "./SpeakerPopup/SpeakerPopup";

import adarsh from "../../img/speaker2023/adarsh.png";
import ayushi from "../../img/speaker2023/ayushi.jpeg";
import fraz from "../../img/speaker2023/fraz.png";
import jeet from "../../img/speaker2023/jeet.png";
import priya from "../../img/speaker2023/priya.png";
import tapesh from "../../img/speaker2023/tapesh.png";

const speakers = () => {
  const speakerDetails = [
    {
      name: "Adarsh Mishra",
      id: 0,
      desig: "Food Vlogger",
      desc: "Food vlogger with 1M+ subscribers on YouTube and 155K+ followers on Instagram. The path and experience of going from being a regular food lover to being an influencer and proprietor of a food page called 'Raipur Foodie Love' that promotes a healthy living. Join him at TEDxBITD.",
      imgURL: adarsh,
    },
    {
      name: "Major Priya Jhingan",
      id: 1,
      desig: "Indian Army",
      desc: "Priya Jhingam is the first female cadet in the Indian army and Silver medallist (1993). Honored by India's President, Shri Ram Nath Kovind, in February 2018 for being a pioneer of women in the Indian Army, with 112 other distinguished women in other disciplines. She is a Mountaineer as well. Get to know her thrilling journey at TEDxBITD.",
      imgURL: priya,
    },
    {
      name: "Jeet Singh Arya",
      id: 2,
      desig: "Founder @ Unexplored Bastar",
      desc: "Embark on an extraordinary expedition with a visionary researcher turned explorer. Unveil the hidden wonders of Bastar, guided by a National Entrepreneurship Award Winner, TEDx speaker, and History Channel featured Visionary. Dive into history, art, culture, and more through Unexplored Bastar with TEDxBITD.",
      imgURL: jeet,
    },
    {
      name: "Tapesh Kumar",
      id: 3,
      desig: "Youngest Airline Captain",
      desc: "Board on an awe-inspiring journey with Tapesh Kumar, the Boeing Boy who soared from a boyhood dream to become the world's youngest airline Captain at 25. With 13 years of flying experience on B737 and A320, his story of passion, mentoring, and balanced leadership awaits you at TEDxBITD. Join the flight of inspiration!",
      imgURL: tapesh,
    },
    {
      name: "Mohhamad Fraz",
      id: 4,
      desig: "SDE @ Google",
      desc: "Dive into the remarkable journey of Mohammed Fraz, a trailblazing SDE at @Google and a renowned YouTuber with 328K+ subscribers. Uncover the wisdom gained from roles at @Cure.fit, @Codechef, @Unacademy.",
      imgURL: fraz,
    },
    {
      name: "Ayushi Shekhawat",
      id: 5,
      desig: "Sports Anchor",
      desc: "A youthful and brilliant television personality awaits you at TEDxBITD. Ayushi Shekhawat is well-known for her work as a News Anchor and Journalist Sports Presenter @starsportsindia. Is a Social Media Influencer and Content Creator. ",
      imgURL: ayushi,
    },
  ];
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [occupation, setOccupation] = useState("");
  const [about, setAbout] = useState("");

  const [loading, setLoading] = useState(null);
  const [file, setFile] = useState(null);
  const [url, setURL] = useState("");

  const [modal, showModal] = useState("");

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [desig, setDesig] = useState("");
  const [desc, setDesc] = useState("");
  const [imgURL, setImgURL] = useState("");

  const types = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/doc",
    "application/msword",
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (e) => {
    const iD = e.target.id;
    console.log(iD);
    console.log(speakerDetails[iD].name);
    setName(speakerDetails[iD].name);
    setDesig(speakerDetails[iD].desig);
    setDesc(speakerDetails[iD].desc);
    setImgURL(speakerDetails[iD].imgURL);
    setOpen(true);
  };

  function handleChange(e) {
    const fileUpload = e.target.files[0];
    if (fileUpload && types.includes(fileUpload.type)) {
      setFile(e.target.files[0]);
    } else {
      setFile(null);
    }
  }

  const onSubmit = async (event) => {
    if ((fullname && contactNo && email && occupation) !== "") {
      event.preventDefault();

      const application = {
        // url: url,
        // filename : file.name,
        fullname: fullname,
        contactNo: contactNo,
        email: email,
        contactNo: contactNo,
        occupation: occupation,
        about: about,
        date: new Date(),
      };

      // If submittion successful without file
      if (!file) {
        db.collection("Speakers")
          .add(application)
          .then(() => {
            setURL(""),
              setFile(null),
              setFullname(""),
              setContactNo(""),
              setEmail(""),
              setOccupation(""),
              setAbout(""),
              setLoading(null);
            showModal(
              <AlertModal
                message="Speaker has been nominated successfully!"
                icon="successful.png"
                reload="true"
                close={closeModal}
              />
            );
          });
      }
      // If submittion successful with file
      else {
        setLoading(
          <div className="inputGroup progressBar">
            <Progress animated color="info" value={100}>
              Uploading...
            </Progress>
          </div>
        );
        await storage.ref(`/speakers/${file.name}`).put(file);

        storage
          .ref("speakers")
          .child(file.name)
          .getDownloadURL()
          .then((url) => {
            db.collection("Speakers")
              .add(
                application,
                (application.url = url),
                (application.filename = file.name)
              )
              .then(() => {
                setURL(""),
                  setFile(null),
                  setFullname(""),
                  setContactNo(""),
                  setEmail(""),
                  setOccupation(""),
                  setAbout(""),
                  setLoading(null);
                showModal(
                  <AlertModal
                    message="Speaker has been nominated successfully!"
                    icon="successful.png"
                    reload="true"
                    close={closeModal}
                  />
                );
              });
          });
      }
    } else {
      // If form submission failled
      showModal(
        <AlertModal
          message="Speaker could not be nominated! Please try again."
          icon="failed.png"
          close={closeModal}
        />
      );
    }
  };

  const closeModal = () => {
    showModal("");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>TEDxBITD - Speakers 2023</title>
        <meta
          name="description"
          content="Our speakers are the inspiration centers of TEDxBITD. We aim to bring speakers with inspirational stories and life-changing ideas worth spreading, to encourage great and meaningful conversations."
        />
        <meta
          name="keywords"
          content="ted,tedx,bit,durg,speakers,bitd,event,campus,college,bhilai"
        />
      </Helmet>
      <div>
        <PageCover title="Speakers 2023" />
        <div
          className={
            open ? " speakerPopupContainer open" : "speakerPopupContainer"
          }
        >
          <SpeakerPopup
            imgURL={imgURL}
            speakerName={name}
            desc={desc}
            desig={desig}
            handleClose={handleClose}
          />
          <div className="speakerPageCover">.</div>
        </div>
        <Fade up>
          <div className={"speakersList2023"}>
            <div id="Speaker1">
              <SingleSpeaker2023
                id={0}
                imgURL={adarsh}
                speakerInstagram="https://www.instagram.com/raipurfoodielove/"
                speakerFacebook="https://www.facebook.com/indiafoodielove/x"
                speakerYT="https://www.youtube.com/@RaipurFoodieLove"
                name="Adarsh Mishra"
                designation="Food Vlogger"
                handleOpen={handleOpen}
              />
            </div>
            <SingleSpeaker2023
              id={1}
              imgURL={priya}
              speakerInstagram="https://www.instagram.com/majorpriya/"
              speakerFacebook="https://www.facebook.com/majorpriya/"
              website="https://www.majorpriyajhingan.com/"
              name="Major Priya Jhingan"
              designation="Indian Army"
              handleOpen={handleOpen}
            />
            <SingleSpeaker2023
              id={2}
              imgURL={jeet}
              speakerTwitter="https://twitter.com/jeetsingharya"
              speakerInstagram="https://www.instagram.com/unexploredbastar/"
              linkedIn="https://www.linkedin.com/in/jeetsingharya/"
              name="Jeet Singh Arya"
              designation="Founder @ Unexplored Bastar"
              handleOpen={handleOpen}
            />
            <SingleSpeaker2023
              id={3}
              imgURL={tapesh}
              speakerTwitte="https://twitter.com/boeingboytapshi"
              speakerInstagram="https://www.instagram.com/tapshi/"
              speakerTwitter="https://twitter.com/boeingboytapshi"
              speakerYT="https://www.youtube.com/@BoeingBoy"
              name="Tapesh Kumar"
              designation="Youngest Airline Captain"
              handleOpen={handleOpen}
            />
            <SingleSpeaker2023
              id={4}
              imgURL={fraz}
              speakerInstagram="https://www.instagram.com/frazmohammad/"
              linkedIn="https://www.linkedin.com/in/mohammad-f-bb4886157/"
              speakerYT="https://www.youtube.com/@FrazMohammad"
              name="Mohhamad Fraz"
              designation="SDE @ Google"
              handleOpen={handleOpen}
            />
            <SingleSpeaker2023
              id={5}
              imgURL={ayushi}
              speakerTwitter="https://twitter.com/ShekhawatAyushi"
              speakerFacebook="https://www.facebook.com/ayushishekhawatofficial/"
              speakerInstagram="https://www.instagram.com/ayushishekhawat_official/"
              name="Ayushi Shekhawat"
              designation="Sports Anchor"
              handleOpen={handleOpen}
            />
          </div>
        </Fade>
      </div>
    </React.Fragment>
  );
};

export default speakers;
