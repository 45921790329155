import React from 'react'
import ShirtSize from "../../img/shirtSize.jpeg"

const SizeChartBox = (props) => {
  return (
    <div className="shirtSizeBox">
        <img src={ShirtSize} alt="" />
        <div onClick={props.handleCloseClick} className='iButtonComp-Close'>x</div>
      </div>
  )
}

export default SizeChartBox