import React from "react";
import "./BookingComponent.css";

const ticketStatus = () => {
  return (
    <React.Fragment>
      <div className="bookingCont">
        <div className="header height-100">
          <div className="content">
            <h2 className="title">Thank you for your order!</h2>
            <div className="details">
              <div className="single-detail">
                <i className="fas fa-envelope"></i>Check your mail for the
                receipt. Event passes will be available on the spot.
              </div>
              <div className="single-detail">
                <i className="far fa-calendar-alt"></i>19th of August, 2023
              </div>
              <div className="single-detail">
                <i className="far fa-clock"></i>10:00 AM - 5:00 PM
              </div>
            </div>
            <a href="/contact">Contact Us</a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ticketStatus;
