import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./SingleBlogComponent.css";
import { db } from "../../../firebase";
import Moment from "moment";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const singleBlog = (props) => {
  const [blog, setblogs] = useState([]);

  const history = useHistory();

  const ref = db.collection("Blogs").doc(props.match.params.id);
  ref.get().then((doc) => {
    if (doc.exists) {
      const Blog = doc.data();
      setblogs({
        id: doc.id,
        blogtitle: Blog.blogtitle,
        blogauthor: Blog.blogauthor,
        blogdate: Blog.blogdate,
        blogimage: Blog.blogimageurl,
        blogcontent: Blog.blogcontent,
      });
    } else {
      history.push("/blog");
    }
  });

  const [Otherblog, setOhterblogs] = useState([]);
  useEffect(() => {
    const fetchdata = async () => {
      db.collection("Blogs").onSnapshot(function (data) {
        setOhterblogs(
          data.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        );
      });
    };
    fetchdata();
  }, []);

  // const shareUrl = "https://tedxbitd.in/singleblog/" + props.match.params.id;
  const shareUrl = "https://tedxbitd.com/singleblog/" + props.match.params.id; // New Url
  const shareText =
    "TEDxBITD Blog by " + blog.blogauthor + ' on "' + blog.blogtitle + '"';

  return (
    <React.Fragment>
      <div className="singleBlogCont">
        <div className="blogHeader">
          <div className="blogDetails">
            <h1 className="blogTitle">{blog.blogtitle}</h1>
            <h5 className="blogAuthor">by {blog.blogauthor}</h5>
            <h6 className="blogDate">
              Posted on {Moment(blog.blogdate).format("ll")}
            </h6>
          </div>
          <div className="blogImage">
            <img
              src={
                "https://drive.google.com/uc?export=view&id=" + blog.blogimage
              }
              alt="Blog Image"
            />
          </div>
        </div>
        <div className="blogMain">
          <div className="blogLeft">
            <div
              dangerouslySetInnerHTML={{
                __html: blog.blogcontent,
              }}
              className="blogContent"
            ></div>
            <div className="blogShare">
              <div className="shareButtons">
                <h6>Share on:</h6>
                <WhatsappShareButton url={shareUrl} title={shareText}>
                  <WhatsappIcon size="32" round={true} />
                </WhatsappShareButton>
                <FacebookShareButton url={shareUrl} quote={shareText}>
                  <FacebookIcon size="32" round={true} />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={shareText}>
                  <TwitterIcon size="32" round={true} />
                </TwitterShareButton>
                <LinkedinShareButton url={shareUrl} title={shareText}>
                  <LinkedinIcon size="32" round={true} />
                </LinkedinShareButton>
                <TelegramShareButton url={shareUrl} title={shareText}>
                  <TelegramIcon size="32" round={true} />
                </TelegramShareButton>
              </div>
            </div>
          </div>
          <div className="blogRight">
            <div className="blogList">
              <h3>Other Blogs</h3>
              {Otherblog.map((Blogs) => {
                return Blogs.id !== props.match.params.id ? (
                  <a
                    href={`/singleblog/${Blogs.id}`}
                    className="singleBlogList"
                  >
                    <div className="singleBlogListImage">
                      <img
                        src={
                          "https://drive.google.com/uc?export=view&id=" +
                          Blogs.blogimageurl
                        }
                        alt="Blog Image"
                      />
                    </div>
                    <div className="singleBlogListContent">
                      <div className="blogTitle">{Blogs.blogtitle}</div>
                      <div className="blogDate">
                        Posted on {Moment(Blogs.blogdate).format("ll")}
                      </div>
                    </div>
                  </a>
                ) : null;
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default singleBlog;
