import React, { useEffect, useState } from "react";
import PageCover from "../../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import emailjs from "emailjs-com";
import AlertModal from "../../AlertModalComponent/AlertModalComponent";
import { db } from "../../../firebase";
import { Helmet } from "react-helmet";

const speakerNominations = () => {
  const [fullName, setFullName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [workingAt, setWorkingAt] = useState("");
  const [links, setLinks] = useState("");
  const [inspirations, setInspirations] = useState("");
  const [description, setDescription] = useState("");

  const [loader, setLoader] = useState(false);
  const [modal, showModal] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    db.collection("speakernominations23")
      .add({
        fullName: fullName,
        mobileNo: mobileNo,
        email: email,
        workingAt: workingAt,
        links: links,
        inspirations: inspirations,
        description: description,
        createdAt: new Date(),
      })
      .then(() => {
        setLoader(false);
        showModal(
          <AlertModal
            message="Your Nomination has been submitted. Thank you for your interest, we'll get in touch with you soon."
            icon="successful.png"
            reload="true"
            close={closeModal}
          />
        );
      })
      .catch((error) => {
        showModal(
          <AlertModal
            message={error.message}
            icon="failed.png"
            reload="true"
            close={closeModal}
          />
        );
        setLoader(false);
      });

    emailjs
      .sendForm(
        "service_n6fo218", // Service Id
        "template_3386ijh", // Template Id
        e.target,
        "user_j147cM4xSAMaZzHRF7Kqm" // User Id
      )
      .then(
        (result) => {
          // console.log(result.text);
          setLoader(false);
        },
        (error) => {
          // console.log(error.text);
          setLoader(false);
        }
      );
    e.target.reset();
  };

  const closeModal = () => {
    showModal("");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>TEDxBITD - Speaker Nominations</title>
        <meta
          name="description"
          content="TEDxBITD has a great support team to answer all your questions related to the event. Feel free to drop by and we will get back to you at the earliest."
        />
        <meta
          name="keywords"
          content="ted,tedx,bit,durg,speakers,bitd,event,campus,college,bhilai"
        />
      </Helmet>
      <PageCover title="Speaker Nominations" />
      {modal}
      <div className="submitCont">
        {/* <h2 className="heading text-center">
          Oops! We're sorry, but the Speaker Nominations for TEDxBITD 2022 have
          been closed. Until next time!
        </h2> */}
        <Fade up>
          <div className="instructions">
            <Fade up>
              <h3>Read the Instructions before submitting:</h3>
              <ol>
              <li>
                  You can download the sponsorship brochure from here-{" "}
                  <a
                    href="./assets/docs/TEDxBITD -  Event Booklet.pdf"
                    download
                  >
                    Download Brochure
                  </a>
                  .
              </li>
                <li>
                  All the fields marked with an <strong>*</strong> are mandatory
                  to fill.
                </li>
              </ol>
            </Fade>
          </div>
          <form onSubmit={handleSubmit}>
            <h2 className="lineTitle">
              <span>
                <strong>Personal</strong> Information
              </span>
            </h2>
            <div className="formRow twoInputs">
              <div className="inputGroup">
                <label htmlFor="fullName">Full Name:*</label>
                <input
                  type="text"
                  name="fullName"
                  id="fullName"
                  placeholder="Full Name*"
                  required
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="mobileNo">Mobile No.:*</label>
                <input
                  type="text"
                  name="mobileNo"
                  id="mobileNo"
                  placeholder="Mobile No.*"
                  required
                  onChange={(e) => setMobileNo(e.target.value)}
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="email">Email-ID:*</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email-ID*"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="workingAt">Current Occupation:</label>
                <input
                  type="text"
                  name="workingAt"
                  id="workingAt"
                  placeholder="Current Occupation*"
                  required
                  onChange={(e) => setWorkingAt(e.target.value)}
                />
              </div>
            </div>
            <div className="formRow twoInputs">
              <div className="inputGroup">
                <label htmlFor="links">Social Media Links:</label>
                <textarea
                  name="links"
                  id="links"
                  rows="5"
                  placeholder="Paste links..."
                  onChange={(e) => setLinks(e.target.value)}
                ></textarea>
              </div>
              <div className="inputGroup">
                <label htmlFor="inspirations">
                  TED Talk Inspirations, Your talk structure:*
                </label>
                <textarea
                  name="inspirations"
                  id="inspirations"
                  rows="5"
                  placeholder="Type your answer..."
                  required
                  onChange={(e) => setInspirations(e.target.value)}
                ></textarea>
              </div>
              <div className="inputGroup">
                <label htmlFor="description">
                  Describe yourself and your work briefly:*
                </label>
                <textarea
                  name="description"
                  id="description"
                  rows="5"
                  placeholder="Type your answer..."
                  required
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            {loader}
            <div className="formRow singleInput">
              <div className="inputGroup">
                <button type="submit">SUBMIT NOMINATION</button>
              </div>
            </div>
          </form>
        </Fade>
      </div>
    </React.Fragment>
  );
};

export default speakerNominations;
