import React, { useState } from "react";
import "./AddBlogComponent.css";
import PageCover from "../../HeaderComponents/PageCover";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/tooltip";
import "bootstrap/js/dist/dropdown";
import "bootstrap/dist/css/bootstrap.css";
import { db } from "../../../firebase";
import AlertModal from "../../AlertModalComponent/AlertModalComponent";

const addBlog = () => {
  const [blogtitle, setTitle] = useState("");
  const title = (e) => {
    setTitle(e.target.value);
  };

  const [blogauthor, setAuthor] = useState("");
  const author = (e) => {
    setAuthor(e.target.value);
  };

  const [blogimageurl, setImageUrl] = useState("");
  const imageurl = (e) => {
    setImageUrl(e.target.value);
  };

  const [blogAboutAuthor, setAboutAuthor] = useState("");
  const aboutAuthor = (e) => {
    setAboutAuthor(e.target.value);
  };

  const [blogcontent, setContent] = useState("");
  const content = (param) => {
    setContent(param);
  };

  const [modal, showModal] = useState("");

  const blogdate = new Date().toLocaleDateString();

  const submitblog = (e) => {
    if (
      blogtitle !== "" &&
      blogauthor !== "" &&
      blogcontent !== "" &&
      blogimageurl !== ""
    ) {
      e.preventDefault();
      db.collection("Blogs")
        .add({
          blogtitle: blogtitle,
          blogauthor: blogauthor,
          blogimageurl: blogimageurl,
          blogaboutauthor: blogAboutAuthor,
          blogdate: blogdate,
          blogcontent: blogcontent,
        })
        .then(() => {
          showModal(
            <AlertModal
              message="Blog has been posted successfully!"
              icon="successful.png"
              reload="true"
              close={closeModal}
            />
          );
        })
        .catch((error) => {
          showModal(
            <AlertModal
              message={error.message}
              icon="failed.png"
              close={closeModal}
            />
          );
        });
    } else {
      showModal(
        <AlertModal
          message="Please fill in all the details!"
          icon="failed.png"
          close={closeModal}
        />
      );
    }
  };

  const closeModal = () => {
    showModal("");
  };

  return (
    <React.Fragment>
      <PageCover title="Add Blog" />
      {modal}
      <div className="addBlogCont">
        <form action="#">
          <div className="heading">
            Make sure you post a realllyyy Good One!
          </div>
          <div className="inputRow">
            <div className="inputGroup">
              <input
                type="text"
                name="title"
                id="title"
                placeholder="Blog Title"
                onChange={title}
              />
            </div>
            <div className="inputGroup">
              <input
                type="text"
                name="author"
                id="author"
                placeholder="Blog Author"
                onChange={author}
              />
            </div>
            <div className="inputGroup">
              <input
                type="text"
                name="image"
                id="image"
                placeholder="Blog Image Drive ID(16 : 9)"
                onChange={imageurl}
              />
            </div>
            <div className="inputGroup">
              <input
                type="text"
                name="about"
                id="about"
                placeholder="About the author"
                onChange={aboutAuthor}
              />
            </div>
          </div>
          <div className="summernote">
            <ReactSummernote
              options={{
                lang: "en-US",
                height: 500,
                dialogsInBody: true,
                toolbar: [
                  ["font", ["bold", "underline"]],
                  ["para", ["paragraph"]],
                  ["insert", ["link"]],
                ],
              }}
              onChange={content}
            />
          </div>
          <div className="inputRow single">
            <div className="inputGroup w50p">
              <button type="submit" onClick={submitblog}>
                Post Blog&nbsp;&nbsp;<i className="fas fa-upload"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default addBlog;
