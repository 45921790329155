import React from 'react'

import "./BookingComponent.css"
import "./BookingCard.css"

function BookingCard() {
  return (
    <div>
      
     
    </div>
  )
}

export default BookingCard
