import React, { useState } from "react";
import "./AddMoodBoard.css";
import PageCover from "../../HeaderComponents/PageCover";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/tooltip";
import "bootstrap/js/dist/dropdown";
import "bootstrap/dist/css/bootstrap.css";
import AlertModal from "../../AlertModalComponent/AlertModalComponent";
import { db } from "../../../firebase";

const AddMoodBoard = () => {
  const [moodtitle, setMoodTitle] = useState("");
  const title = (e) => {
    setMoodTitle(e.target.value);
  };

  const [moodauthor, setMoodAuthor] = useState("");
  const author = (e) => {
    setMoodAuthor(e.target.value);
  };

  const [moodimageurl, setMoodImageUrl] = useState("");
  const imageurl = (e) => {
    setMoodImageUrl(e.target.value);
  };

  const [modal, showModal] = useState("");

  const mooddate = new Date().toLocaleDateString();

  const submitMoodBoard = (e) => {
    if (moodtitle !== "" && moodauthor !== "" && moodimageurl !== "") {
      e.preventDefault();
      db.collection("addedMoodBoards")
        .add({
          moodtitle: moodtitle,
          moodauthor: moodauthor,
          moodimageurl: moodimageurl,
          createdAt: mooddate,
        })
        .then(() => {
          showModal(
            <AlertModal
              message="Your MoodBoard has been posted successfully!"
              icon="successful.png"
              reload="true"
              close={closeModal}
            />
          );
        })
        .catch((error) => {
          showModal(
            <AlertModal
              message={error.message}
              icon="failed.png"
              close={closeModal}
            />
          );
        });
    } else {
      showModal(
        <AlertModal
          message="Please fill in all the details!"
          icon="failed.png"
          close={closeModal}
        />
      );
    }
  };

  const closeModal = () => {
    showModal("");
  };

  return (
    <React.Fragment>
      <PageCover title="Submit Mood-Board" />
      {modal}
      <div className="addMoodBoardCont">
        <form action="#">
          <div className="heading">
            <h2>let the world know Your mood !! 😋</h2>
          </div>
          <div className="inputRow">
            <div className="inputGroup">
              <input
                type="text"
                name="title"
                id="title"
                placeholder="Mood Title"
                onChange={title}
              />
            </div>
            <div className="inputGroup">
              <input
                type="text"
                name="author"
                id="author"
                placeholder="Mood Author"
                onChange={author}
              />
            </div>
            <div className="inputGroup">
              <input
                type="text"
                name="image"
                id="image"
                placeholder="Mood Image"
                onChange={imageurl}
              />
            </div>
          </div>
          <div className="inputRow single">
            <div className="inputGroup w50p">
              <button type="submit" onClick={submitMoodBoard}>
                Submit Mood Board &nbsp;&nbsp;<i className="fas fa-dove"></i>
                &nbsp;&nbsp;
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddMoodBoard;
