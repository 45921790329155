import React, { useEffect, useState } from "react";
import "./CampusAmbassadorComponent.css";
import PageCover from "../../HeaderComponents/PageCover";
import { Fade } from "react-reveal";
import { db } from "../../../firebase";
import Moment from "moment";
import { Alert, ButtonToggle } from "reactstrap";

const openMicListComponent = () => {
  const [application, setApplication] = useState([]);

  useEffect(() => {
    db.collection("campus ambassador")
      .orderBy("createdAt", "asc")
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        setApplication(data);
      });
  }, []);

  let counter = 0;

  return (
    <React.Fragment>
      <PageCover title="CA Registrations" />
      <div className="caListCont">
        <div className="blogs">
          {application?.map((data, i) => {
            counter++;
            return (
              <Fade up key={i}>
                <div className="singleBlog">
                  <div className="left">
                    <div className="title">{data.name}</div>
                    <div className="date">
                      <b>Email:&nbsp;&nbsp;</b>
                      {data.email}
                    </div>
                    <div className="date">
                      <b>Phone No.:&nbsp;&nbsp;</b>
                      {data.phoneno}
                    </div>
                    <div className="date">
                      <b>Year:&nbsp;&nbsp;</b>
                      {data.year}
                    </div>
                    <div className="date">
                      <b>College:&nbsp;&nbsp;</b>
                      {data.collegename}
                    </div>
                    <div className="date">
                      <b>College Strength:&nbsp;&nbsp;</b>
                      {data.collegestrength}
                    </div>
                    <div className="date">
                      <b>Registered on:&nbsp;&nbsp;</b>
                      {Moment(data.createdAt?.toDate().toString()).format(
                        "lll",
                      )}
                    </div>
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
        {counter === 0 ? (
          <Fade up>
            <Alert
              color="danger"
              style={{ textAlign: "center", padding: "1.5em 0" }}
            >
              Oops! Looks like there are no CA registrations at the moment!
              <br />
              <br />
              <a href="/dashboard">
                <ButtonToggle color="danger">Go Back</ButtonToggle>
              </a>
            </Alert>
          </Fade>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default openMicListComponent;
