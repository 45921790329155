import React from 'react'
import { Fade } from "react-reveal";
import "./SpeakerPopup.css"

import devansh from "../../../img/devansh.jpg"

function SpeakerPopup(props) {
  

  const id="1"
  return (
    // <Fade left>

    <div className='speakerPopup'>
        <p onClick={props.handleClose}>x</p>
        <img src={props.imgURL} alt="" />
        <div className='speakerPopupDetails'>
            <div>

        <span>{props.speakerName}</span>
        <span>{props.desig}</span>
            </div>
        <span>{props.desc}</span>

        </div>
    </div>
    // </Fade>
  )
}

export default SpeakerPopup
